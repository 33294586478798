import { Add } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { GREY_BUTTON_STYLE } from "../../../../../constants/styles";
import CustomDialog from "../../../../shared/CustomDialog";
import TripItinerary from "./TripItinerary";
import useQueryTourPointsList from "../../../../../hooks/queries/tourPoints/useQueryTourPointsList";
import RouteMap from "../../../../shared/Map/routeMap/RouteMap";
import { getTourPointCoordinates } from "../../../../../constants/utils";

const TripItineraries = () => {
  const { t } = useTranslation();
  const { watch, setValue, getValues } = useFormContext();

  const [openDelete, setOpenDelete] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const itineraries = watch("itineraries") || [];
  const { data: tourPoints = [], refetch: refetchTourPoints } = useQueryTourPointsList();

  const handleSetItineraryPositions = ({ destination, source }) => {
    if (!destination) return;
    const newItineraries = itineraries;
    const [removed] = newItineraries.splice(source.index, 1);
    newItineraries.splice(destination.index, 0, removed);

    const updatedItineraries = newItineraries.map((itinerary, index) => ({
      ...itinerary,
      position: index,
    }));

    setValue("itineraries", updatedItineraries);
  };

  const handleRemoveItinerary = () => {
    const updatedItineraries = itineraries?.filter((_, idx) => idx !== selectedIndex);
    setValue("itineraries", updatedItineraries);
    setSelectedIndex(null);
    setOpenDelete(false);
  };

  const handleAddItinerary = () => {
    const prev = getValues("itineraries") || [];
    const defaultLanguage = getValues("default_language");
    setValue("itineraries", [
      ...prev,
      { title: "", subtitle: "", description: "", position: "", language: defaultLanguage },
    ]);
  };

  const handleOpenDelete = (e, index) => {
    e.stopPropagation();
    setSelectedIndex(index);
    setOpenDelete(true);
  };

  const itinerariesCoordinates = itineraries.map((itinerary) =>
    getTourPointCoordinates(itinerary.tour_point_id, tourPoints)
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={8} pl={2} mb={2}>
        <DragDropContext onDragEnd={handleSetItineraryPositions}>
          <Droppable droppableId="droppable-list">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%" }}
              >
                {itineraries.map((_, index) => (
                  <TripItinerary
                    key={`trip-itinerary-${index}`}
                    index={index}
                    handleOpenDelete={handleOpenDelete}
                    tourPoints={tourPoints}
                    refetchTourPoints={refetchTourPoints}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Button
          fullWidth
          variant="outlined"
          size="large"
          sx={{
            ...GREY_BUTTON_STYLE,
            marginTop: "20px",
            width: "fit-content",
          }}
          onClick={handleAddItinerary}
          startIcon={<Add sx={{ color: "var(--secondary-color)" }} />}
        >
          {t("ADD_POINT")}
        </Button>
      </Grid>
      {/* MAP WITH THE ROUTES */}
      <Grid item xs={4} pl={2} mb={2}>
        <RouteMap coordinates={itinerariesCoordinates} />
      </Grid>
      <CustomDialog
        isOpen={openDelete}
        onClose={() => setOpenDelete(false)}
        title={t("DELETE_ITINERARY")}
        content={t("DELETE_ITINERARY_CONFIRM")}
        onAccept={handleRemoveItinerary}
      />
    </Grid>
  );
};

export default TripItineraries;
