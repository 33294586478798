import { useEffect, useState } from "react";
import {
  getEventById,
  getExtrasByEventId,
  getTicketsByEventId,
} from "../../services/eventsServices";
import prepareDuplicateTickets from "./utils/prepareDuplicateTickets";
import prepareDuplicateExtras from "./utils/prepareDuplicateExtras";
import prepareDuplicateDetails from "./utils/prepareDuplicateDetails";

const useDuplicateTrip = (eventId, isDuplicateChild = false) => {
  const [formInfo, setFormInfo] = useState(null);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  // FOR HOTEL & TRANSPORTS IDS
  const [ids, setIds] = useState({});

  // GET EVENT DETAILS
  const fetchDetails = async () => {
    if (!eventId) return;

    setLoadingDuplicate(true);
    try {
      const [eventDetails, tickets, extras] = await Promise.all([
        getEventById(eventId),
        getTicketsByEventId(eventId),
        getExtrasByEventId(eventId),
      ]);

      const newDetails = await prepareDuplicateDetails(eventDetails, setIds);
      const newTickets = await prepareDuplicateTickets(tickets);
      const newExtras = await prepareDuplicateExtras(extras, ids);

      if (isDuplicateChild && !newDetails.parent_event_id) newDetails.parent_event_id = eventId;

      setFormInfo({ details: newDetails, tickets: newTickets, extras: newExtras });
    } catch (error) {
      console.error("Error fetching event details", error);
    } finally {
      setLoadingDuplicate(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [eventId]);

  return { formInfo, loadingDuplicate };
};

export default useDuplicateTrip;
