import {
  Avatar,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getRoutePointName } from "../../constants/utils";
import { TEMPLATES_TAB } from "../../utils/hotels";
import OrderButtons from "../shared/OrderButtons";
import HotelActions from "./HotelActions";

const HotelsTable = ({
  data,
  filters,
  handleOrder,
  handleEdit,
  handleSee,
  handleEvent,
  handleOpenDelete,
  routePoints,
  selectedTab,
}) => {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
      <Table sx={{ whiteSpace: "nowrap" }}>
        <TableHead>
          <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
            <TableCell></TableCell>
            <TableCell>
              <OrderButtons
                columnName={t("SKU")}
                id="sku"
                order={filters}
                handleClickAsc={handleOrder}
                handleClickDesc={handleOrder}
              />
            </TableCell>
            <TableCell>
              <OrderButtons
                columnName={t("NAME")}
                id="name"
                order={filters}
                handleClickAsc={handleOrder}
                handleClickDesc={handleOrder}
              />
            </TableCell>
            {/* {selectedTab === ASSIGNED_TAB && (
              <>
                <TableCell>{t("PLACES")}</TableCell>
                <TableCell>{t("ASSIGNED_DATES")}</TableCell>
              </>
            )} */}
            <TableCell>
              <OrderButtons
                columnName={t("ROUTE_POINT")}
                id="tour_point_id"
                order={filters}
                handleClickAsc={handleOrder}
                handleClickDesc={handleOrder}
              />
            </TableCell>
            {selectedTab === TEMPLATES_TAB && <TableCell>{t("ASSIGNED_TRIPS")}</TableCell>}
            {/* {selectedTab === ASSIGNED_TAB && (
              <>
                <TableCell>{t("ASSIGNED_TRIP")}</TableCell>
                <TableCell>{t("PREMIUM_ROOMS")}</TableCell>
              </>
            )} */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                backgroundColor: row.is_active === 0 ? "var(--grey-cancelled)" : "",
                whiteSpace: "nowrap",
              }}
            >
              <TableCell>
                <Avatar alt={row.name} src={row.img_url} />
              </TableCell>
              <TableCell>{row.sku}</TableCell>
              <TableCell>{row.name}</TableCell>
              {/* {selectedTab === ASSIGNED_TAB && (
                <>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </>
              )} */}
              <TableCell>
                {getRoutePointName(row.tour_point_id || row.route_point_id, routePoints)}
              </TableCell>
              {selectedTab === TEMPLATES_TAB && <TableCell>{row.itineraries_using_it}</TableCell>}
              {/* {selectedTab === ASSIGNED_TAB && (
                <>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </> 
              )}*/}
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <HotelActions
                    handleEdit={handleEdit}
                    handleSee={handleSee}
                    handleEvent={handleEvent}
                    handleOpenDelete={handleOpenDelete}
                    hotelId={row.id}
                  />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HotelsTable;
