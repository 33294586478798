import { Grid, TextField, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { focusColor } from "../../shared/textFieldStyle";

const TripNumbers = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Grid item xs={12} sm={12}>
        {/* TITLE SECTION */}
        <Typography variant="h6">{t("DETAILS")}</Typography>
        <Typography variant="body1">{t("DETAILS_DESCRIPTION")}</Typography>

        {/* CONTAINER SECTION */}
        <Grid container item xs={12} sm={12} spacing={2}>
          {/*  */}
          <Grid item xs={6} sm={6}>
            <Typography>{t("MIN_AGE")}</Typography>
            <TextField
              placeholder={t("MIN_AGE")}
              {...register("min_age")}
              error={!!errors?.min_age}
              helperText={errors?.min_age?.message}
              fullWidth
              sx={focusColor}
              type="number"
              inputProps={{ min: 0, max: 120 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("NUMBER_COUNTRIES")}</Typography>
            <TextField
              placeholder={"00"}
              {...register("countries")}
              error={!!errors?.countries}
              helperText={errors?.countries?.message}
              fullWidth
              sx={focusColor}
              type="number"
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("NUMBER_CITIES")}</Typography>
            <TextField
              placeholder={"00"}
              {...register("cities")}
              error={!!errors?.cities}
              helperText={errors?.cities?.message}
              fullWidth
              sx={focusColor}
              type="number"
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("NUMBER_DAYS")}</Typography>
            <TextField
              placeholder={"00"}
              {...register("days")}
              error={!!errors?.days}
              helperText={errors?.days?.message}
              fullWidth
              sx={focusColor}
              type="number"
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("NUMBER_NIGHTS")}</Typography>
            <TextField
              placeholder={"00"}
              {...register("nights")}
              error={!!errors?.nights}
              helperText={errors?.nights?.message}
              fullWidth
              sx={focusColor}
              type="number"
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("STARS_VALUATION")}</Typography>
            <TextField
              placeholder={"00"}
              {...register("rating")}
              error={!!errors?.rating}
              helperText={errors?.rating?.message}
              fullWidth
              sx={focusColor}
              type="number"
              inputProps={{ min: 0, max: 5 }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TripNumbers;
