import { Button, Grid, Paper, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const TripPlusContainer = ({
  children,
  handleContinue,
  handleBack,
  isFirstStep,
  isLastStep,
  loadingSubmit,
  isEdit,
}) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Grid component={Paper} container spacing={2} padding={2}>
      {isEdit && (
        <Grid item xs={12} container justifyContent="flex-end">
          <Button type="submit" className="oniria-btn" variant="contained" disabled={loadingSubmit}>
            {loadingSubmit ? t("SAVING") : t("SAVE")}
          </Button>
        </Grid>
      )}
      {children}
      <Grid item xs={12}>
        {!!errors && Object.keys(errors).length > 0 ? (
          <Typography sx={{ color: "var(--oniria-red)" }}>*{t("CHECK_FIELDS")}</Typography>
        ) : null}
      </Grid>
      <Grid item xs={12} container justifyContent="center" gap={2}>
        {!isFirstStep && (
          <Button onClick={handleBack} className="oniria-btn-cancel" variant="contained">
            {t("BACK")}
          </Button>
        )}
        {isLastStep ? (
          <Button type="submit" className="oniria-btn" variant="contained" disabled={loadingSubmit}>
            {loadingSubmit ? t("SAVING") : t("SAVE")}
          </Button>
        ) : (
          <Button className="oniria-btn" variant="contained" onClick={handleContinue}>
            {t("CONTINUE")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default TripPlusContainer;
