import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ImageUpload from "../../shared/ImageUpload";
import { useFormContext } from "react-hook-form";
import { handleSelectImageReactHook } from "../../shared/FormsValidator";
import InfoToolTip from "../../shared/InfoToolTip";

const TripImages = () => {
  const { t } = useTranslation();

  const { watch, setValue } = useFormContext();

  return (
    <>
      <Grid item xs={10}>
        <Typography variant="h6">{t("TRIP_IMAGES")}</Typography>
        <Typography variant="body1">
          {t("TRIP_IMAGES_DESCRIPTION")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>{t("TRIP_PRINCIPAL_IMAGE")}* </Typography>
          <InfoToolTip text={t("TRIP_PRINCIPAL_IMAGE_INFO")} />
        </Box>
        <ImageUpload
          onImageSelect={(e) =>
            handleSelectImageReactHook(e, "principal_url", setValue)
          }
          url={watch("principal_url")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>{t("TRIP_IMAGES_HEADER")}* </Typography>
          <InfoToolTip text={t("TRIP_IMAGES_INFO")} />
        </Box>
        <ImageUpload
          onImageSelect={(e) =>
            handleSelectImageReactHook(e, "slider_urls", setValue)
          }
          multi
          selectedImagesInForm={watch("slider_urls")}
          size="21:9"
        />
      </Grid>
    </>
  );
};

export default TripImages;
