import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EXTRA_TAGS } from "../../../../constants/variables";
import { focusColor } from "../../textFieldStyle";

const NightExtra = ({ itineraryToAssign = {}, hotelId }) => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const extras = watch("extras") || [];
  const hotelExtras = extras?.filter((extra) => extra.hotel_id === hotelId) || [];

  const hasExtraNightsStart = hotelExtras?.find((extra) =>
    extra.tags?.includes(EXTRA_TAGS.START_NIGHTS)
  );
  const hasExtraNightsEnd = hotelExtras?.find((extra) =>
    extra.tags?.includes(EXTRA_TAGS.END_NIGHTS)
  );
  const startExtraIndex = hotelExtras?.findIndex((extra) =>
    extra.tags?.includes(EXTRA_TAGS.START_NIGHTS)
  );
  const endExtraIndex = hotelExtras?.findIndex((extra) =>
    extra.tags?.includes(EXTRA_TAGS.END_NIGHTS)
  );

  const handleAddNewExtra = (tag) => {
    setValue("extras", [
      ...extras,
      {
        name: tag,
        price: 0,
        tags: tag,
        description: "",
        description_design: "",
        initial_stock: 0,
        position: extras.length,
        stock_by_ticket_max: 0,
        hotel_id: hotelId,
      },
    ]);
  };

  useEffect(() => {
    if (!hasExtraNightsStart && itineraryToAssign?.start_point) {
      handleAddNewExtra(EXTRA_TAGS.START_NIGHTS);
    }
    if (!hasExtraNightsEnd && itineraryToAssign?.end_point) {
      handleAddNewExtra(EXTRA_TAGS.END_NIGHTS);
    }
  }, [extras]);

  const showNights =
    (!!hasExtraNightsStart || !!hasExtraNightsEnd) &&
    (itineraryToAssign?.start_point || itineraryToAssign?.end_point);

  return showNights ? (
    <Accordion
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--grey-cancelled)",
        boxShadow: "none",
        mt: 2,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography fontWeight="bold">{t("EXTRA_NIGHTS")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="caption">{t("EXTRA_NIGHTS_DESCRIPTION")}</Typography>
        {/* START NIGHTS */}

        <Grid container mt={2}>
          {itineraryToAssign?.start_point && (
            <>
              <Typography fontWeight="bold" sx={{ width: "100%" }}>
                {t("START_NIGHTS")}
              </Typography>
              <Typography variant="caption" sx={{ width: "100%" }}>
                {t("START_NIGHTS_DESCRIPTION")}
              </Typography>
              <Grid item xs={12} container columnSpacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography>{t("MAX_NUMBER_NIGHTS")}</Typography>
                  <TextField
                    placeholder={"00"}
                    fullWidth
                    sx={focusColor}
                    type="number"
                    inputProps={{ min: 0 }}
                    {...register(`extras[${startExtraIndex}].stock_by_ticket_max`)}
                    error={!!errors?.extras?.[startExtraIndex]?.stock_by_ticket_max}
                    helperText={errors?.extras?.[startExtraIndex]?.stock_by_ticket_max?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>{t("ADDITIONAL_COST_BY_NIGHT")}</Typography>
                  <TextField
                    placeholder={"€"}
                    {...register(`extras[${startExtraIndex}].price`)}
                    error={!!errors?.extras?.[startExtraIndex]?.price}
                    helperText={errors?.extras?.[startExtraIndex]?.price?.message}
                    fullWidth
                    sx={focusColor}
                    type="number"
                    inputProps={{ min: 0 }}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {/* END NIGHTS */}
          {itineraryToAssign?.end_point && (
            <Grid container mt={2}>
              <Typography fontWeight="bold" sx={{ width: "100%" }}>
                {t("END_NIGHTS")}
              </Typography>
              <Typography variant="caption" sx={{ width: "100%" }}>
                {t("END_NIGHTS_DESCRIPTION")}
              </Typography>
              <Grid item xs={12} container columnSpacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography>{t("MAX_NUMBER_NIGHTS")}</Typography>
                  <TextField
                    placeholder={"00"}
                    fullWidth
                    sx={focusColor}
                    type="number"
                    inputProps={{ min: 0 }}
                    {...register(`extras[${endExtraIndex}].stock_by_ticket_max`)}
                    error={!!errors?.extras?.[endExtraIndex]?.stock_by_ticket_max}
                    helperText={errors?.extras?.[endExtraIndex]?.stock_by_ticket_max?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>{t("ADDITIONAL_COST_BY_NIGHT")}</Typography>
                  <TextField
                    placeholder={"€"}
                    {...register(`extras[${endExtraIndex}].price`)}
                    error={!!errors?.extras?.[endExtraIndex]?.price}
                    helperText={errors?.extras?.[endExtraIndex]?.price?.message}
                    fullWidth
                    sx={focusColor}
                    type="number"
                    inputProps={{ min: 0 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  ) : null;
};

export default NightExtra;
