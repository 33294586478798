import { Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InfoToolTip from "../../shared/InfoToolTip";
import { focusColor } from "../../shared/textFieldStyle";

const TripExtraConfig = ({ index }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  return (
    <>
      <Grid item xs={12}>
        <Typography>{t("STOCK")}*</Typography>
        <TextField
          placeholder={t("STOCK")}
          {...register(`extras[${index}].initial_stock`)}
          error={!!errors?.extras?.[index]?.initial_stock}
          helperText={errors?.extras?.[index]?.initial_stock?.message}
          fullWidth
          sx={focusColor}
          type="number"
          inputProps={{ min: 0 }}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("PRICE")}*</Typography>
        <TextField
          placeholder={t("PRICE")}
          {...register(`extras[${index}].price`)}
          error={!!errors?.extras?.[index]?.price}
          helperText={errors?.extras?.[index]?.price?.message}
          fullWidth
          sx={focusColor}
          type="number"
          inputProps={{ min: 0 }}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("TIMES_CAN_BE_CONSUMED")}</Typography>
        <TextField
          placeholder={"00"}
          {...register(`extras[${index}].times_can_be_consumed`)}
          error={!!errors?.extras?.[index]?.times_can_be_consumed}
          helperText={errors?.extras?.[index]?.times_can_be_consumed?.message}
          fullWidth
          sx={focusColor}
          type="number"
          inputProps={{ min: 0 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("LIMIT_BY_TICKET")}</Typography>
        <TextField
          placeholder="00"
          {...register(`extras[${index}].stock_by_ticket_max`)}
          error={!!errors?.extras?.[index]?.stock_by_ticket_max}
          helperText={errors?.extras?.[index]?.stock_by_ticket_max?.message}
          fullWidth
          sx={focusColor}
          type="number"
          inputProps={{ min: 0 }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              {...register(`extras[${index}].is_visible`)}
              checked={watch(`extras.${index}.is_visible`)}
              className="checkbox-oniria"
            />
          }
          label={t("IS_VISIBLE")}
        />
        <InfoToolTip text={t("IS_VISIBLE_INFO")} />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              {...register(`extras[${index}].only_international`)}
              checked={watch(`extras.${index}.only_international`)}
              className="checkbox-oniria"
            />
          }
          label={t("ONLY_MEMBER_CARD")}
        />
        <InfoToolTip text={t("ONLY_INTERNATIONAL_CARD_INFO")} />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              {...register(`extras[${index}].no_after_sales`)}
              checked={watch(`extras.${index}.no_after_sales`)}
              className="checkbox-oniria"
            />
          }
          label={t("NO_AFTER_SALES")}
        />
        <InfoToolTip text={t("NO_AFTER_SALES_INFO")} />
      </Grid>
    </>
  );
};

export default TripExtraConfig;
