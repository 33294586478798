import { GridView, ViewList } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, IconButton, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import CustomDialog from "../../components/shared/CustomDialog";
import { Loading } from "../../components/shared/Loading";
import SelectPerPage from "../../components/shared/SelectPerPage";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import TourPointFilters from "../../components/tourPoints/TourPointFilters";
import TourPointsGrid from "../../components/tourPoints/TourPointsGrid";
import TourPointsTable from "../../components/tourPoints/TourPointsTable";
import { ROUTES } from "../../constants/routes";
import { PAGINATION_STYLES } from "../../constants/styles";
import useQueryTourPoints from "../../hooks/queries/tourPoints/useQueryTourPoints";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import useDebounce from "../../hooks/useDebouncing";
import usePagination from "../../hooks/usePagination";
import { deleteTourPoint } from "../../services/tourPoints";

const TourPointsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breadcrumbs = [{ name: t("TOUR_POINTS"), link: ROUTES.TOUR_POINTS }];
  const [showTable, setShowTable] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [filters, setFilters] = useState({
    search: "",
    premiseId: "all",
    asc: [],
    desc: ["tp.created_at"],
    domainId: "all",
    countryCode: "all",
    regionCode: "all",
  });
  const { page, perPage, changePage, changePerPage } = usePagination();

  const { data: premises = [] } = useQueryPremisesList();
  const { data: domains = [] } = useQueryDomains();
  const { data, isLoading, error, refetch } = useQueryTourPoints(page, perPage, filters);

  const { data: tourPoints = [], total: totalPoints } = data || {};

  const updateFilter = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    changePage(null, 1);
  };

  const handleSearchChange = (event) => {
    updateFilter("search", event.target.value);
    changePage(null, 1);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleOrder = (id, type) => {
    const oppositeType = type === "asc" ? "desc" : "asc";
    const newFilters = { ...filters };
    if (newFilters[type]?.includes(id)) {
      newFilters[type] = newFilters[type].filter((item) => item !== id);
    } else {
      newFilters[type].push(id);
      newFilters[oppositeType] = newFilters[oppositeType]?.filter((item) => item !== id);
    }
    setFilters(newFilters);
  };

  const handleCreate = () => {
    navigate(ROUTES.CREATE_TOUR_POINT);
  };

  const handleOpenDelete = (id) => {
    setSelectedId(id);
    setOpenConfirm(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteTourPoint(selectedId);
      toastMessageSuccess(t("DELETED_SUCCESSFULLY"));
    } catch (error) {
      toastMessageError(error?.response?.data?.message || t("ERROR"));
    } finally {
      setSelectedId("");
      refetch();
      setOpenConfirm(false);
    }
  };

  const handleEdit = (id) => {
    navigate(`/tour-points/edit/${id}`);
  };

  const handleSee = (id) => {
    navigate(`/tour-points/events/${id}`);
  };

  const numberPages = Math.ceil(parseFloat(totalPoints) / parseFloat(perPage));

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("TOUR_POINTS_PAGE_DESCRIPTION")} />
      <TourPointFilters
        onSearchChange={debounceSearch}
        filters={filters}
        updateFilter={updateFilter}
      />
      <Grid container justifyContent="flex-end" alignItems="center" mb={2} mt={2}>
        <Button
          variant="contained"
          className="oniria-btn"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          {t("CREATE_TOUR_POINT")}
        </Button>
      </Grid>
      {isLoading ? (
        <Loading />
      ) : error ? (
        <p>{t("ERROR_LOADING_DATA")}</p>
      ) : totalPoints > 0 ? (
        <div style={{ marginBottom: "20px" }}>
          <Grid
            item
            xs={11}
            sm={12}
            container
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems={"center"}
            pb={2}
            gap={{ xs: 2, sm: 0 }}
          >
            <Typography>
              {t("TOTAL_TOUR_POINTS")}: {totalPoints}
            </Typography>
            <Grid container item xs={12} md={8} justifyContent="flex-end">
              {numberPages >= 1 && (
                <Grid item xs={12} md={8} container justifyContent="flex-end">
                  <Grid item>
                    <SelectPerPage
                      text={t("TOUR_POINTS")}
                      change={changePerPage}
                      value={perPage}
                      total={totalPoints}
                    />
                  </Grid>
                  <Pagination
                    count={numberPages}
                    mr={3}
                    sx={PAGINATION_STYLES}
                    page={page}
                    onChange={changePage}
                  />
                </Grid>
              )}
              <Grid>
                <IconButton onClick={() => setShowTable(true)}>
                  <ViewList />
                </IconButton>
                <IconButton onClick={() => setShowTable(false)}>
                  <GridView />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          {showTable ? (
            <TourPointsTable
              data={tourPoints}
              filters={filters}
              handleOrder={handleOrder}
              handleEdit={handleEdit}
              handleSee={handleSee}
              handleOpenDelete={handleOpenDelete}
              premises={premises}
              domains={domains}
            />
          ) : (
            <TourPointsGrid
              data={tourPoints}
              handleEdit={handleEdit}
              handleSee={handleSee}
              handleOpenDelete={handleOpenDelete}
              premises={premises}
              domains={domains}
            />
          )}
          {numberPages >= 1 && (
            <Grid container justifyContent="center">
              <Pagination
                count={numberPages}
                mr={3}
                sx={PAGINATION_STYLES}
                page={page}
                onChange={changePage}
              />
            </Grid>
          )}
        </div>
      ) : (
        <p>{t("NO_TOUR_POINTS_FOUND")}</p>
      )}
      <CustomDialog
        isOpen={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title={t("DELETE_TOUR_POINT")}
        content={t("CONFIRM_DELETE_TOUR_POINT")}
        onAccept={handleConfirmDelete}
      />
    </>
  );
};

export default TourPointsPage;
