import { GridView, ViewList } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, IconButton, Pagination, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import HotelsFilters from "../../components/hotels/HotelsFilters";
import HotelsGrid from "../../components/hotels/HotelsGrid";
import HotelsTable from "../../components/hotels/HotelsTable";
import CustomDialog from "../../components/shared/CustomDialog";
import { Loading } from "../../components/shared/Loading";
import SelectPerPage from "../../components/shared/SelectPerPage";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import { ROUTES } from "../../constants/routes";
import { MUI_SELECT_PRIM_COLOR, PAGINATION_STYLES } from "../../constants/styles";
import useQueryHotelTemplates from "../../hooks/queries/hotels/useQueryHotels";
import useQueryTourPointsList from "../../hooks/queries/tourPoints/useQueryTourPointsList";
import useDebounce from "../../hooks/useDebouncing";
import usePagination from "../../hooks/usePagination";
import { deleteHotelTemplate } from "../../services/hotelServices";
import useQueryHotelsAssigned from "../../hooks/queries/hotels/useQueryHotelsAssigned";
import { ASSIGNED_TAB } from "../../utils/hotels";

const HotelsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breadcrumbs = [{ name: t("HOTEL_MANAGER"), link: ROUTES.HOTELS }];
  const [showTable, setShowTable] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedTab, setSelectedTab] = useState(0); // 0 - Assigned, 1 - Templates
  const [filters, setFilters] = useState({
    search: "",
    asc: [],
    desc: ["created_at"],
  });
  const [hotels, setHotels] = useState([]);
  const { page, perPage, changePage, changePerPage } = usePagination();

  const { data: routePoints = [] } = useQueryTourPointsList();

  const handleSetHotels = (data) => {
    setHotels(data);
  };

  const {
    data: dataTemplates,
    isLoading,
    error,
    refetch,
  } = useQueryHotelTemplates(page, perPage, filters, selectedTab, handleSetHotels);

  const {
    data: dataAssigned,
    isLoading: isLoadingAssigned,
    error: errorAssigned,
    refetch: refetchAssigned,
  } = useQueryHotelsAssigned(page, perPage, filters, selectedTab, handleSetHotels);

  const totalHotels = selectedTab === ASSIGNED_TAB ? dataAssigned?.total : dataTemplates?.total;

  const updateFilter = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    changePage(null, 1);
  };

  const handleSearchChange = (event) => {
    updateFilter("search", event.target.value);
    changePage(null, 1);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleOrder = (id, type) => {
    const oppositeType = type === "asc" ? "desc" : "asc";
    const newFilters = { ...filters };
    if (newFilters[type]?.includes(id)) {
      newFilters[type] = newFilters[type].filter((item) => item !== id);
    } else {
      newFilters[type].push(id);
      newFilters[oppositeType] = newFilters[oppositeType]?.filter((item) => item !== id);
    }
    setFilters(newFilters);
  };

  const handleCreate = () => {
    navigate(ROUTES.CREATE_HOTEL);
  };

  const handleOpenDelete = (id) => {
    setSelectedId(id);
    setOpenConfirm(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteHotelTemplate(selectedId);
      toastMessageSuccess(t("DELETED_SUCCESSFULLY"));
    } catch (error) {
      toastMessageError(error?.response?.data?.message || t("ERROR"));
    } finally {
      setSelectedId("");
      selectedTab === ASSIGNED_TAB ? refetchAssigned() : refetch();
      setOpenConfirm(false);
    }
  };

  const handleEdit = (id) => {
    selectedTab === ASSIGNED_TAB
      ? navigate(`/hotels/edit-trip/${id}`)
      : navigate(`/hotels/edit/${id}`);
  };

  const handleSee = (id) => {
    navigate(`/hotels/${id}`);
  };

  const handleEvent = (id) => {
    // TODO
    navigate(`/events/assign-hotel/${id}`);
  };

  const handleChangeTab = (_, newValue) => {
    setSelectedTab(newValue);
    changePage(null, 1);
  };

  const numberPages = Math.ceil(parseFloat(totalHotels || "0") / parseFloat(perPage));

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HOTELS_PAGE_DESCRIPTION")} />
      <Grid container justifyContent="flex-start" alignItems="center" mb={2} mt={2}>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          TabIndicatorProps={{
            style: { backgroundColor: "var(--secondary-color)" },
          }}
          sx={{
            mb: 2,
            color: "var(--secondary-color)",
          }}
        >
          <Tab
            label={t("STANDARD_HOSTING")}
            sx={[MUI_SELECT_PRIM_COLOR, { textTransform: "initial" }]}
          />
          <Tab
            label={t("ASSIGNED_HOSTING")}
            sx={[MUI_SELECT_PRIM_COLOR, { textTransform: "initial" }]}
          />
        </Tabs>
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="center" mb={2} mt={2} gap={2}>
        <Button
          variant="contained"
          className="oniria-btn"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          {t("CREATE_STANDARD_HOTEL")}
        </Button>
      </Grid>
      <HotelsFilters onSearchChange={debounceSearch} />
      {isLoading || isLoadingAssigned ? (
        <Loading />
      ) : error || errorAssigned ? (
        <p>{t("ERROR_LOADING_DATA")}</p>
      ) : totalHotels > 0 ? (
        <div style={{ marginBottom: "20px" }}>
          <Grid
            item
            xs={11}
            sm={12}
            container
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems={"center"}
            pb={2}
            gap={{ xs: 2, sm: 0 }}
          >
            <Typography>
              {t("TOTAL_HOSTING")}: {totalHotels || 0}
            </Typography>
            <Grid container item xs={12} md={8} justifyContent="flex-end">
              {numberPages >= 1 && (
                <Grid item xs={12} md={8} container justifyContent="flex-end">
                  <Grid item>
                    <SelectPerPage
                      text={t("HOSTING")}
                      change={changePerPage}
                      value={perPage}
                      total={totalHotels || 0}
                    />
                  </Grid>
                  <Pagination
                    count={numberPages}
                    mr={3}
                    sx={PAGINATION_STYLES}
                    page={page}
                    onChange={changePage}
                  />
                </Grid>
              )}
              <Grid>
                <IconButton onClick={() => setShowTable(true)}>
                  <ViewList />
                </IconButton>
                <IconButton onClick={() => setShowTable(false)}>
                  <GridView />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          {showTable ? (
            <HotelsTable
              data={hotels}
              filters={filters}
              handleOrder={handleOrder}
              handleEdit={handleEdit}
              handleSee={handleSee}
              handleEvent={handleEvent}
              handleOpenDelete={handleOpenDelete}
              routePoints={routePoints}
              selectedTab={selectedTab}
            />
          ) : (
            <HotelsGrid
              data={hotels}
              handleEdit={handleEdit}
              handleSee={handleSee}
              handleEvent={handleEvent}
              handleOpenDelete={handleOpenDelete}
              routePoints={routePoints}
              selectedTab={selectedTab}
            />
          )}
          {numberPages >= 1 && (
            <Grid container justifyContent="center">
              <Pagination
                count={numberPages}
                mr={3}
                sx={PAGINATION_STYLES}
                page={page}
                onChange={changePage}
              />
            </Grid>
          )}
        </div>
      ) : (
        <p>{t("NO_HOTELS_FOUND")}</p>
      )}
      <CustomDialog
        isOpen={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title={t("DELETE_HOTEL")}
        content={t("CONFIRM_DELETE_HOTEL")}
        onAccept={handleConfirmDelete}
      />
    </>
  );
};

export default HotelsPage;
