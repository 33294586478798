import { Checkbox, Grid, TextField, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EXTRA_TAGS } from "../../../../constants/variables";
import { focusColor } from "../../textFieldStyle";

const IndividualPensions = ({ extras, hotelId }) => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext();

  const hotelExtras = extras?.filter((extra) => extra.hotel_id === hotelId);

  const breakfastExtra = hotelExtras?.find((extra) => extra.tags?.includes(EXTRA_TAGS.BREAKFAST));
  const halfPensionExtra = hotelExtras?.find((extra) =>
    extra.tags?.includes(EXTRA_TAGS.HALF_PENSION)
  );
  const fullPensionExtra = hotelExtras?.find((extra) => extra.tags?.includes(EXTRA_TAGS.COMPLETE));

  const handleAddExtra = (type, isChecked) => {
    const prevExtras = getValues("extras") || [];
    const newExtra = {
      name: type,
      price: 0,
      tags: type,
      hotel_id: hotelId,
    };

    let updatedExtras;
    if (isChecked) {
      updatedExtras = [...prevExtras, newExtra];
    } else {
      updatedExtras = prevExtras?.filter((extra) => !extra.tags?.includes(type));
    }

    setValue("extras", updatedExtras);
  };

  const handleChangePrice = (type, price) => {
    const prevExtras = getValues("extras") || [];
    const updatedExtras = prevExtras.map((extra) => {
      if (extra.tags === type) {
        return { ...extra, price };
      }
      return extra;
    });

    setValue("extras", updatedExtras);
  };

  return (
    <Grid container mt={2}>
      <Typography fontWeight="bold">
        {t("PENSIONS")} ({t("INDIVIDUALS")})
      </Typography>
      <Typography variant="caption">{t("INDIVIDUAL_PENSIONS_DESCRIPTION")}</Typography>
      <Grid container gap={2}>
        <Grid item xs={12}>
          <Typography>
            {t("BREAKFAST")} {t("IF_INCLUDED_0")}
          </Typography>
          <Grid container alignItems="center" gap={1}>
            <Checkbox
              checked={!!breakfastExtra}
              onChange={(e) => handleAddExtra(EXTRA_TAGS.BREAKFAST, e.target.checked)}
              className="checkbox-oniria"
            />
            <TextField
              value={breakfastExtra?.price}
              type="number"
              inputProps={{ min: 0 }}
              placeholder={t("ADDITIONAL_COST")}
              sx={[focusColor, { flexGrow: 1 }]}
              disabled={!breakfastExtra}
              onChange={(e) => handleChangePrice(EXTRA_TAGS.BREAKFAST, e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {t("HALF_PENSION")} {t("IF_INCLUDED_0")}
          </Typography>
          <Grid container alignItems="center" gap={1}>
            <Checkbox
              checked={!!halfPensionExtra}
              onChange={(e) => handleAddExtra(EXTRA_TAGS.HALF_PENSION, e.target.checked)}
              className="checkbox-oniria"
            />
            <TextField
              value={halfPensionExtra?.price}
              type="number"
              inputProps={{ min: 0 }}
              placeholder={t("ADDITIONAL_COST")}
              sx={[focusColor, { flexGrow: 1 }]}
              onChange={(e) => handleChangePrice(EXTRA_TAGS.HALF_PENSION, e.target.value)}
              disabled={!halfPensionExtra}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {t("FULL_PENSION")} {t("IF_INCLUDED_0")}
          </Typography>
          <Grid container alignItems="center" gap={1}>
            <Checkbox
              checked={!!fullPensionExtra}
              onChange={(e) => handleAddExtra(EXTRA_TAGS.COMPLETE, e.target.checked)}
              className="checkbox-oniria"
            />
            <TextField
              value={fullPensionExtra?.price}
              type="number"
              inputProps={{ min: 0 }}
              placeholder={t("ADDITIONAL_COST")}
              sx={[focusColor, { flexGrow: 1 }]}
              onChange={(e) => handleChangePrice(EXTRA_TAGS.COMPLETE, e.target.value)}
              disabled={!fullPensionExtra}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IndividualPensions;
