import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DEFAULT_EVENT_LANGUAGE } from "../../../constants/variables";
import { focusColor } from "../../shared/textFieldStyle";

const ExtraName = ({
  checkExtraField,
  index,
  extras,
  errors,
  sendButtonClicked,
  isDisabled,
  extra_language_infos,
  handleChangeLanguageParam,
  selectedLanguage,
  defaultLanguage = DEFAULT_EVENT_LANGUAGE,
}) => {
  const { t } = useTranslation();
  const extra = extras[index];
  const error = errors[index];

  const selectedLanguageIndex = extra_language_infos?.findIndex(
    (language) => language.language === selectedLanguage
  );

  return (
    <Grid item xs={12}>
      {selectedLanguage === defaultLanguage ? (
        <TextField
          onChange={(e) =>
            checkExtraField(
              "name",
              e,
              /^(?!.*[|\\#·$~%¬=^[\]*{}_-]).{1,50}$/,
              t("MAX_50"),
              false,
              index
            )
          }
          margin="normal"
          fullWidth
          value={extra.name}
          id="name"
          name="name"
          autoComplete="name"
          autoFocus
          label={t("EXTRA_NAME")}
          error={
            (error.name !== "" && error.name !== "empty") ||
            (error.name === "empty" && sendButtonClicked)
          }
          helperText={error.name !== "empty" ? error.name : ""}
          disabled={isDisabled}
          sx={focusColor}
        />
      ) : (
        <TextField
          onChange={(e) => handleChangeLanguageParam(e.target.value, "name", selectedLanguageIndex)}
          margin="normal"
          required
          fullWidth
          value={extra_language_infos[selectedLanguageIndex]?.name}
          id="name"
          name="name"
          autoComplete="name"
          autoFocus
          label={t("EXTRA_NAME")}
          disabled={isDisabled}
          sx={focusColor}
        />
      )}
    </Grid>
  );
};

export default ExtraName;
