import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EXTRA_TAGS } from "../../../../constants/variables";

const GlobalRooms = ({ extras, handlePensionChange, includedPension }) => {
  const { t } = useTranslation();
  return (
    <Grid container mt={2}>
      <Typography fontWeight="bold">
        {t("ROOMS_TYPES")} ({t("GLOBALS")})
      </Typography>
      <Typography variant="caption">{t("ROOMS_GLOBAL_TYPES_DESCRIPTION")}</Typography>
      <br />
      {extras
        .filter((extra) => extra.tags?.includes(EXTRA_TAGS.ROOM))
        .map((extra, index) => (
          <FormControlLabel
            key={`${extra.id}-${index}`}
            control={
              <Checkbox
                checked={includedPension?.includes(EXTRA_TAGS.BREAKFAST)}
                onChange={(e) => handlePensionChange(EXTRA_TAGS.BREAKFAST, e.target.checked)}
                className="checkbox-oniria"
              />
            }
            label={extra.name}
          />
        ))}
    </Grid>
  );
};

export default GlobalRooms;
