import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HOSTING_EXTRAS } from "../../../../constants/variables";
import GlobalPensionsDialog from "./extras/GlobalPensionsDialog";
import GlobalRoomsDialog from "./extras/GlobalRoomsDialog";

const AddHostingExtras = () => {
  const { t } = useTranslation();
  const [openPensionsDialog, setOpenPensionsDialog] = useState(false);
  const [openRoomsDialog, setOpenRoomsDialog] = useState(false);

  const { control, watch } = useFormContext();

  const pensionsType = watch("pensions_type");
  const roomsType = watch("rooms_type");

  return (
    <>
      <Divider sx={{ width: "100%", borderColor: "var(--secondary-color)", mt: 2, mb: 2 }} />
      <Typography variant="body1" sx={{ mb: 2, fontWeight: 900 }}>
        {t("HOSTING_EXTRAS")}
      </Typography>

      {/* PENSIONS TYPES*/}
      <Typography variant="body2" sx={{ width: "100%", fontWeight: "800", mb: 1 }}>
        {t("PENSIONS")}
      </Typography>
      <Typography variant="body2">{t("PENSIONS_DESCRIPTION")}</Typography>
      <Controller
        name="pensions_type"
        defaultValue={HOSTING_EXTRAS.NONE}
        control={control}
        render={({ field }) => (
          <RadioGroup row sx={{ mt: 2, mb: 2 }} {...field}>
            <FormControlLabel
              value={HOSTING_EXTRAS.NONE}
              control={<Radio className="radio-oniria" />}
              label={t("WITHOUT_PENSION_SUPPLEMENT")}
            />
            <FormControlLabel
              value={HOSTING_EXTRAS.GLOBAL}
              control={<Radio className="radio-oniria" />}
              label={t("GLOBAL_SUPPLEMENT")}
            />
            <FormControlLabel
              value={HOSTING_EXTRAS.INDIVIDUAL}
              control={<Radio className="radio-oniria" />}
              label={t("BY_HOSTING")}
            />
          </RadioGroup>
        )}
      />
      {pensionsType === HOSTING_EXTRAS.GLOBAL && (
        <Grid container>
          <Button
            onClick={() => setOpenPensionsDialog(true)}
            className="oniria-btn"
            variant="contained"
            sx={{ mb: 2 }}
            size="small"
          >
            {t("GLOBAL_PENSIONS")}
          </Button>
        </Grid>
      )}

      {/* ROOMS TYPES */}
      <Typography variant="body2" sx={{ width: "100%", fontWeight: "800", mb: 1 }}>
        {t("ROOMS_TYPES")}
      </Typography>
      <Typography variant="body2">{t("ROOMS_TYPES_DESCRIPTION")}</Typography>
      <Controller
        name="rooms_type"
        defaultValue={HOSTING_EXTRAS.NONE}
        control={control}
        render={({ field }) => (
          <RadioGroup row sx={{ mt: 2, mb: 2 }} {...field}>
            <FormControlLabel
              value={HOSTING_EXTRAS.NONE}
              control={<Radio className="radio-oniria" />}
              label={t("WITHOUT_SUPPLEMENT_BY_ROOM")}
            />
            <FormControlLabel
              value={HOSTING_EXTRAS.GLOBAL}
              control={<Radio className="radio-oniria" />}
              label={t("GLOBAL_SUPPLEMENT")}
            />
            <FormControlLabel
              value={HOSTING_EXTRAS.INDIVIDUAL}
              control={<Radio className="radio-oniria" />}
              label={t("INDIVIDUAL")}
            />
          </RadioGroup>
        )}
      />

      {roomsType === HOSTING_EXTRAS.GLOBAL && (
        <Grid container>
          <Button
            onClick={() => setOpenRoomsDialog(true)}
            className="oniria-btn"
            variant="contained"
            sx={{ mb: 2 }}
            size="small"
          >
            {t("GLOBAL_ROOMS")}
          </Button>
        </Grid>
      )}

      <GlobalPensionsDialog
        isOpen={openPensionsDialog}
        onClose={() => setOpenPensionsDialog(false)}
      />
      <GlobalRoomsDialog isOpen={openRoomsDialog} onClose={() => setOpenRoomsDialog(false)} />
    </>
  );
};

export default AddHostingExtras;
