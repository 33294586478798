import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getTransportDetails } from "../../../services/transportServices";

const useQueryTransportDetails = (id, onSuccess, isTrip, retry = false) => {
  return useQuery(
    [RQ_KEY.TRANSPORTS_DETAILS, id, isTrip, retry],
    () => getTransportDetails(id, isTrip),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
      onSuccess,
    }
  );
};

export default useQueryTransportDetails;
