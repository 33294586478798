import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DIVIDER_STYLE } from "../../../constants/styles";
import {
  endsWithNumber,
  generateNextUrl,
  transformNameToUrl,
} from "../../../constants/utils";
import { useQueryPremisesList } from "../../../hooks/queries/useQueryPremisesList";
import { checkAvailableUrl } from "../../../services/eventsServices";
import InfoToolTip from "../../shared/InfoToolTip";
import SelectHookForm from "../../shared/SelectHookForm";
import { focusColor } from "../../shared/textFieldStyle";
import TripCategoriesDomain from "./TripCategoriesDomain";

const TripConfiguration = () => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useFormContext();

  const { data: premises = [] } = useQueryPremisesList();

  const isDoublePayment = watch("has_double_payment");

  const tryUrlWithNumber = (url) => {
    const checkUrl = (currentUrl) => {
      checkAvailableUrl(currentUrl).then((isUrlAvailable) => {
        if (isUrlAvailable) {
          setValue("seo_name", currentUrl);
        } else {
          const nextUrl = generateNextUrl(currentUrl);
          checkUrl(nextUrl);
        }
      });
    };
    const initialUrl = endsWithNumber(url) ? url : `${url}-01`;
    checkUrl(initialUrl);
  };

  const handleSetUrl = async () => {
    const eventSeoName = getValues("seo_name");
    const eventName = getValues("name");
    if (!eventSeoName && !!eventName) {
      const newUrl = transformNameToUrl(eventName);
      if (newUrl) {
        const isUrlAvailable = await checkAvailableUrl(newUrl || "");
        if (isUrlAvailable) {
          setValue("seo_name", newUrl);
        } else {
          tryUrlWithNumber(newUrl);
        }
      }
    }
  };

  useEffect(() => {
    handleSetUrl();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">{t("CONFIGURATION_SEO")}</Typography>
        <Typography variant="body1">
          {t("CONFIGURATION_SEO_DESCRIPTION")}
        </Typography>
      </Grid>
      {/* SKU */}
      <Grid item xs={12}>
        <Typography>
          {t("SKU")}
          <InfoToolTip text={t("SKU_INFO")} />
        </Typography>
        <TextField
          placeholder={t("SKU")}
          {...register("sku")}
          error={!!errors?.sku}
          helperText={errors?.sku?.message}
          fullWidth
          sx={focusColor}
        />
      </Grid>
      {/* Event URL */}
      <Grid item xs={12}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>{t("EVENT_URL")}</Typography>
          <InfoToolTip text={t("EVENT_URL_INFO")} />
        </Box>
        <TextField
          placeholder={t("EVENT_URL")}
          {...register("seo_name")}
          error={!!errors?.seo_name}
          helperText={errors?.seo_name?.message}
          fullWidth
          sx={focusColor}
        />
      </Grid>
      {/* PROPIETARIO */}
      <Grid item xs={12}>
        <Typography>
          {t("PREMISE_ORGANIZATION")}*
          <InfoToolTip text={t("PREMISE_ORGANIZATION_INFO")} />
        </Typography>
        <SelectHookForm
          name={"premise_id"}
          defaultValue=""
          control={control}
          label={t("PREMISE")}
          required
          error={errors?.premise_id}
          options={premises?.map((premise) => (
            <MenuItem key={premise.id} value={premise.id}>
              {premise.name}
            </MenuItem>
          ))}
        />
      </Grid>
      <TripCategoriesDomain />
      <Divider sx={DIVIDER_STYLE} />
      <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{t("CONFIGURATION")}</Typography>
        <Typography variant="body1">
          {t("CONFIGURATION_DESCRIPTION")}
        </Typography>
      </Grid>
        <Grid item xs={6} >
          <Typography>{t("TICKET_ORDER_LIMIT")}*</Typography>
          <TextField
            placeholder={"00"}
            {...register("limit_tickets_order")}
            error={!!errors?.limit_tickets_order}
            helperText={errors?.limit_tickets_order?.message}
            fullWidth
            sx={focusColor}
            type="number"
            inputProps={{ min: 0 }}
            required
          />
        </Grid>
        <Grid item xs={12} >
          <FormControlLabel
            control={
              <Checkbox
                {...register("without_night")}
                className="checkbox-oniria"
                checked={watch("without_night")}
              />
            }
            label={
              <div>
                {t("WITHOUT_CHECK_IN")}
                <InfoToolTip text={t("WITHOUT_CHECK_IN_INFO")} />
              </div>
            }
          />
        </Grid>
        <Grid item xs={12} >
          <FormControlLabel
            control={
              <Checkbox
                {...register("passport_required")}
                className="checkbox-oniria"
                checked={watch("passport_required")}
              />
            }
            label={
              <div>
                {t("PASSPORT_REQUIRED")}
                <InfoToolTip text={t("PASSPORT_REQUIRED_INFO")} />
              </div>
            }
          />
        </Grid>
        <Grid item xs={12} >
          <FormControlLabel
            control={
              <Checkbox
                {...register("autovalidation")}
                className="checkbox-oniria"
                checked={watch("autovalidation")}
              />
            }
            label={
              <div>
                {t("TICKET_SELF_VALIDATION")}
                <InfoToolTip text={t("TICKET_SELF_VALIDATION_INFO")} />
              </div>
            }
          />
        </Grid>
        <Grid item xs={12} >
          <FormControlLabel
            control={
              <Checkbox
                {...register("has_double_payment")}
                className="checkbox-oniria"
                checked={watch("has_double_payment")}
              />
            }
            label={
              <div>
                {t("DOUBLE_PAYMENT")}
                <InfoToolTip text={t("DOUBLE_PAYMENT_INFO")} />
              </div>
            }
          />
        </Grid>
        {isDoublePayment && (
          <>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Typography>{t("FIRST_PAYMENT_PERCENT")}</Typography>
                <InfoToolTip text={t("FIRST_PAYMENT_PERCENT_INFO")} />
              </Box>
              <TextField
                placeholder={"Ej.- 20%"}
                {...register("first_payment_percentage")}
                error={!!errors?.first_payment_percentage}
                helperText={errors?.first_payment_percentage?.message}
                fullWidth
                sx={focusColor}
                type="number"
                inputProps={{ min: 0, max: 100 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>{t("SECOND_PAYMENT_END_DATE")}</Typography>
              <Controller
                name="second_payment_end_date"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="date"
                    {...field}
                    error={!!errors?.second_payment_end_date}
                    helperText={errors?.second_payment_end_date?.message || ""}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={focusColor}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>{t("SECOND_PAYMENT_END_TIME")}</Typography>
              <Controller
                name="second_payment_end_date_time"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="time"
                    {...field}
                    error={!!errors?.end_date}
                    helperText={errors?.end_date?.message || ""}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={focusColor}
                  />
                )}
              />
            </Grid>
          </>
        )}{" "}
      </Grid>
    </>
  );
};

export default TripConfiguration;
