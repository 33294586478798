import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, Grid, Step, StepButton, Stepper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createTripErrorsAllOk, eventLanguageErrorsAllOk } from "../../classes/tripClass";
import { dateWithoutTZ, sanitizeBoolean, sanitizeForSending } from "../../constants/utils";
import { EVENT_TABS } from "../../constants/variables";
import useUserRoles from "../../hooks/useUserRoles";
import {
  createExtra,
  createTicket,
  editEvent,
  updateExtra,
  updateTicket,
} from "../../services/eventsServices";
import {
  allFieldsOk,
  cleanObjectOfVoidFields,
  transformObjectWithUrls,
} from "../shared/FormsValidator";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";
import Details from "./details/Details";
import Extras from "./extras/Extras";
import EventLanguages from "./languages/EventLanguage";
import MoreInformation from "./moreInformation/MoreInformation";
import Tickets from "./trip/tickets/Tickets";

const EditEvent = (props) => {
  const { t } = useTranslation();
  const { extraId } = props;
  const [buttonClicked, setButtonClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const event = props.formData;
  const steps =
    event.event_type === "trip"
      ? [t("DETAILS"), t("MORE_INFO"), t("TICKETS"), t("EXTRAS")]
      : [t("DETAILS"), t("TICKETS"), t("EXTRAS")];
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState(event);
  const [formParamsErrors, setFormParamsErrors] = useState(
    JSON.parse(JSON.stringify(createTripErrorsAllOk))
  );
  const { userCanEdit } = useUserRoles();
  const selectedEventsTab = useSelector((state) => state.b2bInfo.selectedTab);
  const isDisabled = selectedEventsTab === EVENT_TABS.B2B || !userCanEdit;

  useEffect(() => {
    if (event && event.details && event.details.event_language_infos.length > 0) {
      for (let i = 0; i < event.details.event_language_infos.length; i++) {
        setFormParamsErrors((prev) => ({
          ...prev,
          details: {
            ...prev.details,
            event_language_infos: [
              ...prev.details.event_language_infos,
              JSON.parse(JSON.stringify(eventLanguageErrorsAllOk)),
            ],
          },
        }));
      }
    }
  }, [event]);

  const setFormParams = (objectName, field, e, atribute = "value") => {
    let object = formData[objectName];
    object[field] = e.target[atribute];
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrors = (objectName, field, value) => {
    let object = JSON.parse(JSON.stringify(formParamsErrors[objectName]));
    object[field] = value;
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrorsTicketsAndExtras = (objectName, value) => {
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: value,
    }));
  };

  const setEventLanguageInfoError = (index, field, value) => {
    setFormParamsErrors((prev) => {
      const updatedEventLanguageInfos = [...prev.details.event_language_infos];
      if (updatedEventLanguageInfos[index]) {
        updatedEventLanguageInfos[index] = {
          ...updatedEventLanguageInfos[index],
          [field]: value,
        };
      } else {
        return prev;
      }
      return {
        ...prev,
        details: {
          ...prev.details,
          event_language_infos: updatedEventLanguageInfos,
        },
      };
    });
  };

  const setFormParamsValue = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const handleNext = () => {
    setButtonClicked(true);
    if (!allFieldsOk(formParamsErrors)) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setButtonClicked(true);
    if (!allFieldsOk(formParamsErrors)) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setButtonClicked(true);
    if (!allFieldsOk(formParamsErrors)) {
      return;
    }
    setActiveStep(step);
  };

  const handleSubmit = async () => {
    const { id, details, moreInformation, tickets, extras } = formData;
    //
    try {
      //
      const tripComplete = JSON.parse(JSON.stringify({ ...details, ...moreInformation }));

      tripComplete.description = sanitizeForSending(tripComplete.description);
      tripComplete.not_included_options = sanitizeForSending(tripComplete.not_included_options);
      tripComplete.additional_info = sanitizeForSending(tripComplete.additional_info);

      tripComplete.rating = tripComplete?.rating ? parseFloat(tripComplete.rating) : null;
      tripComplete.min_age = parseInt(tripComplete.min_age || "0");
      tripComplete.latitude = parseFloat(tripComplete.latitude || "0");
      tripComplete.longitude = parseFloat(tripComplete.longitude || "0");
      tripComplete.limit_tickets_order = parseInt(tripComplete.limit_tickets_order || "0");
      tripComplete.countries = parseInt(tripComplete.countries || "0");
      tripComplete.cities = parseInt(tripComplete.cities || "0");
      tripComplete.days = parseInt(tripComplete.days || "0");
      tripComplete.nights = parseInt(tripComplete.nights || "0");
      //Booleans
      tripComplete.passport_required = sanitizeBoolean(tripComplete.passport_required);
      tripComplete.without_night = sanitizeBoolean(tripComplete.without_night);
      tripComplete.multi_date = sanitizeBoolean(tripComplete.multi_date);
      //Double payment
      tripComplete.has_double_payment = sanitizeBoolean(tripComplete.has_double_payment);
      tripComplete.first_payment_percentage = parseFloat(
        tripComplete.first_payment_percentage || "0"
      );
      if (
        typeof tripComplete.slider_urls === "string" ||
        (typeof tripComplete.slider_urls === "object" && tripComplete.slider_urls.mime)
      ) {
        tripComplete.slider_urls = [tripComplete.slider_urls];
      }
      //
      if (tripComplete.event_categories.length >= 0) {
        tripComplete.event_categories.forEach((element) => {
          element.event_id = id;
        });
      }
      //
      tripComplete.total_capacity = 0;
      for (let i = 0; i < tickets.length; i++) {
        tripComplete.total_capacity += parseInt(tickets[i].initial_stock);
      }
      //
      const optionValues = [];
      for (let i = 0; i < tripComplete.included_options.length; i++) {
        optionValues.push(tripComplete.included_options[i].value);
      }
      tripComplete.included_options = JSON.stringify(optionValues);
      //
      for (let i = 0; i < tripComplete.itineraries.length; i++) {
        if (!tripComplete.itineraries[i].event_id) {
          tripComplete.itineraries[i].event_id = id;
        }
        tripComplete.itineraries[i].position = i;
      }
      //
      for (let i = 0; i < tripComplete.blocks.length; i++) {
        if (!tripComplete.blocks[i].event_id) {
          tripComplete.blocks[i].event_id = id;
        }
        if (
          typeof tripComplete.blocks[i].images_urls === "string" &&
          tripComplete.blocks[i].images_urls !== ""
        ) {
          tripComplete.blocks[i].images_urls = [tripComplete.blocks[i].images_urls];
        } else if (tripComplete.blocks[i].images_urls === "") {
          delete tripComplete.blocks[i].images_urls;
        }
        tripComplete.blocks[i].position = i;
      }
      // LANGUAGES
      if (tripComplete.event_language_infos.length > 0) {
        for (let i = 0; i < tripComplete.event_language_infos.length; i++) {
          const language = tripComplete.event_language_infos[i];
          language.event_id = id;
          delete language.id;
          language.description = sanitizeForSending(language.description);
          language.not_included_options = sanitizeForSending(language.not_included_options);
          language.additional_info = sanitizeForSending(language.additional_info);
        }
      }
      //OPTIONAL DATES
      tripComplete.start_date = tripComplete.start_date || null;
      tripComplete.start_date_time = tripComplete.start_date_time || null;
      tripComplete.end_date = tripComplete.end_date || null;
      tripComplete.end_date_time = tripComplete.end_date_time || null;
      tripComplete.publication_date = tripComplete.publication_date || null;
      tripComplete.publication_date_time = tripComplete.publication_date_time || null;
      tripComplete.end_of_publication_date = tripComplete.end_of_publication_date || null;
      tripComplete.end_of_publication_date_time = tripComplete.end_of_publication_date_time || null;
      tripComplete.start_sale_date = tripComplete.start_sale_date || null;
      tripComplete.start_sale_date_time = tripComplete.start_sale_date_time || null;
      tripComplete.end_sale_date = tripComplete.end_sale_date || null;
      tripComplete.end_sale_date_time = tripComplete.end_sale_date_time || null;
      tripComplete.second_payment_end_date = tripComplete.second_payment_end_date || null;
      tripComplete.second_payment_end_date_time = tripComplete.second_payment_end_date_time || null;
      tripComplete.second_payment_start_date = tripComplete.second_payment_start_date || null;
      tripComplete.second_payment_start_date_time =
        tripComplete.second_payment_start_date_time || null;
      //
      const tripToSend = await transformObjectWithUrls(tripComplete);
      await editEvent(id, tripToSend);
      //
      for (let i = 0; i < tickets.length; i++) {
        const ticketCopy = await JSON.parse(JSON.stringify(tickets[i]));
        const ticketToSend = await cleanObjectOfVoidFields(ticketCopy);
        ticketToSend.position = i;
        ticketToSend.description = sanitizeForSending(ticketToSend.description);
        // TODO: Revisar como gestionamos estos atributos
        ticketToSend.initial_stock = parseInt(ticketToSend.initial_stock || "0");
        ticketToSend.price = parseFloat(ticketToSend.price || "0");
        ticketToSend.only_international = sanitizeBoolean(ticketToSend.only_international);
        ticketToSend.iva = parseInt(ticketToSend.iva || "0");
        ticketToSend.only_in_app = sanitizeBoolean(ticketToSend.only_in_app);
        delete ticketToSend.stock;
        // PROMO CODE DISCOUNTS
        for (let k = 0; k < ticketToSend.promotional_code_discounts.length; k++) {
          ticketToSend.promotional_code_discounts[k].discount = parseFloat(
            ticketToSend.promotional_code_discounts[k].discount
          );
          ticketToSend.promotional_code_discounts[k].is_active = sanitizeBoolean(
            ticketToSend.promotional_code_discounts[k].is_active
          );
          ticketToSend.promotional_code_discounts[k].ticket_id = ticketToSend.id;
          ticketToSend.promotional_code_discounts[k].code =
            ticketToSend.promotional_code_discounts[k].code || ".";
        }
        //
        if (ticketToSend.international_discount)
          ticketToSend.international_discount = parseFloat(ticketToSend.international_discount);
        if (ticketToSend.early_payment_discount)
          ticketToSend.early_payment_discount = parseFloat(ticketToSend.early_payment_discount);
        if (ticketToSend.promotional_code_discount)
          ticketToSend.promotional_code_discount = parseFloat(
            ticketToSend.promotional_code_discount
          );
        //
        for (let j = 0; j < ticketToSend.group_discounts.length; j++) {
          ticketToSend.group_discounts[j].min_size = parseInt(
            ticketToSend.group_discounts[j].min_size
          );
          ticketToSend.group_discounts[j].max_size = parseInt(
            ticketToSend.group_discounts[j].max_size
          );
          ticketToSend.group_discounts[j].discount = parseFloat(
            ticketToSend.group_discounts[j].discount
          );
          ticketToSend.group_discounts[j].ticket_id = tickets[i].id;
        }
        //TICKET LANGUAGES
        if (ticketToSend.ticket_language_infos.length > 0) {
          for (let j = 0; j < ticketToSend.ticket_language_infos.length; j++) {
            const language = ticketToSend.ticket_language_infos[j];
            language.description = sanitizeForSending(language.description);
            language.ticket_id = ticketToSend.id;
            delete language.id;
          }
        }
        // TICKET DATES
        ticketToSend.start_date = ticketToSend.start_date
          ? dateWithoutTZ(ticketToSend.start_date)
          : null;
        ticketToSend.end_date = ticketToSend.end_date ? dateWithoutTZ(ticketToSend.end_date) : null;
        ticketToSend.second_payment_end_date = ticketToSend.second_payment_end_date
          ? dateWithoutTZ(ticketToSend.second_payment_end_date)
          : null;
        //
        if (ticketToSend.id) {
          await updateTicket(ticketToSend.id, ticketToSend);
        } else {
          ticketToSend.event_id = id;
          await createTicket(ticketToSend);
        }
      }
      // EXTRAS
      for (let i = 0; i < extras.length; i++) {
        const extraCopy = await JSON.parse(JSON.stringify(extras[i]));
        const extraImages = await transformObjectWithUrls(extraCopy);
        const extraToSend = await cleanObjectOfVoidFields(extraImages);
        extraToSend.is_visible = sanitizeBoolean(extraToSend.is_visible);
        extraToSend.only_international = sanitizeBoolean(extraToSend.only_international);
        extraToSend.no_after_sales = sanitizeBoolean(extraToSend.no_after_sales);
        extraToSend.initial_stock = parseInt(extraToSend.initial_stock);
        extraToSend.price = parseFloat(extraToSend.price);
        extraToSend.stock_by_ticket_max = parseInt(extraToSend.stock_by_ticket_max);
        extraToSend.times_can_be_consumed = parseInt(extraToSend.times_can_be_consumed ?? 1);
        extraToSend.position = i;
        extraToSend.extra_template_id = extraToSend.extra_template_id ?? "";
        extraToSend.description = sanitizeForSending(extraToSend.description);
        extraToSend.description_design = sanitizeForSending(extraToSend.description_design);

        //EXTRA LANGUAGES
        if (extraToSend.extra_language_infos?.length > 0) {
          for (let j = 0; j < extraToSend.extra_language_infos.length; j++) {
            const language = extraToSend.extra_language_infos[j];
            language.description = sanitizeForSending(language.description);
            language.description_design = sanitizeForSending(language.description_design);
            language.extra_id = extraToSend.id;
            delete language.id;
          }
        }
        //
        if (extraToSend.id) {
          await updateExtra(extraToSend.id, extraToSend);
        } else {
          extraToSend.event_id = id;
          await createExtra(extraToSend);
        }
      }
      //
      toastMessageSuccess(t("EDIT_SUCCESS"));
      // setTimeout(() => {
      //   navigate(event.event_type === "trip" ? "/trips" : "/events");
      // }, 1500);
    } catch (error) {
      console.error("Submit error: ", error);
      setLoading(false);
      toastMessageError(error.response.data.error || t("ERROR"));
    } finally {
      setLoading(false);
    }
  };

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    if (event.event_type === "trip" && activeStep === 3) {
      return true;
    } else if (event.event_type !== "trip" && activeStep === 2) {
      return true;
    }
    return false;
  };

  const formController = {
    formData,
    setFormData,
    formParams: setFormParams,
    next: handleNext,
    back: handleBack,
    validation: formParamsErrors,
    isFirstStep,
    isLastStep,
    finish: handleSubmit,
    setFormParamsNotEvent,
    setFormErrors,
    setFormErrorsTicketsAndExtras,
    setFormParamsValue,
    setFormParamsErrors,
    setEventLanguageInfoError,
  };

  useEffect(() => {
    if (extraId) {
      let step = event.event_type === "trip" ? 3 : 2;
      setActiveStep(step);
    }
  }, [extraId]);

  const addComponent = (activeStep) => {
    if (event.event_type === "trip") {
      return activeStep === 0 ? (
        <Details
          formController={formController}
          editEvent
          typeTrip
          buttonClicked={buttonClicked}
          setButtonClicked={setButtonClicked}
          isDisabled={isDisabled}
        />
      ) : activeStep === 1 ? (
        <MoreInformation
          formController={formController}
          editEvent
          buttonClicked={buttonClicked}
          setButtonClicked={setButtonClicked}
          isDisabled={isDisabled}
        />
      ) : activeStep === 2 ? (
        <Tickets
          formController={formController}
          eventType={"trip"}
          buttonClicked={buttonClicked}
          setButtonClicked={setButtonClicked}
          isDisabled={isDisabled}
        />
      ) : (
        <Extras
          formController={formController}
          buttonClicked={buttonClicked}
          setButtonClicked={setButtonClicked}
          isDisabled={isDisabled}
          extraId={extraId}
        />
      );
    } else {
      return activeStep === 0 ? (
        <Details
          formController={formController}
          editEvent
          isEvent
          buttonClicked={buttonClicked}
          setButtonClicked={setButtonClicked}
          isDisabled={isDisabled}
        />
      ) : activeStep === 1 ? (
        <Tickets
          formController={formController}
          eventType={"event"}
          buttonClicked={buttonClicked}
          setButtonClicked={setButtonClicked}
          isDisabled={isDisabled}
        />
      ) : (
        <Extras
          formController={formController}
          buttonClicked={buttonClicked}
          setButtonClicked={setButtonClicked}
          isDisabled={isDisabled}
          extraId={extraId}
        />
      );
    }
  };

  const handleSaveButton = () => {
    setButtonClicked(true);
    setLoading(true);
    if (!allFieldsOk(formParamsErrors)) {
      setLoading(false);
      return;
    }
    handleSubmit();
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={10}
        lg={12}
        sx={{
          mt: 5,
          mb: 5,
          display: "flex",
          flexDirection: "row",
          justifyContent: "initial",
        }}
      >
        <Grid container flexWrap="nowrap">
          <Button
            onClick={handleBack}
            sx={{ color: "var(--secondary-color)" }}
            disabled={activeStep === 0}
          >
            <ArrowBackIos fontSize="small" sx={{ color: "var(--secondary-color)" }} />
          </Button>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step
                key={label}
                sx={{
                  "& .MuiStepLabel-root .Mui-active": {
                    color: "var(--secondary-color)",
                  },
                  "& .MuiStepLabel-root .Mui-disabled": { display: "none" },
                  "& .MuiStepLabel-root .Mui-completed": { display: "none" },
                  display: {
                    xs: activeStep !== index ? "none" : "block",
                    sm: "block",
                  },
                }}
              >
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <Button
            sx={{ color: "var(--secondary-color)" }}
            onClick={handleNext}
            disabled={activeStep === steps.length - 1}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Grid>
      </Grid>
      {selectedEventsTab === EVENT_TABS.MY_EVENTS && userCanEdit && (
        <Grid item xs={12} md={8} container justifyContent="flex-end" onClick={handleSaveButton}>
          <Button className="oniria-btn" sx={{ color: "#fff" }} disabled={loading}>
            {loading ? t("SAVING") : t("SAVE")}
          </Button>
        </Grid>
      )}
      {!allFieldsOk(formParamsErrors) && buttonClicked && (
        <Typography sx={{ color: "var(--oniria-red)" }}>*{t("CHECK_FIELDS")}</Typography>
      )}
      <EventLanguages formController={formController} />
      {addComponent(activeStep)}
    </>
  );
};

export default EditEvent;
