import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Grid, InputLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useEventLanguage from "../../../../hooks/useEventLanguage";
import { checkFieldValue } from "../../../shared/FormsValidator";
import TextEditor from "../../../textEditor/TextEditor";
import { checkRenderEditorJs } from "../../../textEditor/tools";
import LanguageTabs from "../../languages/LanguageTabs";

const EventDescription = ({
  typeTrip,
  trip,
  setFormErrors,
  detailsErrors,
  buttonClicked,
  setFormData,
  setFormParamsNotEvent,
  setEventLanguageInfoError,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { event_language_infos, default_language } = trip;

  const {
    selectedLanguage,
    setSelectedLanguage,
    handleChangeAttribute,
    selectedLanguageInfo,
    selectedLanguageIndex,
  } = useEventLanguage(
    event_language_infos,
    setFormData,
    setEventLanguageInfoError,
    default_language
  );

  const languageErrors = detailsErrors?.event_language_infos[selectedLanguageIndex];

  const handleChangeName = (data) => {
    handleChangeAttribute("description", data, /^.[\s\S]{0,5000000}$/, t("MAX_5000"));
  };

  const handleChangeDescription = (data) => {
    checkFieldValue(
      "details",
      "description",
      data,
      /^.[\s\S]{0,5000000}$/,
      t("MAX_5000"),
      true,
      setFormParamsNotEvent,
      setFormErrors
    );
  };

  return (
    <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
      <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
        {typeTrip ? t("DESCRIPTION_TRIP") : t("DESCRIPTION_EVENT")}
      </InputLabel>
      {event_language_infos?.length > 0 ? (
        <LanguageTabs
          event_languages_info={trip.event_language_infos}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          defaultLanguage={default_language}
        />
      ) : null}
      {selectedLanguage === default_language ? (
        <>
          {checkRenderEditorJs(pathname, trip.description) ? (
            <TextEditor
              data={trip?.description}
              setData={handleChangeDescription}
              id="description"
            />
          ) : (
            <CKEditor
              editor={ClassicEditor}
              data={trip?.description}
              onChange={(_, editor) => {
                const data = editor.getData();
                handleChangeDescription(data);
              }}
              config={{
                removePlugins: [
                  "CKFinderUploadAdapter",
                  "CKFinder",
                  "EasyImage",
                  "Image",
                  "ImageCaption",
                  "ImageStyle",
                  "ImageToolbar",
                  "ImageUpload",
                  "MediaEmbed",
                ],
              }}
            />
          )}
          {detailsErrors.description !== "" && buttonClicked && (
            <Typography sx={{ color: "#d32f2f" }}>*{t("REQUIRED_FIELD")}</Typography>
          )}
        </>
      ) : (
        <div>
          <TextEditor
            data={selectedLanguageInfo?.description}
            setData={handleChangeName}
            id={`description-${selectedLanguage}`}
          />
          {languageErrors?.description && languageErrors?.description !== "" && buttonClicked && (
            <Typography sx={{ color: "#d32f2f" }}>*{t("REQUIRED_FIELD")}</Typography>
          )}
        </div>
      )}
    </Grid>
  );
};

export default EventDescription;
