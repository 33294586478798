import { Close, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../shared/CustomDialog";
import InfoToolTip from "../../shared/InfoToolTip";
import { focusColor } from "../../shared/textFieldStyle";

const TripTicketDiscounts = ({ index }) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const {
    watch,
    setValue,
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const ticket = watch(`tickets.${index}`) || {};

  const handleOpenDialog = (index) => {
    setSelectedIndex(index);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenConfirm = (index) => {
    setSelectedIndex(index);
    setOpenConfirm(true);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleDesactiveCode = () => {
    setValue(`tickets.${index}.promotional_code_discounts.${selectedIndex}.is_active`, false);
    handleCloseDialog();
  };

  const handleActiveCode = () => {
    setValue(`tickets.${index}.promotional_code_discounts.${selectedIndex}.is_active`, true);
    handleCloseConfirm();
  };

  const handleAddPromoCode = () => {
    setValue(`tickets.${index}.promotional_code_discounts`, [
      ...ticket.promotional_code_discounts,
      { code: "", discount: 0, is_active: true },
    ]);
  };

  const removeGroupDiscount = (discountIndex) => {
    setValue(
      `tickets.${index}.group_discounts`,
      ticket.group_discounts.filter((_, i) => i !== discountIndex)
    );
  };

  const handleAddGroupDiscount = () => {
    setValue(`tickets.${index}.group_discounts`, [
      ...ticket.group_discounts,
      { min_size: 0, max_size: 0, discount: 0 },
    ]);
  };

  return (
    <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
      <Grid item xs={12}>
        <InputLabel
          sx={{
            fontSize: "18px",
            color: "black",
            textAlign: "left",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {t("DISCOUNTS_MEMBERS")}
          <InfoToolTip text={t("DISCOUNTS_MEMBERS_INFO")} />
        </InputLabel>
        <TextField
          placeholder={t("IN_EU")}
          {...register(`tickets.${index}.international_discount`)}
          error={!!errors?.tickets?.[index]?.international_discount}
          helperText={errors?.tickets?.[index]?.international_discount?.message}
          fullWidth
          sx={focusColor}
          type="number"
          inputProps={{ min: 0 }}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel
          sx={{
            fontSize: "18px",
            color: "black",
            textAlign: "left",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            marginBottom: "1rem",
          }}
        >
          {t("PROMOTIONAL_CODE")}
          <InfoToolTip text={t("PROMOTIONAL_CODE_INFO")} />
        </InputLabel>
      </Grid>
      {ticket?.promotional_code_discounts?.map((promo, promoIndex) => {
        return (
          <Grid
            xs={12}
            item
            container
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              border: "1px solid lightgrey",
              borderRadius: "4px",
              marginBottom: "1rem",
              padding: "1rem",
            }}
          >
            <Grid item xs={5}>
              <TextField
                {...register(`tickets.${index}.promotional_code_discounts.${promoIndex}.code`)}
                error={!!errors?.tickets?.[index]?.promotional_code_discounts?.[promoIndex]?.code}
                helperText={
                  errors?.tickets?.[index]?.promotional_code_discounts?.[promoIndex]?.code?.message
                }
                fullWidth
                sx={focusColor}
                placeholder={t("PROMOTIONAL_CODE")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                {...register(`tickets.${index}.promotional_code_discounts.${promoIndex}.discount`)}
                error={
                  !!errors?.tickets?.[index]?.promotional_code_discounts?.[promoIndex]?.discount
                }
                helperText={
                  errors?.tickets?.[index]?.promotional_code_discounts?.[promoIndex]?.discount
                    ?.message
                }
                fullWidth
                sx={focusColor}
                type="number"
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={2} container justifyContent="center">
              {promo.is_active ? (
                <IconButton onClick={() => handleOpenDialog(promoIndex)}>
                  <Delete sx={{ color: "var(--oniria-red)" }} />
                </IconButton>
              ) : (
                <Button
                  sx={{ color: "var(--oniria-red)", border: "1px solid var(--oniria-red)" }}
                  onClick={() => handleOpenConfirm(promoIndex)}
                >
                  {t("INACTIVE ")}
                </Button>
              )}
            </Grid>
          </Grid>
        );
      })}
      <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
        <Button
          fullWidth
          variant="outlined"
          size="large"
          sx={{
            color: "var(--secondary-color)",
            borderColor: "var(--secondary-color)",
            "&:hover": {
              color: "var(--secondary-color)",
              borderColor: "var(--secondary-color)",
              backgroundColor: "white",
            },
          }}
          onClick={handleAddPromoCode}
        >
          {t("ADD_PROMOTIONAL_CODE")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <InputLabel sx={{ fontSize: "18px", color: "black", textAlign: "left" }}>
          {t("DISCOUNT_PAYMENT")}
        </InputLabel>
        <TextField
          placeholder={t("IN_EU")}
          {...register(`tickets.${index}.early_payment_discount`)}
          error={!!errors?.tickets?.[index]?.early_payment_discount}
          helperText={errors?.tickets?.[index]?.early_payment_discount?.message}
          fullWidth
          sx={focusColor}
          type="number"
          inputProps={{ min: 0 }}
        />
      </Grid>
      <Grid
        container
        columnSpacing={2}
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <Grid item xs={12}>
          <InputLabel sx={{ fontSize: "18px", color: "black", textAlign: "left" }}>
            {t("VALID_UNTIL")}
          </InputLabel>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={`tickets.${index}.early_payment_discount_date`}
            control={control}
            render={({ field }) => (
              <TextField
                type="date"
                {...field}
                error={!!errors?.tickets?.[index]?.early_payment_discount_date}
                helperText={errors?.tickets?.[index]?.early_payment_discount_date?.message}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={focusColor}
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                  max: "2100-12-31",
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={`tickets.${index}.early_payment_discount_date_time`}
            control={control}
            render={({ field }) => (
              <TextField
                type="time"
                {...field}
                error={!!errors?.tickets?.[index]?.early_payment_discount_date_time}
                helperText={errors?.tickets?.[index]?.early_payment_discount_date_time?.message}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={focusColor}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
          {t("GROUP_DISCOUNT")}
        </InputLabel>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: "18px", color: "black", mb: 2 }}>
          {t("EXAMPLE_DISCOUNT")}
        </Typography>
      </Grid>
      <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          {ticket.group_discounts.map((_, discountIndex) => {
            return (
              <Box
                component={Paper}
                elevation={0}
                sx={{
                  py: 2,
                  px: 1,
                  mb: 2,
                  border: "1px solid #E4E4E4",
                  borderRadius: "15px",
                }}
              >
                <Grid container spacing={1} sx={{ alignItems: "center" }}>
                  <Grid item xs={12} sx={{ display: "flex", flexDirection: "row-reverse" }}>
                    {discountIndex === ticket.group_discounts.length - 1 && (
                      <IconButton onClick={() => removeGroupDiscount(discountIndex)}>
                        <Close fontSize="small" />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={2} sx={{ textAlign: "center" }}>
                    <Typography sx={{ mt: 1, textAlign: "center" }}>{t("FROM_MIN")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      {...register(`tickets.${index}.group_discounts.${discountIndex}.min_size`)}
                      error={!!errors?.tickets?.[index]?.group_discounts?.[discountIndex]?.min_size}
                      helperText={
                        errors?.tickets?.[index]?.group_discounts?.[discountIndex]?.min_size
                          ?.message
                      }
                      fullWidth
                      sx={focusColor}
                      placeholder={t("FROM_MIN")}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1} sx={{ textAlign: "center" }}>
                    <Typography sx={{ mt: 1 }}>{t("TO")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      {...register(`tickets.${index}.group_discounts.${discountIndex}.max_size`)}
                      error={!!errors?.tickets?.[index]?.group_discounts?.[discountIndex]?.max_size}
                      helperText={
                        errors?.tickets?.[index]?.group_discounts?.[discountIndex]?.max_size
                          ?.message
                      }
                      fullWidth
                      sx={focusColor}
                      placeholder={t("TO")}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} sx={{ textAlign: "center" }}>
                    <Typography sx={{ mt: 1 }}>{t("DISCOUNT_OF")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      {...register(`tickets.${index}.group_discounts.${discountIndex}.discount`)}
                      error={!!errors?.tickets?.[index]?.group_discounts?.[discountIndex]?.discount}
                      helperText={
                        errors?.tickets?.[index]?.group_discounts?.[discountIndex]?.discount
                          ?.message
                      }
                      fullWidth
                      sx={focusColor}
                      placeholder={t("DISCOUNT_OF")}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1} sx={{ textAlign: "center" }}>
                    <Typography sx={{ mt: 1 }}>€</Typography>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              sx={{
                color: "var(--secondary-color)",
                borderColor: "var(--secondary-color)",
                "&:hover": {
                  color: "var(--secondary-color)",
                  borderColor: "var(--secondary-color)",
                  backgroundColor: "white",
                },
              }}
              onClick={handleAddGroupDiscount}
            >
              {t("ADD_DISCOUNT_GROUP")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <CustomDialog
        isOpen={openDialog}
        onClose={handleCloseDialog}
        title={t("UNCHECK_CODE")}
        content={t("UNCHECK_CODE_TEXT")}
        onAccept={(e) => handleDesactiveCode(e)}
      />
      <CustomDialog
        isOpen={openConfirm}
        onClose={handleCloseConfirm}
        title={t("CHECK_CODE")}
        content={t("CHECK_CODE_TEXT")}
        onAccept={(e) => handleActiveCode(e)}
      />
    </Grid>
  );
};

export default TripTicketDiscounts;
