import { uploadImage } from "../../services/utilsServices";

export const checkField = (
  classForm,
  key,
  e,
  regex,
  errorMessage,
  canBeEmpty,
  formParams,
  setErrors,
  parameter = "value"
) => {
  let errorValue = "";
  const newValue = e.target[parameter];
  if (newValue === "" && !canBeEmpty) {
    errorValue = "empty";
  } else if (regex.test(newValue)) {
    errorValue = "";
  } else {
    errorValue = errorMessage;
  }
  setErrors(classForm, key, errorValue);
  formParams(classForm, key, e, parameter);
};

export const checkFieldValue = (
  classForm,
  key,
  value,
  regex,
  errorMessage,
  canBeEmpty,
  formParams,
  setErrors
) => {
  let errorValue = "";
  if (value === "" && !canBeEmpty) {
    errorValue = "empty";
  } else if (regex.test(value)) {
    errorValue = "";
  } else {
    errorValue = errorMessage;
  }
  setErrors(classForm, key, errorValue);
  formParams(classForm, key, value);
};

export const allFieldsOk = (errors) => {
  if (!errors || typeof errors !== "object") {
    return true;
  }
  let allOk = true;
  const errorsArray = Object?.values(errors) || [];
  errorsArray?.map((error, _) => {
    //
    if (Array?.isArray(error) || typeof error === "object") {
      const nestedCheck = allFieldsOk(error);
      if (!nestedCheck) allOk = false;
    }
    //
    else if (error !== "" && error !== undefined && error !== null) {
      allOk = false;
    }
  });
  return allOk;
};
export const selectedCheckbox = (classForm, key, e, formParams) => {
  formParams(classForm, key, e, "checked");
};

export const checkFieldFiles = (classForm, key, value, canBeEmpty, formParams, setErrors) => {
  let errorValue = "";
  if (value.length !== undefined && value.length === 0 && !canBeEmpty) {
    errorValue = "empty";
  }
  setErrors(classForm, key, errorValue);
  formParams(classForm, key, value);
};
export const handleImageSelect = (
  classForm,
  url,
  identify,
  setFormParamsNotEvent,
  setErrors,
  canBeEmpty = false
) => {
  if (Array.isArray(url)) {
    const imageArray = [];
    for (let i = 0; i < url.length; i++) {
      const urlParams = url[i].split(";");
      const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
      imageArray.push(imageObject);
    }
    checkFieldFiles(classForm, identify, imageArray, canBeEmpty, setFormParamsNotEvent, setErrors);
  } else {
    const urlParams = url.split(";");
    const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
    checkFieldFiles(classForm, identify, imageObject, canBeEmpty, setFormParamsNotEvent, setErrors);
  }
};

export const transformObjectWithUrls = async (object) => {
  const objectToReturn = {};
  const keys = Object.keys(object);
  for (let i = 0; i < keys.length; i++) {
    // TODO: En caso de que haya algun objeto que no sea imagen habrá que gestionarlo aqui
    if (
      object[keys[i]] &&
      typeof object[keys[i]] === "object" &&
      object[keys[i]].image &&
      object[keys[i]].mime
    ) {
      const imageUrl = await uploadImage(object[keys[i]]);
      objectToReturn[keys[i]] = imageUrl.imageURL;
    } else if (Array.isArray(object[keys[i]])) {
      const arrayAttribute = object[keys[i]];
      for (let j = 0; j < arrayAttribute.length; j++) {
        //
        if (!objectToReturn[keys[i]]) {
          objectToReturn[keys[i]] = [];
        }
        // En caso de objeto
        if (arrayAttribute[j] && typeof arrayAttribute[j] === "object") {
          // Si es imagen
          if (arrayAttribute[j].image && arrayAttribute[j].mime) {
            const imageUrl = await uploadImage(arrayAttribute[j]);
            objectToReturn[keys[i]][j] = imageUrl.imageURL;
          }
          // Otros objetos
          else {
            objectToReturn[keys[i]][j] = await transformObjectWithUrls(arrayAttribute[j]);
          }
        } else {
          objectToReturn[keys[i]][j] = arrayAttribute[j];
        }
      }
    } else {
      objectToReturn[keys[i]] = object[keys[i]];
    }
  }

  return objectToReturn;
};

// TODO: Esta no tiene que ser asincrona
export const cleanObjectOfVoidFields = async (object) => {
  const objectToReturn = {};
  const keys = Object.keys(object);
  for (let i = 0; i < keys.length; i++) {
    if (object[keys[i]] !== "") {
      objectToReturn[keys[i]] = object[keys[i]];
    }
  }
  return objectToReturn;
};

export const objectWithBooleanParams = (object, keysToCast) => {
  const objectToReturn = {};
  const keys = Object.keys(object);

  for (let i = 0; i < keys.length; i++) {
    if (keysToCast.includes(keys[i])) {
      objectToReturn[keys[i]] = object[keys[i]] === 1;
    } else {
      objectToReturn[keys[i]] = object[keys[i]];
    }
  }
  return objectToReturn;
};

export const objectWithTemplateKeys = (object, template, extraKeysToInclude = []) => {
  const objectToReturn = {};
  const templateKeys = Object.keys(template);
  const keys = Object.keys(object);
  for (let i = 0; i < keys.length; i++) {
    if (templateKeys.includes(keys[i]) || extraKeysToInclude.includes(keys[i])) {
      if (object[keys[i]]) {
        objectToReturn[keys[i]] = object[keys[i]];
      } else {
        objectToReturn[keys[i]] = template[keys[i]];
      }
    }
  }
  return objectToReturn;
};

/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
export function isValidIBANNumber(input) {
  var CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    CR: 22,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
  };
  var iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
    digits;
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55;
  });
  // final check
  return mod97(digits) === 1;
}

function mod97(string) {
  var checksum = string.slice(0, 2),
    fragment;
  for (var offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

export const handleSelectImage = (url, setFormData, name) => {
  if (Array.isArray(url)) {
    const imageArray = [];
    for (let i = 0; i < url.length; i++) {
      const urlParams = url[i].split(";");
      const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
      imageArray.push(imageObject);
    }
    if (imageArray.length === 0) {
      setFormData((prev) => ({ ...prev, [name]: null }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: imageArray }));
    }
  } else {
    const urlParams = url.split(";");
    const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
    setFormData((prev) => ({ ...prev, [name]: imageObject }));
  }
};

export const handleSelectImageReactHook = (url, type, setValue) => {
  if (Array.isArray(url)) {
    const imageArray = [];
    for (let i = 0; i < url.length; i++) {
      const urlParams = url[i].split(";");
      const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
      imageArray.push(imageObject);
    }
    if (imageArray.length === 0) {
      setValue(type, null);
    } else {
      setValue(type, imageArray);
    }
  } else {
    const urlParams = url.split(";");
    const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
    setValue(type, imageObject);
  }
};

export const sendPdfInBase64 = async (pdf) => {
  if (!pdf) return null;

  try {
    const [mimeInfo, base64Content] = pdf.split(",");
    if (!mimeInfo.includes("application/pdf")) {
      throw new Error("El archivo no es un PDF válido.");
    }
    const bodyToSend = {
      mime: "application/pdf",
      image: base64Content,
    };
    const response = await uploadImage(bodyToSend);
    if (!response.imageURL) {
      throw new Error("Error al procesar el PDF");
    }
    return response.imageURL;
  } catch (error) {
    console.error("Error al procesar el PDF:", error);
    return null;
  }
};
