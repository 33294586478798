import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from "../../../constants/variables";

const LanguageSelect = ({
  translatedFields,
  handleChangeCheckbox,
  defaultLanguage = DEFAULT_LANGUAGE,
}) => {
  const { t } = useTranslation();
  const steps = AVAILABLE_LANGUAGES;

  const cardLanguages = (idiom) => {
    return translatedFields?.some((info) => info.language === idiom) || false;
  };

  const handleChangeCheckBox = (e, value) => {
    const isChecked = e.target.checked;
    handleChangeCheckbox(isChecked, value);
  };

  return (
    <Accordion sx={{ boxShadow: "none", border: "1px solid rgba(0, 0, 0, 0.23)" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center">
          <Typography>{t("LANGUAGES")}</Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {steps
            .filter((step) => step.id !== defaultLanguage)
            .map((step, index) => (
              <Grid item xs={12} key={index} container alignItems="center">
                <Checkbox
                  className="checkbox-oniria"
                  checked={cardLanguages(step.id)}
                  onChange={(e) => handleChangeCheckBox(e, step.id)}
                />
                <Typography>{t(step.name)}</Typography>
              </Grid>
            ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default LanguageSelect;
