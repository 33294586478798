import { Visibility } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RQ_KEY } from "../../../constants/query";
import { formatDate, formatHours } from "../../../constants/utils";
import { EVENT_TABS } from "../../../constants/variables";
import useUserRoles from "../../../hooks/useUserRoles";
import { getEventSales, getEventSalesDomain } from "../../../services/eventsServices";
import EventCardButtons from "./EventCardButtons";
import useQueryChildEvents from "../../../hooks/queries/events/useQueryChildEvents";
import EventCardChilds from "./EventCardChilds";

const CardEvents = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { event, refetch } = props;
  const { isManager, isSuperAdmin, isScanner } = useUserRoles();

  const selectedEventsTab = useSelector((state) => state.b2bInfo.selectedTab);

  const [qrResponse, setQrResponse] = useState();
  const [seeChildEvents, setSeeChildEvents] = useState(false);

  const url = window.location.href;
  const path = url.split("/").slice(3).join("/");

  const soldTicketsPercent = (soldTickets, totalStock) => {
    let percentage = (soldTickets * 100) / totalStock;
    return parseFloat(percentage.toFixed(2));
  };

  const handleEventClick = (id) => {
    navigate(`/event/${id}`);
  };

  //Principal image or video
  const isVideo = event.principal_url?.endsWith(".mp4") || event.principal_url?.endsWith(".webm");

  const { data: eventSales, refetch: refetchSales } = useQuery(
    [RQ_KEY.EVENT_SALES, event.id],
    () => {
      if (selectedEventsTab === EVENT_TABS.MY_EVENTS) {
        return getEventSales(event.id);
      } else {
        return getEventSalesDomain(event.id);
      }
    },
    {
      enabled: !!event.id,
      refetchOnWindowFocus: false,
    }
  );

  // CHILDREN EVENTS
  const {
    data: childEvents,
    isLoading: loadingChildEvents,
    refetch: refetchChild,
  } = useQueryChildEvents(event.id, seeChildEvents);

  useEffect(() => {
    if (qrResponse) {
      refetchSales();
    }
  }, [qrResponse]);

  return (
    <Grid item xs={11} sm={12}>
      <Card
        sx={{
          border: event.is_active === 0 ? "1px solid #d5d3d3" : "1px solid #E4E4E4",
          borderRadius: "15px",
          position: "relative",
          backgroundColor: event.is_active === 0 ? "var(--grey-cancelled)" : "inherit",
        }}
      >
        {event.is_active === 0 && (
          <Typography
            style={{
              position: "absolute",
              top: "5px",
              right: "30px",
              color: "#ff0000",
              fontWeight: "600",
              marginBottom: "10px",
            }}
          >
            {t("CANCELED")}
          </Typography>
        )}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ p: event.is_active === 0 ? 4 : 3 }}>
            <Grid container columnSpacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={2.5}
                sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
              >
                {isVideo ? (
                  <CardMedia
                    component={"video"}
                    sx={{ height: 150, maxWidth: 200, objectFit: "fill" }}
                    src={event.principal_url}
                    alt="Video"
                    autoPlay
                    muted
                  />
                ) : (
                  <CardMedia
                    component={"img"}
                    sx={{ height: 150, maxWidth: 200, objectFit: "fill" }}
                    image={
                      event.principal_url === null ? "/media/events_null.svg" : event.principal_url
                    }
                    alt="Image"
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3.25}>
                <Grid container item xs={11.5} justifyContent="space-between">
                  <Typography
                    className="oniria-colorText"
                    variant="subtitle1"
                    sx={{ fontWeight: "bolder", cursor: "pointer" }}
                    onClick={() => handleEventClick(event.id)}
                  >
                    {event.name}
                  </Typography>
                  {event.is_visible === 1 && <Visibility sx={{ color: "var(--oniria-gold)" }} />}
                </Grid>
                <Typography variant="subtitle2" mb={2}>
                  {event.premise_name}, {event.premise_city}
                </Typography>
                <Grid container sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="subtitle2" mr={2}>
                    {event.start_date ? formatDate(event.start_date) : t("MULTI_DATES")}
                  </Typography>
                  <Typography variant="subtitle2">
                    {event.start_date && event.end_date ? (
                      <>
                        {formatHours(event.start_date)}-{formatHours(event.end_date)}
                      </>
                    ) : (
                      ""
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={11.5}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mt: 3,
                  }}
                >
                  <Typography variant="subtitle2">
                    {path === "events" ? t("TICKETS_SOLD") : t("PLACES_SOLD")}:{" "}
                    {eventSales?.sold_tickets}/{eventSales?.total_initial_stock}
                  </Typography>
                  <Typography variant="subtitle2">
                    {soldTicketsPercent(eventSales?.sold_tickets, eventSales?.total_initial_stock)}%
                  </Typography>
                </Grid>
                <Grid item xs={11.5}>
                  <LinearProgress
                    variant="determinate"
                    value={soldTicketsPercent(
                      eventSales?.sold_tickets,
                      eventSales?.total_initial_stock
                    )}
                    sx={{
                      width: "100%",
                      height: 8,
                      mb: 1,
                      backgroundColor: "var(--oniria-sky)",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "var(--secondary-color)",
                      },
                    }}
                  />
                </Grid>

                {event?.is_father_trip === 1 && (
                  <Grid item xs={11.5}>
                    <Button
                      size="small"
                      onClick={() => setSeeChildEvents((prev) => !prev)}
                      className="oniria-border-btn"
                    >
                      {seeChildEvents ? t("HIDE_CHILD_EVENTS") : t("SHOW_CHILD_EVENTS")}
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sm={12} lg={3.2} container>
                {!isScanner && (
                  <>
                    <Grid item xs={6} sm={3} lg={5}>
                      <Typography variant="subtitle2" mt={3.5} mb={1}>
                        {t("TOTAL_SALES")}
                      </Typography>
                      <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                        {eventSales?.amount ? eventSales?.amount + "€" : "0.00€"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} lg={5}>
                      <Typography variant="subtitle2" mt={3.5} mb={1}>
                        {t("REFUNDS")}
                      </Typography>
                      <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                        {eventSales?.total_refunded ? eventSales?.total_refunded + "€" : "0.00€"}
                      </Typography>
                    </Grid>
                  </>
                )}
                {(isSuperAdmin || isManager) && (
                  <Grid item xs={6} sm={3} lg={5}>
                    <Typography variant="subtitle2" mt={3.5} mb={1}>
                      {t("TOTAL_COMMISSIONS")}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                      {eventSales?.commission ? eventSales?.commission + "€" : "0.00€"}
                    </Typography>
                  </Grid>
                )}
                {!!event.premise_commission && (
                  <Grid item xs={6} sm={3} lg={5}>
                    <Typography variant="subtitle2" mt={3.5} mb={1}>
                      {t("PREMISE_COMMISSIONS")}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                      {eventSales?.premise_commission
                        ? eventSales?.premise_commission + "€"
                        : "0.00€"}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <EventCardButtons
                event={event}
                setQrResponse={setQrResponse}
                eventSales={eventSales}
                refetch={refetch}
              />
            </Grid>
            {seeChildEvents && (
              <EventCardChilds
                childEvents={childEvents}
                loadingChildEvents={loadingChildEvents}
                refetch={refetchChild}
              />
            )}
          </CardContent>
        </Box>
      </Card>
    </Grid>
  );
};

export default CardEvents;
