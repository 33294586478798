import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { focusColor } from "../../../shared/textFieldStyle";

const SearchFilter = ({ onSearchChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <SearchIcon sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }} />
      <TextField
        label={t("SEARCH")}
        onChange={onSearchChange}
        size="small"
        sx={[focusColor, { width: "75%" }]}
      />
    </>
  );
};

export default SearchFilter;
