import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InfoToolTip from "../../shared/InfoToolTip";
import { focusColor } from "../../shared/textFieldStyle";

const TripTicketDates = ({ index }) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const isDoublePayment = watch("has_double_payment");

  return (
    <Grid item xs={12} container columnSpacing={2}>
      <Grid item xs={12} container columnSpacing={2} mb={2}>
        <Grid item xs={12}>
          <InputLabel sx={{ fontSize: "18px", color: "black" }}>
            <div
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                display: "flex",
              }}
            >
              {t("START_DATE")}
              <InfoToolTip text={t("INFO_TICKET_START_DATE")} />
            </div>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name={`tickets.${index}.start_date`}
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                type="date"
                {...field}
                error={!!errors?.tickets?.[index]?.start_date}
                helperText={errors?.tickets?.[index]?.start_date?.message || ""}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={focusColor}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name={`tickets.${index}.start_date_time`}
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                type="time"
                {...field}
                error={!!errors?.tickets?.[index]?.start_date_time}
                helperText={errors?.tickets?.[index]?.start_date_time?.message || ""}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={focusColor}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container columnSpacing={2}>
        <Grid item xs={12}>
          <InputLabel sx={{ fontSize: "18px", color: "black" }}>
            <div
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                display: "flex",
              }}
            >
              {t("END_DATE")}
              <InfoToolTip text={t("INFO_TICKET_END_DATE")} />
            </div>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name={`tickets.${index}.end_date`}
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                type="date"
                {...field}
                error={!!errors?.tickets?.[index]?.end_date}
                helperText={errors?.tickets?.[index]?.end_date?.message || ""}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={focusColor}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name={`tickets.${index}.end_date_time`}
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                type="time"
                {...field}
                error={!!errors?.tickets?.[index]?.end_date_time}
                helperText={errors?.tickets?.[index]?.end_date_time?.message || ""}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={focusColor}
              />
            )}
          />
        </Grid>
      </Grid>
      {isDoublePayment && (
        <Grid item xs={12} container columnSpacing={2}>
          <Grid item xs={12}>
            <InputLabel sx={{ fontSize: "18px", color: "black" }}>
              <div
                style={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  display: "flex",
                }}
              >
                {t("SECOND_PAYMENT_END_DATE")}
              </div>
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("SECOND_PAYMENT_END_DATE")}</Typography>
            <Controller
              name={`tickets.${index}.second_payment_end_date`}
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  type="date"
                  {...field}
                  error={!!errors?.tickets?.[index]?.second_payment_end_date}
                  helperText={errors?.tickets?.[index]?.second_payment_end_date?.message || ""}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={focusColor}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name={`tickets.${index}.second_payment_end_date_time`}
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  type="time"
                  {...field}
                  error={!!errors?.tickets?.[index]?.second_payment_end_date_time}
                  helperText={errors?.tickets?.[index]?.second_payment_end_date_time?.message || ""}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={focusColor}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TripTicketDates;
