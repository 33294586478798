import { Add, Close } from "@mui/icons-material";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { convertStringToObject } from "../../../../constants/utils";
import { DEFAULT_LANGUAGE, EXTRA_TAGS } from "../../../../constants/variables";
import EmailsEditor from "../../../emailEditor/EmailEditor";
import { focusColor } from "../../textFieldStyle";

const IndividualRooms = ({ hotelId }) => {
  const { t } = useTranslation();
  const emailEditorRefs = useRef([]);
  const {
    watch,
    getValues,
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const extras = watch("extras") || [];

  const handleAddExtra = () => {
    const prevExtras = getValues("extras") || [];
    const newExtra = {
      name: "",
      price: 0,
      tags: EXTRA_TAGS.ROOM,
      description: "",
      description_design: "",
      initial_stock: 0,
      position: prevExtras.length,
      hotel_id: hotelId,
    };
    setValue("extras", [...prevExtras, newExtra]);
  };

  const handleRemoveExtra = (index) => {
    const prevExtras = getValues("extras") || [];
    const updatedExtras = prevExtras?.filter((_, i) => i !== index);
    setValue("extras", updatedExtras);
  };

  const handleChangeDescription = (data, name, index) => {
    const newExtras = getValues("extras");

    const updatedExtras = newExtras.map((extra, i) => {
      if (i === index) {
        return { ...extra, [name]: data };
      }
      return extra;
    });
    setValue("extras", updatedExtras);
  };

  return (
    <Grid container mt={2}>
      <Typography fontWeight="bold">
        {t("ROOMS_TYPES")} ({t("INDIVIDUALS")})
      </Typography>
      <Typography variant="caption">{t("INDIVIDUAL_ROOMS_DESCRIPTION")}</Typography>
      {extras
        ?.filter((extra) => extra.tags?.includes(EXTRA_TAGS.ROOM) && extra.hotel_id === hotelId)
        .map((extra, index) => {
          const position = extra?.position || index;
          return (
            <Grid
              container
              spacing={1}
              key={`${extra.id}-${index}`}
              sx={{
                border: "1px solid #d3d3d3",
                borderRadius: 3,
                padding: 2,
                marginBottom: 2,
                position: "relative",
                marginTop: 2,
              }}
            >
              <IconButton
                onClick={() => handleRemoveExtra(position)}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "var(--secondary-color)",
                }}
              >
                <Close />
              </IconButton>
              <Grid item xs={12} sm={6}>
                <Typography>{t("ROOM_TYPE")}</Typography>
                <TextField
                  {...register(`extras[${position}].name`)}
                  error={!!errors?.extras?.[position]?.name}
                  helperText={errors?.extras?.[position]?.name?.message}
                  fullWidth
                  sx={focusColor}
                  placeholder={t("NAME")}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>{t("ROOM_TYPE_STOCK")}</Typography>
                <TextField
                  placeholder={t("STOCK")}
                  {...register(`extras[${position}].initial_stock`)}
                  error={!!errors?.extras?.[position]?.initial_stock}
                  helperText={errors?.extras?.[position]?.initial_stock?.message}
                  fullWidth
                  sx={focusColor}
                  type="number"
                  inputProps={{ min: 0 }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {t("ADDITIONAL_COST")} {t("IF_INCLUDED_0")}
                </Typography>
                <TextField
                  placeholder={t("PRICE")}
                  {...register(`extras[${position}].price`)}
                  error={!!errors?.extras?.[position]?.price}
                  helperText={errors?.extras?.[position]?.price?.message}
                  fullWidth
                  sx={focusColor}
                  type="number"
                  inputProps={{ min: 0 }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("DESCRIPTION")}</Typography>
                <EmailsEditor
                  ref={(el) => el && (emailEditorRefs.current[0] = el)}
                  initialDesign={convertStringToObject(extra?.description_design)}
                  onExportHtml={(html) => handleChangeDescription(html, "description", position)}
                  onDesignChange={(design) =>
                    handleChangeDescription(design, "description_design", position)
                  }
                  key={`editor-extra-event-${DEFAULT_LANGUAGE}-${position}`}
                />
              </Grid>
            </Grid>
          );
        })}
      <Button
        className="oniria-btn"
        variant="contained"
        sx={{ fontSize: "12px", borderRadius: 3, mt: 2 }}
        onClick={handleAddExtra}
        startIcon={<Add />}
        size="small"
      >
        {t("ADD_ROOM")}
      </Button>
    </Grid>
  );
};

export default IndividualRooms;
