import { Box, Grid, InputLabel, Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ButtonsForms from "../../account/forms/ButtonsForms";
import { allFieldsOk } from "../../shared/FormsValidator";
import MultiSelect from "../../shared/MultiSelect";
import Blocks from "../trip/blocks/Blocks";
import Itineraries from "../trip/itineraries/Itineraries";
import AdditionalInfo from "./AdditionalInfo";
import NotIncludedOptions from "./NotIncludedOptions";

const MoreInformation = ({
  formController,
  buttonClicked,
  setButtonClicked,
  editEvent,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const {
    formData,
    setFormErrors,
    setFormParamsValue,
    validation,
    setFormData,
    setEventLanguageInfoError,
  } = formController;
  const { moreInformation: trip, details } = formData;
  const { moreInformation: moreInformationErrors } = validation;

  //To set next button form clicked
  const catchButtonClicked = (value) => {
    setButtonClicked(value);
  };

  return (
    <Grid
      container
      sx={{
        mt: 5,
        mb: 20,
        px: 1,
        color: "black",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid item xs={11} md={8}>
        <Box
          component={Paper}
          elevation={3}
          sx={{
            py: 2,
            px: 3,
            border: "1px solid #E4E4E4",
            borderRadius: "15px",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 2 }}>
            <Grid
              container
              sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
            >
              <Grid item xs={11} sx={{ mt: 2, mb: 2 }}>
                <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                  {t("INCLUDING")}
                </InputLabel>
                <MultiSelect formController={formController} />
              </Grid>
              <NotIncludedOptions
                notIncludedOptions={trip.not_included_options}
                setFormParamsValue={setFormParamsValue}
                event_language_infos={details.event_language_infos}
                setFormData={setFormData}
                setEventLanguageInfoError={setEventLanguageInfoError}
                defaultLanguage={details.default_language}
              />
              <AdditionalInfo
                additionalInfo={trip.additional_info}
                setFormParamsValue={setFormParamsValue}
                event_language_infos={details.event_language_infos}
                setFormData={setFormData}
                setEventLanguageInfoError={setEventLanguageInfoError}
                defaultLanguage={details.default_language}
              />
              <Itineraries
                formController={formController}
                buttonClicked={buttonClicked}
                event_language_infos={details.event_language_infos}
                defaultLanguage={details.default_language}
              />
              <Blocks
                setFormErrors={setFormErrors}
                trip={trip}
                moreInformationErrors={moreInformationErrors}
                setFormParamsValue={setFormParamsValue}
                formData={formData}
                formController={formController}
                buttonClicked={buttonClicked}
                editEvent={editEvent}
                event_language_infos={details.event_language_infos}
                defaultLanguage={details.default_language}
              />
            </Grid>
          </Box>
          {!isDisabled ? (
            <ButtonsForms
              formController={formController}
              allFieldsOk={allFieldsOk}
              errors={moreInformationErrors}
              sendButtonClicked={catchButtonClicked}
            />
          ) : null}
        </Box>
      </Grid>
    </Grid>
  );
};

export default MoreInformation;
