import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHttpBearerToken } from "../constants/axios";
import { logout } from "../store/reducers/userInfoReducer";
import SwitchLanguage from "./header/SwitchLanguage";
import { useTranslation } from "react-i18next";

const UserAvatar = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.userInfo);

  const { userData, roles } = userInfo;
  const rol = roles[0] ?? "";

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logOut = () => {
    localStorage.clear();
    sessionStorage.clear();

    setHttpBearerToken("");
    dispatch(logout());
    navigate("/login");
  };

  return (
    <>
      <Grid item xs={6} sm={11} sx={{ float: "right" }}>
        <List sx={{ width: "auto" }}>
          <ListItem title="Open settings">
            <ListItemAvatar>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Avatar" src={userData?.avatar_url} />
              </IconButton>
            </ListItemAvatar>
            <ListItemText primary={userData?.name + " " + userData?.surname} secondary={rol} />
          </ListItem>
        </List>
        <Popover
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <List
            component="nav"
            onClick={handleCloseUserMenu}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {/* <ListItem mb={1} sx={{ cursor: "pointer", textAlign: "center" }}>Perfil</ListItem> */}
            <ListItem
              sx={{
                cursor: "pointer",
                textAlign: "center",
                "&:hover": { backgroundColor: "lightgray" },
              }}
            >
              <SwitchLanguage />
            </ListItem>
            <ListItem
              sx={{
                cursor: "pointer",
                textAlign: "center",
                "&:hover": { backgroundColor: "lightgray" },
              }}
              onClick={logOut}
            >
              {t("LOGOUT")}
            </ListItem>
          </List>
        </Popover>
      </Grid>
    </>
  );
};

export default UserAvatar;
