import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MUI_SELECT_PRIM_COLOR } from "../../../constants/styles";
import BasicInfoSteps from "./BasicInfoSteps";
import ConfigurationSteps from "./ConfigurationSteps";

const TripComponents = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const tabs = [t("BASE_INFO"), t("CONFIGURATION")];

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const [tripComponents, setTripComponents] = useState({
    hasHosting: null,
    hasTransports: null,
    hasTourLeaders: null,
  });

  const handleChangeTripComponents = (type, e) => {
    const isChecked = e.target.value === "true";
    setTripComponents((prev) => ({ ...prev, [type]: isChecked }));
  };

  const itineraries = watch("itineraries");
  const tourLeaders = watch("tour_leader_ids") || [];

  useEffect(() => {
    if (!itineraries) return;
    setTripComponents({
      hasHosting: itineraries.some((itinerary) => itinerary?.hotel_ids?.length > 0),
      hasTransports: itineraries.some((itinerary) => itinerary?.transport_ids?.length > 0),
      hasTourLeaders: tourLeaders.length > 0,
    });
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">{t("TRIP_COMPONENTS")}</Typography>
        <Typography variant="body1">{t("TRIP_COMPONENTS_DESCRIPTION")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: { backgroundColor: "var(--secondary-color)" },
          }}
        >
          {tabs.map((tabLabel) => (
            <Tab
              key={tabLabel}
              label={tabLabel}
              sx={{ ...MUI_SELECT_PRIM_COLOR, paddingBottom: 0 }}
            />
          ))}
        </Tabs>
      </Grid>

      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {activeTab === 0 && (
          <BasicInfoSteps
            tripComponents={tripComponents}
            handleChangeTripComponents={handleChangeTripComponents}
            handleTabChange={handleTabChange}
          />
        )}
        {activeTab === 1 && <ConfigurationSteps tripComponents={tripComponents} />}
      </Box>
    </>
  );
};

export default TripComponents;
