import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { AVAILABLE_LANGUAGES } from "../../constants/variables";
import { FormField } from "./FormField";
import { focusColor } from "./textFieldStyle";

export const TranslationFields = ({
  setFormData,
  translations,
  onChange,
  errors,
  defaultLanguage,
}) => {
  const { t } = useTranslation();
  const steps = AVAILABLE_LANGUAGES.filter((lang) => lang.id !== defaultLanguage);

  const checkLanguage = (idiom) => {
    return translations?.some((info) => info.language === idiom) || false;
  };

  const handleChangeCheckBox = (e, value) => {
    const isChecked = e.target.checked;
    const newLanguage = {
      language: value,
      title: "",
      description: "",
      html: "",
      design: "",
    };
    if (isChecked) {
      setFormData((prev) => ({
        ...prev,
        translations: [...prev?.translations, newLanguage],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        translations: prev?.translations?.filter((language) => language?.language !== value),
      }));
    }
  };

  return (
    <FormControl fullWidth sx={focusColor}>
      <Accordion
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid var(--grey-cancelled)",
          boxShadow: "none",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>{t("BLOG_TRANSLATION")}</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {steps.map((step, index) => (
              <Grid item xs={12} key={index} container alignItems="center">
                <Checkbox
                  className="checkbox-oniria"
                  checked={checkLanguage(step.id)}
                  onChange={(e) => handleChangeCheckBox(e, step.id)}
                />
                <Typography>{t(step.name)}</Typography>
              </Grid>
            ))}
          </Grid>
          {translations && translations.length > 0
            ? translations?.map((translation, index) => (
                <Grid key={index} sx={{ mt: 2 }} container columnSpacing={2} rowSpacing={2}>
                  <Typography sx={{ width: "100%", textTransform: "lowercase", pl: 2 }}>
                    {translation.language} - {t("TRANSLATION")}
                  </Typography>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label={t("TITLE")}
                      value={translation.title}
                      onChange={(e) => onChange(index, "title", e.target.value)}
                      error={errors[`translations.${index}.title`]}
                      helperText={errors[`translations.${index}.title`]}
                      sx={focusColor}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      label={t("DESCRIPTION")}
                      value={translation.description}
                      onChange={(e) => onChange(index, "description", e.target.value)}
                      error={errors[`translations.${index}.description`]}
                      helperText={errors[`translations.${index}.description`]}
                      sx={focusColor}
                      required
                    />
                  </Grid>
                </Grid>
              ))
            : null}
        </AccordionDetails>
      </Accordion>
    </FormControl>
  );
};
