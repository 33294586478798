import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { VERTICAL_STEEP_STYLE } from "../../../constants/styles";
import AddComponentsIcons from "./addComponents/AddComponentsIcons";
import AddTripComponents from "./addComponents/AddTripComponents";
import VerticalStepButtons from "../../shared/VerticalStepButtons";
import { useState } from "react";

const BasicInfoSteps = ({ tripComponents, handleChangeTripComponents, handleTabChange }) => {
  const { t } = useTranslation();
  const steps = [t("HOSTING"), t("TRANSPORTS"), t("TOUR_LEADERS")];

  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step) => {
    if (step === steps.length) {
      handleTabChange(null, 1);
    } else {
      setActiveStep(step);
    }
  };

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, stepIndex) => (
        <Step key={step} sx={VERTICAL_STEEP_STYLE}>
          <StepLabel
            onClick={() => handleStepChange(stepIndex)}
            StepIconComponent={AddComponentsIcons}
            StepIconProps={{ stepIndex, tripComponents }}
          >
            {step}
          </StepLabel>
          <AddTripComponents
            stepIndex={stepIndex}
            activeStep={activeStep}
            handleChangeTripComponents={handleChangeTripComponents}
            tripComponents={tripComponents}
          />
          <StepContent>
            <VerticalStepButtons
              activeStep={activeStep}
              handleStepChange={handleStepChange}
              stepsLength={steps.length + 1}
            />
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default BasicInfoSteps;
