import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryAssignDomains } from "../../../hooks/queries/useQueryAssignDomains";

const SELECT_OPTIONS = {
  ALL: "all",
  SELECT: "select",
};

const TripTicketSales = ({ index }) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(SELECT_OPTIONS.ALL);
  const { setValue, control, watch } = useFormContext();
  const { data: domainCategories } = useQueryAssignDomains();

  const eventCategories = watch("event_categories") || [];
  const ticketDomains = watch(`tickets[${index}].domain_names`) || [];

  const { field } = useController({
    name: `tickets[${index}].domain_names`,
    control,
    defaultValue: ticketDomains,
  });

  const isDomainChecked = (domain) => {
    return domain.categories.some(
      (category) =>
        eventCategories?.some((selectedCategory) => selectedCategory.category_id === category.id) ||
        category?.subcategories.some((subcategory) =>
          eventCategories?.some(
            (selectedCategory) => selectedCategory.subcategory_id === subcategory.id
          )
        )
    );
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (value === SELECT_OPTIONS.ALL) {
      setValue(`tickets[${index}].domain_names`, []);
    }
  };

  const handleDomainChange = (domain, isChecked) => {
    const updatedDomains = isChecked
      ? [...field.value, domain.name]
      : field.value.filter((domainName) => domainName !== domain.name);
    setValue(`tickets[${index}].domain_names`, updatedDomains);
  };

  return (
    <Grid item xs={12} mt={1} container>
      <Grid item container>
        <FormControl>
          <Typography>{t("SALES_ON")}:</Typography>
          <RadioGroup value={selectedValue} onChange={handleRadioChange} name="radio-buttons-group">
            <FormControlLabel
              value={SELECT_OPTIONS.ALL}
              control={<Radio className="radio-oniria" />}
              label={t("OPTION_ALL_DOMAINS")}
            />
            <FormControlLabel
              value={SELECT_OPTIONS.SELECT}
              control={<Radio className="radio-oniria" />}
              label={t("CHOSE_DOMAINS")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {selectedValue === SELECT_OPTIONS.SELECT && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>{t("DOMAINS")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {domainCategories
                ?.filter((domain) => isDomainChecked(domain))
                ?.map((domain) => (
                  <Fragment key={domain.id}>
                    <Grid>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={ticketDomains?.includes(domain.name) || false}
                            onChange={(e) => handleDomainChange(domain, e.target.checked)}
                            className="checkbox-oniria"
                          />
                        }
                        label={<Typography>{domain.name}</Typography>}
                      />
                    </Grid>
                  </Fragment>
                ))}
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
    </Grid>
  );
};

export default TripTicketSales;
