import { Add } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import DomainSelector from "../../components/categories/DomainSelector";
import Header from "../../components/Header";
import { CategoryItem } from "../../components/premises/categories/CategoryItem";
import CustomDialog from "../../components/shared/CustomDialog";
import { Loading } from "../../components/shared/Loading";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import AddCategoryDialog from "../../components/whiteLabel/appStyles/newCategoryDialog/AddDialog";
import { reorder } from "../../constants/categories";
import { ROUTES } from "../../constants/routes";
import { useQueryCategoriesDomain } from "../../hooks/queries/useQueryCategoriesDomain";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { updateCategoriesDomain } from "../../services/categoriesServices";

const CategoriesPage = () => {
  const { t } = useTranslation();
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [selectCategoryId, setSelectCategoryId] = useState("");

  const { data: domains = [], isLoading: loadingDomains } = useQueryDomains();
  const {
    data: categories = [],
    refetch: refetchCategories,
    isLoading: loadingCategories,
  } = useQueryCategoriesDomain(selectedDomain);

  const breadcrumbs = [
    {
      name: t("CATEGORIES_MANAGER"),
      link: ROUTES.CATEGORIES,
    },
    {
      name: domains.find((d) => d.domain_id === selectedDomain)?.domain_name || "",
    },
  ];

  useEffect(() => {
    if (domains.length > 0) {
      setSelectedDomain(domains[0].domain_id);
    }
  }, [domains]);

  const handleDomainChange = useCallback((e) => {
    setSelectedDomain(e.target.value);
  }, []);

  //drag and drop
  const handleSetCategories = async ({ destination, source }) => {
    if (!destination) return;
    const newItems = reorder(categories, source.index, destination.index);
    try {
      await updateCategoriesDomain(selectedDomain, { updated_categories: newItems });
    } catch (e) {
      console.error("Error handleSetCategories", e);
      toastMessageError(t("ERROR_UPDATE_CATEGORIES"));
    } finally {
      refetchCategories();
    }
  };

  const handleSetSubcategories = async ({ destination, source }) => {
    const newCategories = categories.map((item) => {
      if (item.id !== destination.droppableId) return item;
      const newSubcategories = reorder(item.subcategories, source.index, destination.index);
      return { ...item, subcategories: newSubcategories };
    });
    try {
      await updateCategoriesDomain(selectedDomain, { updated_categories: newCategories });
    } catch (e) {
      console.error("Error handleSetSubcategories", e);
      toastMessageError(t("ERROR_UPDATE_SUBCATEGORIES"));
    } finally {
      refetchCategories();
    }
  };

  // new category
  const handleAddCategory = async (newCategory) => {
    const categoryToAdd = {
      name: newCategory.name,
      description: newCategory.description,
      slug: newCategory.slug,
      position: categories.length,
      domain_id: selectedDomain,
      is_visible: true,
      default_language: "ES",
    };
    const newCategories = [...categories, categoryToAdd];
    try {
      await updateCategoriesDomain(selectedDomain, { updated_categories: newCategories });
      toastMessageSuccess(t("CATEGORY_ADDED_SUCCESSFULLY"));
    } catch (e) {
      console.error("Error handleAddCategory", e);
      toastMessageError(t("ERROR_ADD_CATEGORY"));
    } finally {
      refetchCategories();
    }
  };

  // remove category
  const handleRemoveCategory = async () => {
    setOpenRemove(false);
    const newCategories = categories?.filter((item) => item.id !== selectCategoryId);
    const updatedCategories = newCategories.map((category, index) => ({
      ...category,
      position: index,
    }));
    try {
      await updateCategoriesDomain(selectedDomain, { updated_categories: updatedCategories });
    } catch (e) {
      console.error("Error handleRemoveCategory", e);
    } finally {
      refetchCategories();
    }
  };

  const handleOpenRemove = (categoryId) => {
    setSelectCategoryId(categoryId);
    setOpenRemove(true);
  };

  const handleEditCategory = async (id, newCategory, is_visible) => {
    const newItems = categories.map((category, index) => {
      if (category.id === id) {
        const categoryLanguages = newCategory?.category_language_infos?.map((info) => ({
          ...info,
          category_id: id,
        }));
        category = {
          ...newCategory,
          is_visible: is_visible || true,
          position: index,
          category_language_infos: categoryLanguages,
          domain_id: selectedDomain,
          default_language: "ES",
        };
      }
      return category;
    });
    try {
      await updateCategoriesDomain(selectedDomain, { updated_categories: newItems });
      toastMessageSuccess(t("CATEGORY_UPDATED_SUCCESSFULLY"));
    } catch (e) {
      console.error("Error handleSetCategories", e);
      toastMessageError(t("ERROR_UPDATE_CATEGORIES"));
    } finally {
      refetchCategories();
    }
  };

  //SUBCATEGORIES
  const handleAddSubcategory = async (categoryId, newCategory, image) => {
    const newCategories = categories.map((item) => {
      if (item.id !== categoryId) return item;
      if (item.subcategories) {
        item.subcategories.push({
          name: newCategory.name,
          position: item.subcategories.length,
          slug: newCategory.slug,
          description: newCategory.description,
          image_url: image,
          is_visible: true,
          subcategory_language_infos: newCategory.category_language_infos,
          keywords: newCategory.keywords,
          meta_title: newCategory.meta_title,
          meta_description: newCategory.meta_description,
          default_language: "ES",
        });
      } else {
        item.subcategories = [
          {
            name: newCategory.name,
            position: 0,
            description: newCategory.description,
            image_url: image,
            is_visible: true,
            slug: newCategory.slug,
            subcategory_language_infos: newCategory.category_language_infos,
            keywords: newCategory.keywords,
            meta_title: newCategory.meta_title,
            meta_description: newCategory.meta_description,
            default_language: "ES",
          },
        ];
      }
      return item;
    });
    try {
      await updateCategoriesDomain(selectedDomain, { updated_categories: newCategories });
      toastMessageSuccess(t("SUBCATEGORY_ADDED_SUCCESSFULLY"));
    } catch (e) {
      console.error("Error handleAddSubcategory", e);
      toastMessageError(t("ERROR_ADD_SUBCATEGORY"));
    } finally {
      refetchCategories();
    }
  };

  const handleDeleteSubcategory = async (categoryId, index) => {
    const newCategories = categories.map((item) => {
      if (item.id !== categoryId) return item;
      item.subcategories.splice(index, 1);
      return item;
    });
    try {
      await updateCategoriesDomain(selectedDomain, { updated_categories: newCategories });
    } catch (e) {
      console.error("Error handleDeleteSubcategory", e);
      toastMessageError(t("ERROR_DELETE_SUBCATEGORY"));
    } finally {
      refetchCategories();
    }
  };

  const handleEditSubcategory = async (
    categoryId,
    index,
    newCategory,
    newImage,
    is_visible = true
  ) => {
    const newCategories = categories.map((item) => {
      if (item.id !== categoryId) return item;
      const subcategoryLanguages =
        (newCategory?.category_language_infos || newCategory?.subcategory_language_infos)?.map(
          (info) => ({
            ...info,
            subcategory_id: categoryId,
          })
        ) || [];
      item.subcategories[index].name = newCategory.name;
      item.subcategories[index].description = newCategory.description;
      item.subcategories[index].slug = newCategory.slug;
      item.subcategories[index].image_url = newImage;
      item.subcategories[index].is_visible = is_visible;
      item.subcategories[index].position = index;
      item.subcategories[index].keywords = newCategory.keywords;
      item.subcategories[index].meta_title = newCategory.meta_title;
      item.subcategories[index].meta_description = newCategory.meta_description;
      item.subcategories[index].subcategory_language_infos = subcategoryLanguages;
      item.subcategories[index].default_language = "ES";
      return item;
    });
    try {
      await updateCategoriesDomain(selectedDomain, { updated_categories: newCategories });
    } catch (e) {
      console.error("Error handleEditSubcategory", e);
      toastMessageError(t("ERROR_EDIT_SUBCATEGORY"));
    } finally {
      refetchCategories();
    }
  };
  //END SUBCATEGORIES

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("CATEGORIES_DOMAIN_DESCRIPTION")} />
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <DomainSelector
            domains={domains}
            selectedDomain={selectedDomain}
            onSelectDomain={handleDomainChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" mt={2}>
            {t("CATEGORIES")}
          </Typography>
        </Grid>
        {loadingCategories || loadingDomains ? (
          <Loading />
        ) : (
          <DragDropContext onDragEnd={handleSetCategories}>
            <Droppable droppableId="droppable-list">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ display: "flex", flexDirection: "column", gap: 4, width: "100%" }}
                >
                  {categories
                    .sort((a, b) => a.position - b.position)
                    .map((item, index) => (
                      <CategoryItem
                        key={item.id || index}
                        item={item}
                        index={index}
                        handleOpenRemove={handleOpenRemove}
                        handleSetSubcategories={handleSetSubcategories}
                        handleEditCategory={handleEditCategory}
                        handleAddSubcategory={handleAddSubcategory}
                        handleDeleteSubcategory={handleDeleteSubcategory}
                        handleEditSubcategory={handleEditSubcategory}
                      />
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <Grid container justifyContent="center">
          <Button
            onClick={() => setOpenAddCategory(true)}
            startIcon={<Add />}
            sx={{
              marginTop: 2,
              border: "1.5px solid var(--oniria-gold)",
              color: "gray",
              textTransform: "capitalize",
              "&:hover": {
                color: "#fff",
                backgroundColor: "var(--oniria-gold)",
              },
            }}
          >
            {t("ADD_CATEGORY")}
          </Button>
        </Grid>
      </Grid>
      <AddCategoryDialog
        isOpen={openAddCategory}
        onClose={() => setOpenAddCategory(false)}
        handleAddCategory={handleAddCategory}
      />
      <CustomDialog
        isOpen={openRemove}
        onClose={() => setOpenRemove(false)}
        title={t("CONFIRM_REMOVE_CATEGORY")}
        onAccept={handleRemoveCategory}
      />
    </>
  );
};

export default CategoriesPage;
