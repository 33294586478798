import {
  Autocomplete,
  Avatar,
  Checkbox,
  Chip,
  Grid,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LanguageTabs from "../../shared/languages/LanguageTabs";
import { optionsValue } from "../../shared/OptionsMultiSelect";
import { focusColor } from "../../shared/textFieldStyle";
import TextEditor from "../../textEditor/TextEditor";
import TripBlocks from "./TripBlocks";

const TripMoreInfo = () => {
  const { t } = useTranslation();

  const { watch, setValue, control } = useFormContext();

  const includedOptions = watch("included_options") || [];
  const eventLanguages = watch("event_language_infos") || [];
  const defaultLanguage = watch("default_language");

  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const languageIndex = eventLanguages?.findIndex((info) => info.language === selectedLanguage);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">{t("MORE_INFORMATION")}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography mb={1}>{t("INCLUDING")}</Typography>
        <Controller
          name={"included_options"}
          control={control}
          render={({ field }) => (
            <Autocomplete
              multiple
              options={optionsValue}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              value={includedOptions}
              renderGroup={(option, { selected }) => (
                <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                  <Avatar alt={option.name} src={option.img} />
                  <ListItemText primary={option?.value} />
                  <Checkbox checked={selected} />
                </Grid>
              )}
              renderTags={(value, getTagProps) =>
                value?.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      key={key}
                      avatar={<Avatar alt={option.name} src={option.img} />}
                      label={option.name}
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t("INCLUDING_OPTIONS")}
                  fullWidth
                  placeholder={t("WRITE_INCLUDING")}
                  sx={focusColor}
                />
              )}
            />
          )}
        />
      </Grid>
      <LanguageTabs
        languagesInfo={eventLanguages}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        defaultLanguage={defaultLanguage}
      />
      <Grid item xs={12}>
        <Typography>{t("NOT_INCLUDED_OPTIONS")}</Typography>
        {selectedLanguage === defaultLanguage ? (
          <>
            <TextEditor
              data={watch("not_included_options")}
              setData={(data) => setValue("not_included_options", data)}
              id="trip_not_included"
            />
          </>
        ) : (
          <TextEditor
            data={watch(`event_language_infos.${languageIndex}.not_included_options`)}
            id={`trip_not_included-${languageIndex}`}
            setData={(data) =>
              setValue(`event_language_infos.${languageIndex}.not_included_options`, data)
            }
          />
        )}
      </Grid>
      {/* <Grid item xs={12}>
        <Typography>{t("ADDITIONAL_INFO")}</Typography>
        <TextEditor
          data={watch("additional_info")}
          setData={(data) => setValue("additional_info", data)}
          id="trip_additional_info"
        />
      </Grid> */}
      <TripBlocks />
    </>
  );
};

export default TripMoreInfo;
