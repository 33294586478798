import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { deleteEvent } from "../../../services/eventsServices";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";
import { useTranslation } from "react-i18next";

const DeleteEventDialog = ({ openDelete, handleCloseDelete, eventId, refetch }) => {
  const { t } = useTranslation();

  const url = window.location.href;
  const path = url.split("/").slice(3).join("/");

  const handleDeleteButtonClick = async (e) => {
    e.stopPropagation();
    try {
      await deleteEvent(eventId);
      toastMessageSuccess(t("EVENT_DELETED"));
    } catch (error) {
      toastMessageError(error.response.data.message);
    } finally {
      handleCloseDelete();
      refetch();
    }
  };

  return (
    <Dialog
      open={openDelete}
      onClose={handleCloseDelete}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="oniria-colorText" sx={{ fontWeight: "bolder" }}>
        {path === "events"
          ? t("CANCEL_EVENT")
          : path === "activities"
          ? t("CANCEL_ACTIVITY")
          : t("CANCEL_TRIP")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: "black" }}>
          {path === "events"
            ? t("CONFIRM_CANCEL_EVENT")
            : path === "activities"
            ? t("CONFIRM_CANCEL_ACTIVITY")
            : t("CONFIRM_CANCEL_TRIP")}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Button
          type="submit"
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={handleCloseDelete}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={handleDeleteButtonClick}
        >
          {t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteEventDialog;
