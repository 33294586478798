import { Close } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  DialogActions,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EXTRA_TAGS } from "../../../../../constants/variables";
import InfoToolTip from "../../../../shared/InfoToolTip";
import { focusColor } from "../../../../shared/textFieldStyle";

function GlobalPensionsDialog({ isOpen, onClose }) {
  const { t } = useTranslation();

  const { watch, getValues, setValue } = useFormContext();

  const extras = watch("extras") || [];

  const breakfastExtra = extras.find((extra) => extra.tags?.includes(EXTRA_TAGS.BREAKFAST));
  const halfPensionExtra = extras.find((extra) => extra.tags?.includes(EXTRA_TAGS.HALF_PENSION));
  const fullPensionExtra = extras.find((extra) => extra.tags?.includes(EXTRA_TAGS.COMPLETE));

  const handleAddExtra = (type, isChecked) => {
    const prevExtras = getValues("extras") || [];
    const newExtra = {
      name: type,
      price: 0,
      tags: type,
      initial_stock: 0,
    };

    let updatedExtras;
    if (isChecked) {
      updatedExtras = [...prevExtras, newExtra];
    } else {
      updatedExtras = prevExtras.filter((extra) => !extra.tags.includes(type));
    }

    setValue("extras", updatedExtras);
  };

  const handleChangePrice = (type, price) => {
    const prevExtras = getValues("extras") || [];
    const updatedExtras = prevExtras.map((extra) => {
      if (extra.tags === type) {
        return { ...extra, price };
      }
      return extra;
    });

    setValue("extras", updatedExtras);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)", textAlign: "center" }}>
        {t("GLOBAL_PENSIONS")}
        <InfoToolTip text={t("GLOBAL_PENSIONS_INFO")} />
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "var(--secondary-color)",
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container gap={2}>
          <Grid item xs={12}>
            <Typography>
              {t("BREAKFAST")} {t("IF_INCLUDED_0")}
            </Typography>
            <Grid container alignItems="center" gap={1}>
              <Checkbox
                checked={!!breakfastExtra}
                onChange={(e) => handleAddExtra(EXTRA_TAGS.BREAKFAST, e.target.checked)}
                className="checkbox-oniria"
              />
              <TextField
                value={breakfastExtra?.price}
                type="number"
                inputProps={{ min: 0 }}
                placeholder={t("ADDITIONAL_COST")}
                sx={[focusColor, { flexGrow: 1 }]}
                disabled={!breakfastExtra}
                onChange={(e) => handleChangePrice(EXTRA_TAGS.BREAKFAST, e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {t("HALF_PENSION")} {t("IF_INCLUDED_0")}
            </Typography>
            <Grid container alignItems="center" gap={1}>
              <Checkbox
                checked={!!halfPensionExtra}
                onChange={(e) => handleAddExtra(EXTRA_TAGS.HALF_PENSION, e.target.checked)}
                className="checkbox-oniria"
              />
              <TextField
                value={halfPensionExtra?.price}
                type="number"
                inputProps={{ min: 0 }}
                placeholder={t("ADDITIONAL_COST")}
                sx={[focusColor, { flexGrow: 1 }]}
                onChange={(e) => handleChangePrice(EXTRA_TAGS.HALF_PENSION, e.target.value)}
                disabled={!halfPensionExtra}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {t("FULL_PENSION")} {t("IF_INCLUDED_0")}
            </Typography>
            <Grid container alignItems="center" gap={1}>
              <Checkbox
                checked={!!fullPensionExtra}
                onChange={(e) => handleAddExtra(EXTRA_TAGS.COMPLETE, e.target.checked)}
                className="checkbox-oniria"
              />
              <TextField
                value={fullPensionExtra?.price}
                type="number"
                inputProps={{ min: 0 }}
                placeholder={t("ADDITIONAL_COST")}
                sx={[focusColor, { flexGrow: 1 }]}
                onChange={(e) => handleChangePrice(EXTRA_TAGS.COMPLETE, e.target.value)}
                disabled={!fullPensionExtra}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center">
          <Button
            className="oniria-btn"
            variant="contained"
            sx={{ fontSize: "12px", borderRadius: 3 }}
            onClick={onClose}
            size="small"
          >
            {t("SAVE")}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default GlobalPensionsDialog;
