import { Add, Delete, DragIndicator, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { cloneElement, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { GREY_BUTTON_STYLE, VERTICAL_STEEP_STYLE } from "../../../constants/styles";
import useQueryExtraDetail from "../../../hooks/queries/extras/useQueryExtraDetail";
import { DEFAULT_EXTRA } from "../../../schemas/trips";
import { deleteExtra } from "../../../services/eventsServices";
import ExtraTemplateDialog from "../../events/extras/ExtraTemplateDialog";
import VerticalStepButtons from "../../shared/VerticalStepButtons";
import TripExtraBasicInfo from "./TripExtraBasicInfo";
import TripExtraConfig from "./TripExtraConfig";
import TripExtraWhereToBuy from "./TripExtraWhereToBuy";

const TripExtras = ({ extraTemplateId }) => {
  const { t } = useTranslation();
  const [activeSteps, setActiveSteps] = useState({});
  const [openAddExtra, setOpenAddExtra] = useState(false);

  const steps = [
    { label: t("BASIC_EXTRA_INFORMATION"), content: <TripExtraBasicInfo /> },
    { label: t("CONFIGURATION"), content: <TripExtraConfig /> },
    { label: t("WHERE_TO_BUY"), content: <TripExtraWhereToBuy /> },
  ];

  const { watch, setValue, getValues } = useFormContext();

  const extras = watch("extras") || [];
  const eventCategories = watch("event_categories") || [];

  const handleSetExtrasPositions = ({ destination, source }) => {
    if (!destination) return;
    const newExtras = getValues("extras") || [];
    const [removed] = newExtras.splice(source.index, 1);
    newExtras.splice(destination.index, 0, removed);

    const updatedExtras = newExtras.map((extra, index) => ({
      ...extra,
      position: index,
    }));

    setValue("extras", updatedExtras);

    setActiveSteps((prev) => ({
      ...prev,
      [destination.index]: 0,
    }));
  };

  const removeExtra = async (e, i) => {
    e.stopPropagation();
    try {
      const index = i !== -1 ? i : 0;
      if (extras[index]?.id) {
        await deleteExtra(extras[index].id);
      }
      const updatedExtras = extras?.filter((_, i) => i !== index);
      setValue("extras", updatedExtras);
    } catch (error) {
      console.error("Error deleting extra:", error);
      throw error;
    }
  };

  const handleAddExtra = () => {
    const prev = getValues("extras") || [];
    const defaultLanguage = getValues("default_language");
    const newExtra = {
      ...DEFAULT_EXTRA,
      default_language: defaultLanguage,
      position: prev.length ?? 0,
    };
    setValue("extras", [...prev, newExtra]);

    setActiveSteps((prev) => ({
      ...prev,
      [prev.length - 1]: 0,
    }));
  };

  const handleAddExtraTemplate = (extraTemplate) => {
    const prev = getValues("extras") || [];
    const newExtra = {
      name: extraTemplate.name || "",
      description: extraTemplate.description || "",
      description_design: extraTemplate.description_design || "",
      extra_language_infos: extraTemplate.extra_template_language_infos || [],
      extra_template_id: extraTemplate.id,
      short_description: extraTemplate.short_description || "",
      images: extraTemplate.images || "",
      position: prev.length || 0,
      price: 0,
    };

    setValue("extras", [...prev, newExtra]);

    setActiveSteps((prev) => ({
      ...prev,
      [prev.length - 1]: 0,
    }));
  };

  const handleStepChange = (extraIndex, step) => {
    setActiveSteps((prev) => ({
      ...prev,
      [extraIndex]: step,
    }));
  };

  // FOR EXTRA TEMPLATE from url
  const { data: extraTemplate } = useQueryExtraDetail(extraTemplateId);
  const checkExtraTemplate = () => {
    if (extras.some((extra) => extra.extra_template_id === extraTemplateId)) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (!!extraTemplateId && extraTemplate && checkExtraTemplate()) {
      handleAddExtraTemplate(extraTemplate);
    }
  }, [extraTemplateId, extraTemplate]);

  // To set the position and prevent errors
  useEffect(() => {
    const initialExtras = getValues("extras") || [];
    const updatedExtras = initialExtras.map((extra, index) => ({
      ...extra,
      position: index,
    }));
    setValue("extras", updatedExtras);
  }, [getValues, setValue]);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">{t("EXTRAS")}</Typography>
      </Grid>

      <Grid item xs={12}>
        <DragDropContext onDragEnd={handleSetExtrasPositions}>
          <Droppable droppableId="droppable-list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {extras
                  .filter((extra) => !extra.tags)
                  .sort((a, b) => a.position - b.position)
                  .map((extra, filteredIndex) => {
                    const originalIndex = parseInt(extra.position ?? "0");

                    return (
                      <Draggable
                        key={`draggable-extra-${originalIndex}`}
                        draggableId={`draggable-extra-${originalIndex}`}
                        index={originalIndex}
                      >
                        {(provided, snapshot) => (
                          <Accordion
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            component={Paper}
                            elevation={0}
                            sx={{
                              backgroundColor: snapshot.isDragging ? "var(--grey-cancelled)" : "",
                              mt: 2,
                              border: "1px solid #E4E4E4",
                              width: "100%",
                              "&:before": {
                                display: "none",
                              },
                            }}
                          >
                            <AccordionSummary expandIcon={<ExpandMore />}>
                              <Grid container justifyContent="space-between" alignItems="center">
                                <Grid
                                  item
                                  container
                                  width="fit-content"
                                  alignItems="center"
                                  gap={2}
                                >
                                  <DragIndicator />
                                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                    {t("EXTRA")} - {extra.name ? extra.name : filteredIndex + 1}
                                  </Typography>
                                </Grid>
                                <IconButton onClick={(e) => removeExtra(e, originalIndex)}>
                                  <Delete fontSize="small" />
                                </IconButton>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Stepper
                                  activeStep={activeSteps[filteredIndex] || 0}
                                  orientation="vertical"
                                >
                                  {steps.map((step, stepIndex) => (
                                    <Step key={step.label} sx={VERTICAL_STEEP_STYLE}>
                                      <StepLabel
                                        onClick={() => handleStepChange(filteredIndex, stepIndex)}
                                      >
                                        {step.label}
                                      </StepLabel>
                                      <StepContent>
                                        {cloneElement(step.content, { index: originalIndex })}
                                        <VerticalStepButtons
                                          activeStep={activeSteps[filteredIndex] || 0}
                                          handleStepChange={(value) =>
                                            handleStepChange(filteredIndex, value)
                                          }
                                          stepsLength={steps.length}
                                        />
                                      </StepContent>
                                    </Step>
                                  ))}
                                </Stepper>
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>

      <Grid item xs={12} container columnSpacing={2}>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            fullWidth
            sx={{
              ...GREY_BUTTON_STYLE,
              marginTop: "20px",
            }}
            onClick={handleAddExtra}
            startIcon={<Add sx={{ color: "var(--secondary-color)" }} />}
          >
            {t("ADD_EXTRA")}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="outlined"
            sx={{
              ...GREY_BUTTON_STYLE,
              marginTop: "20px",
            }}
            onClick={() => setOpenAddExtra(true)}
            startIcon={<Add sx={{ color: "var(--secondary-color)" }} />}
          >
            {t("ADD_EXTRA_FROM_TEMPLATE")}
          </Button>
        </Grid>
      </Grid>
      <ExtraTemplateDialog
        isOpen={openAddExtra}
        onClose={() => setOpenAddExtra(false)}
        onAccept={handleAddExtraTemplate}
        event_categories={eventCategories}
      />
    </>
  );
};

export default TripExtras;
