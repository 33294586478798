import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { getRoutePointName, normalizeVisibility } from "../../constants/utils";
import {
  ABECEDARY,
  ASSIGN_TRIP_TYPES,
  ASSIGN_TRIP_TYPES_ARRAY,
  getTransportIconById,
} from "../../constants/variables";
import useQueryTourPointsList from "../../hooks/queries/tourPoints/useQueryTourPointsList";
import AssignedHotelCard from "./assignToTrip/AssignedHotelCard";
import AssignedTourLeaderCard from "./assignToTrip/AssignedTourLeaderCard";
import AssignedTransportCard from "./assignToTrip/AssignedTransportCard";

const AssignComponent = ({
  tripInfo,
  itemsToAssign = [],
  handleAssign,
  handleRemove,
  type,
  handleCreateNew,
}) => {
  const { t } = useTranslation();
  const { data: tourPoints } = useQueryTourPointsList();

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const destinationId = destination.droppableId.split("-")[0];
    const assignedItemId = itemsToAssign[source.index].id || itemsToAssign[source.index].user_id;

    handleAssign(assignedItemId, destinationId);
  };

  const removeAssigned = (itemId, itineraryId) => {
    handleRemove(itemId, itineraryId);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box display="flex" justifyContent="space-between" width="100%" mb={2}>
        {/* LEFT (Drag Area) */}
        <Droppable droppableId="left" direction="vertical">
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps} width="45%" p={2}>
              <Grid container spacing={2}>
                <Grid item xs={6} onClick={handleCreateNew} sx={{ cursor: "pointer" }}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      borderRadius: "20px",
                    }}
                  >
                    <Typography variant="h6" textAlign="center">
                      + {t("CREATE_NEW")}{" "}
                      {type === ASSIGN_TRIP_TYPES.HOSTING
                        ? t("HOTEL")
                        : type === ASSIGN_TRIP_TYPES.TRANSPORTS
                        ? t("TRANSPORT")
                        : t("TOUR_LEADER")}
                    </Typography>
                  </Card>
                </Grid>
                {itemsToAssign.map((item, index) => (
                  <Grid key={`${item.id || item.user_id}-${index}-assign`} item xs={6}>
                    <Draggable
                      draggableId={`${item.id || item.user_id}`}
                      index={index}
                      component={Grid}
                      item
                      xs={6}
                    >
                      {(provided, snapshot) => (
                        <Card
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            borderRadius: "20px",
                            backgroundColor: snapshot.isDragging ? "#e0e0e0" : "transparent",
                            transform: snapshot.isDragging ? "scale(1.1)" : "scale(1)",
                            transition: "transform 0.2s ease, background-color 0.2s ease",
                          }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              flexGrow: 1,
                              padding: 1,
                              alignItems: "center",
                            }}
                          >
                            {type === ASSIGN_TRIP_TYPES.HOSTING && (
                              <Avatar
                                alt={item.name}
                                src={item.img_url}
                                variant="square"
                                sx={{
                                  width: 90,
                                  height: "auto",
                                  borderRadius: "8px",
                                }}
                              />
                            )}
                            {type === ASSIGN_TRIP_TYPES.TOUR_LEADERS && (
                              <Avatar
                                alt={item.name}
                                src={item.img_url}
                                variant="square"
                                sx={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: "100%",
                                }}
                              />
                            )}
                            {type === ASSIGN_TRIP_TYPES.TRANSPORTS && (
                              <Grid
                                item
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: 2,
                                }}
                              >
                                {getTransportIconById(item?.type, 30)}
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  sx={{ marginLeft: 1 }}
                                >
                                  {t(item?.type?.toUpperCase())}
                                </Typography>
                              </Grid>
                            )}
                            <CardContent sx={{ flex: 1, paddingLeft: 2 }}>
                              <Typography variant="h6" component="div">
                                {item.name}
                              </Typography>
                              <Typography variant="body2" color="textSecondary" mb={1}>
                                {item.sku}
                              </Typography>
                            </CardContent>
                          </Box>
                          <CardActions
                            disableSpacing
                            sx={{
                              justifyContent: "center",
                              gap: 2,
                              paddingTop: 1,
                            }}
                          ></CardActions>
                        </Card>
                      )}
                    </Draggable>
                  </Grid>
                ))}
                {provided.placeholder}
              </Grid>
            </Box>
          )}
        </Droppable>

        <Divider orientation="vertical" flexItem />

        {/* RIGHT (Drag Area) */}
        <Box width="45%" p={2} sx={{ border: "1px solid #ccc", borderRadius: 3 }}>
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 900 }}>
              {tripInfo.name}
            </Typography>
            <Typography variant="caption">
              {normalizeVisibility(tripInfo?.multi_date)
                ? t("MULTI_DATE")
                : `${tripInfo?.start_date || ""} - ${tripInfo?.end_date || ""}`}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
            {ASSIGN_TRIP_TYPES_ARRAY.map((zone, index) => (
              <Accordion
                key={"assign-trip-type-" + index}
                sx={{
                  boxShadow: "none",
                }}
                defaultExpanded={zone === type}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>{t(zone.toUpperCase())}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    {tripInfo?.itineraries
                      ?.filter((itinerary) => {
                        if (zone === ASSIGN_TRIP_TYPES.HOSTING) {
                          return (
                            itinerary.start_point || itinerary.end_point || itinerary.nights > 0
                          );
                        }
                        return true;
                      })
                      .map((itinerary, itineraryIndex) => (
                        <Droppable
                          key={`droppable-${itineraryIndex}-${zone}-${type}`}
                          droppableId={`${itinerary.id || itineraryIndex}-${zone}-${type}`}
                          direction="vertical"
                          isDropDisabled={zone !== type}
                        >
                          {(provided, snapshot) => (
                            <Grid
                              item
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              sx={{
                                backgroundColor: snapshot.isDraggingOver
                                  ? "var(--grey-cancelled)"
                                  : "",
                                borderRight:
                                  itineraryIndex !== tripInfo?.itineraries.length - 1 &&
                                  zone !== ASSIGN_TRIP_TYPES.TOUR_LEADERS
                                    ? "1px solid var(--grey-cancelled)"
                                    : "",
                                paddingInline: 2,
                                minHeight: zone !== ASSIGN_TRIP_TYPES.TOUR_LEADERS ? 120 : 0,
                              }}
                            >
                              {zone !== ASSIGN_TRIP_TYPES.TOUR_LEADERS && (
                                <>
                                  <Typography>
                                    {ABECEDARY[itineraryIndex]}: {itinerary.title}
                                  </Typography>
                                  <Typography variant="caption">
                                    {getRoutePointName(itinerary.tour_point_id, tourPoints)}
                                  </Typography>
                                  <br />
                                </>
                              )}
                              {/* HOTELS */}
                              {zone === ASSIGN_TRIP_TYPES.HOSTING ? (
                                itinerary.hotel_ids && itinerary.hotel_ids.length > 0 ? (
                                  <Box display="flex" gap={1} flexWrap="wrap">
                                    {itinerary.hotel_ids.map((hotelId, index) => (
                                      <Fragment key={`${hotelId}-${index}-hotel-card`}>
                                        <AssignedHotelCard
                                          hotelId={hotelId}
                                          handleClose={() =>
                                            removeAssigned(hotelId, itinerary.id || itineraryIndex)
                                          }
                                          selectedItinerary={itinerary.id || itineraryIndex}
                                        />
                                      </Fragment>
                                    ))}
                                  </Box>
                                ) : (
                                  <Typography variant="caption">{t("NO_ASSIGNED")}</Typography>
                                )
                              ) : null}
                              {/* TRANSPORTS */}
                              {zone === ASSIGN_TRIP_TYPES.TRANSPORTS ? (
                                itinerary.transport_ids && itinerary.transport_ids.length > 0 ? (
                                  <Box sx={{ display: "flex", gap: 2, mb: 1 }}>
                                    {itinerary.transport_ids.map((transportId, index) => (
                                      <Fragment key={`${transportId}-${index}-transport-card`}>
                                        <AssignedTransportCard
                                          transportId={transportId}
                                          handleClose={() =>
                                            removeAssigned(
                                              transportId,
                                              itinerary.id || itineraryIndex
                                            )
                                          }
                                        />
                                      </Fragment>
                                    ))}
                                  </Box>
                                ) : (
                                  <Typography variant="caption">{t("NO_ASSIGNED")}</Typography>
                                )
                              ) : null}
                              {zone === ASSIGN_TRIP_TYPES.TOUR_LEADERS && null}
                              {provided.placeholder}
                            </Grid>
                          )}
                        </Droppable>
                      ))}
                  </Grid>
                  {/* TOUR LEADERS  */}
                  {zone === ASSIGN_TRIP_TYPES.TOUR_LEADERS ? (
                    <Droppable
                      key={`droppable-tour-leaders-${zone}`}
                      droppableId={`${zone}-tourLeaders`}
                      direction="vertical"
                      isDropDisabled={zone !== type}
                    >
                      {(provided, snapshot) => (
                        <Grid
                          item
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            backgroundColor: snapshot.isDraggingOver ? "var(--grey-cancelled)" : "",
                            minHeight: 120,
                          }}
                        >
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            {tripInfo.tour_leader_ids && tripInfo.tour_leader_ids.length > 0 ? (
                              tripInfo.tour_leader_ids.map((tourLeaderId, index) => (
                                <Fragment key={`${tourLeaderId}-${index}-tour-card`}>
                                  <AssignedTourLeaderCard
                                    tourLeaderId={tourLeaderId}
                                    handleDelete={handleRemove}
                                  />
                                </Fragment>
                              ))
                            ) : (
                              <Typography>{t("NO_ASSIGNED")}</Typography>
                            )}
                          </Box>
                          {provided.placeholder}
                        </Grid>
                      )}
                    </Droppable>
                  ) : null}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Box>
    </DragDropContext>
  );
};

export default AssignComponent;
