import { Delete } from "@mui/icons-material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { extraErrorsAllOk } from "../../../classes/tripClass";
import { DEFAULT_EVENT_LANGUAGE } from "../../../constants/variables";
import ImageUpload from "../../shared/ImageUpload";
import InfoToolTip from "../../shared/InfoToolTip";
import { focusColor } from "../../shared/textFieldStyle";
import LanguageTabs from "../languages/LanguageTabs";
import ExtraDescription from "./ExtraDescription";
import ExtraDomainSelect from "./ExtraDomainSelect";
import ExtraName from "./ExtraName";
import ExtraShortDescription from "./ExtraShortDescription";
import ExtraTicketSelect from "./ExtraTicketSelect";

const ExtraCard = ({
  formController,
  isDisabled,
  index,
  errors,
  checkExtraField,
  removeExtra,
  sendButtonClicked,
  event_language_infos,
  setFormData,
  defaultLanguage = DEFAULT_EVENT_LANGUAGE,
}) => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const {
    formData: {
      extras,
      tickets,
      details: { event_categories },
    },
  } = formController;

  const extra = extras[index];

  if (!errors[index]) errors[index] = extraErrorsAllOk;

  const handleChangeLanguageParam = (value, key, languageIndex) => {
    setFormData((prev) => {
      const updatedExtras = [...prev.extras];
      updatedExtras[index].extra_language_infos[languageIndex][key] = value;
      return { ...prev, extras: updatedExtras };
    });
  };

  const handleSelectImage = (url) => {
    let newValue = "";
    if (Array.isArray(url)) {
      const imageArray = [];
      for (let i = 0; i < url.length; i++) {
        const urlParams = url[i].split(";");
        const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
        imageArray.push(imageObject);
      }
      if (imageArray.length === 0) {
        newValue = null;
      } else {
        newValue = imageArray;
      }
    } else {
      const urlParams = url.split(";");
      const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
      newValue = imageObject;
    }
    setFormData((prev) => ({
      ...prev,
      extras: prev.extras.map((extra, i) => (i === index ? { ...extra, images: newValue } : extra)),
    }));
  };

  useEffect(() => {
    if (defaultLanguage) {
      setSelectedLanguage(defaultLanguage);
    }
  }, [defaultLanguage]);

  return (
    <Draggable draggableId={`draggable-${index}`} index={parseInt(index, 10)}>
      {(provided, snapshot) => (
        <Accordion
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          component={Paper}
          elevation={0}
          sx={{
            backgroundColor: snapshot.isDragging ? "var(--grey-cancelled)" : "",
            mt: 2,
            border: "1px solid #E4E4E4",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container justifyContent="space-between" alignItems="center" mr={4}>
              <Grid item container width="fit-content" alignItems="center" gap={2}>
                <DragIndicatorIcon />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {t("EXTRA")} - {extra.name || index + 1}
                </Typography>
              </Grid>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  removeExtra(index);
                }}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {event_language_infos?.length > 0 ? (
                  <LanguageTabs
                    event_languages_info={event_language_infos}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    defaultLanguage={defaultLanguage}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12} sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
                <ExtraName
                  checkExtraField={checkExtraField}
                  index={index}
                  extras={extras}
                  errors={errors}
                  sendButtonClicked={sendButtonClicked}
                  isDisabled={isDisabled}
                  extra_language_infos={extra.extra_language_infos}
                  handleChangeLanguageParam={handleChangeLanguageParam}
                  selectedLanguage={selectedLanguage}
                  defaultLanguage={defaultLanguage}
                />
                <Grid item xs={12} container columnSpacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      onChange={(e) =>
                        checkExtraField(
                          "initial_stock",
                          e,
                          /^[0-9]\d*$/,
                          t("INCORRECT"),
                          true,
                          index
                        )
                      }
                      value={extra.initial_stock}
                      margin="normal"
                      type="number"
                      fullWidth
                      id="initial_stock"
                      name="initial_stock"
                      label={t("STOCK_TOTAL")}
                      autoComplete="initial_stock"
                      error={
                        (errors[index].initial_stock !== "" &&
                          errors[index].initial_stock !== "empty") ||
                        (errors[index].initial_stock === "empty" && sendButtonClicked)
                      }
                      helperText={
                        errors[index].initial_stock !== "empty" ? errors[index].initial_stock : ""
                      }
                      disabled={isDisabled}
                      sx={focusColor}
                      onWheel={(e) => e.target.blur()}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      onChange={(e) =>
                        checkExtraField(
                          "times_can_be_consumed",
                          e,
                          /^[0-9]*$/,
                          t("INCORRECT"),
                          true,
                          index
                        )
                      }
                      value={extra.times_can_be_consumed}
                      margin="normal"
                      type="number"
                      fullWidth
                      id="times_can_be_consumed"
                      name="times_can_be_consumed"
                      label={t("TIMES_CAN_BE_CONSUMED")}
                      autoComplete="times_can_be_consumed"
                      error={
                        errors[index].times_can_be_consumed !== "" &&
                        errors[index].times_can_be_consumed !== "empty"
                      }
                      helperText={
                        errors[index].times_can_be_consumed !== "empty"
                          ? errors[index].times_can_be_consumed
                          : ""
                      }
                      disabled={isDisabled}
                      sx={focusColor}
                      onWheel={(e) => e.target.blur()}
                    />
                  </Grid>
                </Grid>
                <ExtraShortDescription
                  checkExtraField={checkExtraField}
                  index={index}
                  extras={extras}
                  errors={errors}
                  sendButtonClicked={sendButtonClicked}
                  isDisabled={isDisabled}
                  extra_language_infos={extra.extra_language_infos}
                  handleChangeLanguageParam={handleChangeLanguageParam}
                  selectedLanguage={selectedLanguage}
                  defaultLanguage={defaultLanguage}
                />
                <ExtraDescription
                  checkExtraField={checkExtraField}
                  index={index}
                  extras={extras}
                  errors={errors}
                  sendButtonClicked={sendButtonClicked}
                  isDisabled={isDisabled}
                  selectedLanguage={selectedLanguage}
                  extra_language_infos={extra.extra_language_infos}
                  handleChangeLanguageParam={handleChangeLanguageParam}
                  defaultLanguage={defaultLanguage}
                />
              </Grid>
              <Grid item xs={12}>
                <ImageUpload url={extra.images} onImageSelect={handleSelectImage} multi={false} />
              </Grid>
              <Grid
                container
                columnSpacing={2}
                sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
              >
                <Grid item xs={12} md={8}>
                  <TextField
                    onChange={(e) =>
                      checkExtraField(
                        "stock_by_ticket_max",
                        e,
                        /^[0-9]\d*$/,
                        t("INCORRECT"),
                        true,
                        index
                      )
                    }
                    value={extra.stock_by_ticket_max}
                    margin="normal"
                    type="number"
                    fullWidth
                    id="stock_by_ticket_max"
                    name="stock_by_ticket_max"
                    label={t("LIMIT_EXTRA")}
                    autoComplete="stock_by_ticket_max"
                    error={
                      (errors[index].stock_by_ticket_max !== "" &&
                        errors[index].stock_by_ticket_max !== "empty") ||
                      (errors[index].stock_by_ticket_max === "empty" && sendButtonClicked)
                    }
                    helperText={
                      errors[index].stock_by_ticket_max !== "empty"
                        ? errors[index].stock_by_ticket_max
                        : ""
                    }
                    disabled={isDisabled}
                    sx={focusColor}
                    onWheel={(e) => e.target.blur()}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    onChange={(e) =>
                      checkExtraField("price", e, /^\d+(?:\.\d+)?$/, t("INCORRECT"), true, index)
                    }
                    value={extra.price}
                    margin="normal"
                    type="number"
                    fullWidth
                    id="price"
                    name="price"
                    label={t("PRICE")}
                    autoComplete="price"
                    error={
                      (errors[index].price !== "" && errors[index].price !== "empty") ||
                      (errors[index].price === "empty" && sendButtonClicked)
                    }
                    helperText={errors[index].price !== "empty" ? errors[index].price : ""}
                    disabled={isDisabled}
                    sx={focusColor}
                    onWheel={(e) => e.target.blur()}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ mt: 2, display: "flex", flexDirection: "row", alignItems: "center" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="is_visible"
                      checked={extra.is_visible === 1 || extra.is_visible === true}
                      onChange={(e) =>
                        checkExtraField(
                          "is_visible",
                          e,
                          /^(true|false)$/,
                          t("CHOOSE_OPTION"),
                          true,
                          index,
                          "checked"
                        )
                      }
                      disabled={isDisabled}
                      value={extra.is_visible}
                      className="checkbox-oniria"
                    />
                  }
                  label={t("IS_VISIBLE")}
                />
                <InfoToolTip text={t("IS_VISIBLE_INFO")} />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ mt: 2, display: "flex", flexDirection: "row", alignItems: "center" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="only_international"
                      checked={extra.only_international === 1 || extra.only_international === true}
                      onChange={(e) =>
                        checkExtraField(
                          "only_international",
                          e,
                          /^(true|false)$/,
                          t("CHOOSE_OPTION"),
                          true,
                          index,
                          "checked"
                        )
                      }
                      disabled={isDisabled}
                      value={extra.only_international}
                      className="checkbox-oniria"
                    />
                  }
                  label={t("ONLY_MEMBER_CARD")}
                />
                <InfoToolTip text={t("ONLY_INTERNATIONAL_CARD_INFO")} />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ mt: 2, display: "flex", flexDirection: "row", alignItems: "center" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="no_after_sales"
                      checked={extra.no_after_sales === 1 || extra.no_after_sales === true}
                      onChange={(e) =>
                        checkExtraField(
                          "no_after_sales",
                          e,
                          /^(true|false)$/,
                          t("CHOOSE_OPTION"),
                          true,
                          index,
                          "checked"
                        )
                      }
                      disabled={isDisabled}
                      value={extra.no_after_sales}
                      className="checkbox-oniria"
                    />
                  }
                  label={t("NO_AFTER_SALES")}
                />
                <InfoToolTip text={t("NO_AFTER_SALES_INFO")} />
              </Grid>
              <ExtraTicketSelect
                ticketsIds={extra.tickets_ids}
                eventTickets={tickets}
                setFormData={setFormData}
                extraIndex={index}
              />
              <ExtraDomainSelect
                event_categories={event_categories}
                extraDomains={extra?.domain_names}
                setFormData={setFormData}
                extraIndex={index}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
};

export default ExtraCard;
