import { ListAlt, MoreHoriz } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Popover,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { EVENT_TABS, EVENTS_TYPE } from "../../../constants/variables";
import useUserRoles from "../../../hooks/useUserRoles";
import ScannerAssistant from "../scanner/ScannerAssistant";
import DeleteEventDialog from "./DeleteEventDialog";

const EventCardButtons = ({ event, setQrResponse, eventSales, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isAdmin, isManager, isSuperAdmin, userCanEdit } = useUserRoles();
  const selectedEventsTab = useSelector((state) => state.b2bInfo.selectedTab);

  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleEventClick = (id) => {
    navigate(`/event/${id}`);
  };

  const handleDuplicateEvent = () => {
    if (event.event_type === EVENTS_TYPE.TRIP_COMPOSITE) {
      navigate(`/trips/create_trip_plus/${event.id}`);
    } else if (event.event_type === EVENTS_TYPE.TRIP) {
      navigate(`/create_trip/${event.id}`);
    } else {
      navigate(`/create_event/${event.id}`);
    }
  };

  const handleCreateChildEvent = () => {
    navigate(`/trips/create_child_trip/${event.id}`);
  };

  /* popover */
  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = Boolean(anchorEl);
  const popperId = openPopover ? "simple-popover" : undefined;
  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClosePop = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return isMobile ? (
    <Grid
      item
      xs={12}
      md={12}
      lg={3}
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
      mt={{ xs: 2, lg: 0 }}
    >
      <ScannerAssistant
        eventId={event.id}
        goBack={() => navigate(ROUTES.EVENTS)}
        eventName={event.name}
        totalTickets={eventSales?.sold_tickets}
        consumedTickets={eventSales?.consumed_tickets}
        isMobileButton
        setQrResponse={setQrResponse}
      />
      <Grid item xs={5} container justifyContent="center">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/event/${event.id}?newValueTab=1`);
          }}
          className="oniria-btn"
          sx={{ borderRadius: "20%" }}
        >
          <ListAlt sx={{ fontSize: "3rem", color: "#fff" }} />
        </IconButton>
      </Grid>
    </Grid>
  ) : (
    <Grid
      item
      xs={12}
      md={12}
      lg={3}
      container
      columnGap={3}
      alignItems={"center"}
      mt={{ xs: 2, lg: 0 }}
    >
      <Grid item xs={8}>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/event/${event.id}?newValueTab=1`);
          }}
          type="submit"
          aria-label="edit"
          className="oniria-btn"
          fullWidth
          variant="contained"
          sx={{ fontSize: "12px", borderRadius: 3, mb: 1 }}
        >
          {t("LIST_ATTENDEES")}
        </Button>
      </Grid>
      <Grid item xs={2.5}>
        <Button
          fullWidth
          variant="contained"
          sx={{ height: "33px", borderRadius: 3, mb: 1 }}
          aria-label="show more"
          className="oniria-btn"
          onClick={handleClick}
          aria-describedby={popperId}
        >
          <MoreHoriz sx={{ fontSize: "2rem" }} />
        </Button>
      </Grid>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePop}
        id={popperId}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ButtonGroup variant="contained" orientation="vertical" aria-label="vertical button group">
          <Button
            className="popover-btn"
            type="submit"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/event/${event.id}?newValueTab=2`);
            }}
          >
            {t("ORDERS")}
          </Button>
          {selectedEventsTab === EVENT_TABS.MY_EVENTS && userCanEdit && (
            <Button className="popover-btn" type="submit" onClick={handleDuplicateEvent}>
              {t("DUPLICATE")}
            </Button>
          )}
          {userCanEdit && event.event_type === EVENTS_TYPE.TRIP_COMPOSITE && (
            <Button className="popover-btn" onClick={handleCreateChildEvent}>
              {t("CREATE_CHILD_EVENT")}
            </Button>
          )}
          {(isSuperAdmin || isManager || isAdmin) && selectedEventsTab === EVENT_TABS.MY_EVENTS && (
            <Button className="popover-btn" onClick={handleClickOpenDelete}>
              {t("REMOVE")}
            </Button>
          )}
        </ButtonGroup>
      </Popover>
      <Grid item xs={8}>
        <Button
          onClick={() => handleEventClick(event.id)}
          aria-label="edit"
          className="oniria-btn"
          fullWidth
          variant="contained"
          sx={{ fontSize: "12px", borderRadius: 3, mb: 1 }}
        >
          {t("MANAGE")}
        </Button>
      </Grid>

      <DeleteEventDialog
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        eventId={event.id}
        refetch={refetch}
      />
    </Grid>
  );
};

export default EventCardButtons;
