import { Box, Divider, Grid, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PAGINATION_STYLES } from "../../../../constants/styles";
import { ASSIGN_TRIP_TYPES } from "../../../../constants/variables";
import useQueryTransports from "../../../../hooks/queries/transports/useQueryTransports";
import useDebounce from "../../../../hooks/useDebouncing";
import usePagination from "../../../../hooks/usePagination";
import { TEMPLATES_TAB } from "../../../../utils/hotels";
import SearchFilter from "../../../events/filters/filter/SearchFilter";
import AssignComponent from "../../../shared/AssignComponent";
import AssignTransportDialog from "../../../shared/assignTransportDialog/AssignTransportDialog";
import CreateTransportDialog from "../../../transports/createDialog/CreateTransportDialog";

const AddTransports = () => {
  const { t } = useTranslation();

  const { getValues, setValue } = useFormContext();
  const { page, changePage } = usePagination();
  const [search, setSearch] = useState("");
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [transportId, setTransportId] = useState(null);
  const [selectedItinerary, setSelectedItinerary] = useState(null);
  const [openAssign, setOpenAssign] = useState(false);

  const tripInfo = getValues();

  const { data } = useQueryTransports(
    page,
    3,
    {
      search,
      isActive: true,
    },
    TEMPLATES_TAB,
    () => {}
  );
  const transports = data?.data;
  const totalPages = Math.ceil((data?.total || 0) / 3);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleAssignTransport = async (transportId, itineraryId) => {
    setTransportId(transportId);
    setOpenAssign(true);
    setSelectedItinerary(itineraryId);
  };

  const addTransportToItinerary = (transportId) => {
    const prevItineraries = getValues("itineraries");
    const newItineraries = prevItineraries.map((itinerary, index) => {
      if (itinerary.id?.includes(selectedItinerary) || selectedItinerary == index) {
        if (!itinerary.transport_ids) {
          itinerary.transport_ids = [transportId];
        } else {
          itinerary.transport_ids = [...itinerary?.transport_ids, transportId];
        }
      }
      return itinerary;
    });
    setValue("itineraries", newItineraries);
  };

  const handleRemoveTransport = (itemId, itineraryId) => {
    const prevItineraries = getValues("itineraries");
    const newItineraries = prevItineraries.map((itinerary, index) => {
      if (itinerary.id?.includes(itineraryId) || itineraryId == index) {
        if (itinerary.transport_ids) {
          itinerary.transport_ids = itinerary?.transport_ids.filter(
            (transportId) => transportId !== itemId
          );
        }
      }
      return itinerary;
    });

    setValue("itineraries", newItineraries);
  };

  const handleCreateTransport = () => {
    setOpenCreateDialog(true);
  };

  return (
    <>
      <Divider sx={{ width: "100%", borderColor: "var(--secondary-color)", mt: 2, mb: 2 }} />
      <Typography variant="body1" sx={{ fontWeight: 900, width: "100%" }}>
        {t("ASSIGN_TRANSPORTS")}
      </Typography>
      <Typography variant="caption" sx={{ mb: 2 }}>
        {t("ASSIGN_TRANSPORTS_DESCRIPTION")}
      </Typography>
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <Grid container spacing={2} sx={{ width: "45%", justifyContent: "start" }}>
          <Grid item xs={12} sm={6}>
            <SearchFilter onSearchChange={debounceSearch} />
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent="flex-end">
            <Pagination
              count={totalPages}
              page={page}
              onChange={changePage}
              sx={PAGINATION_STYLES}
            />
          </Grid>
        </Grid>
      </Box>
      <AssignComponent
        tripInfo={tripInfo}
        itemsToAssign={transports}
        handleAssign={handleAssignTransport}
        handleRemove={handleRemoveTransport}
        type={ASSIGN_TRIP_TYPES.TRANSPORTS}
        handleCreateNew={handleCreateTransport}
      />

      <AssignTransportDialog
        isOpen={openAssign}
        onClose={() => setOpenAssign(false)}
        transportTemplateId={transportId}
        addTransportToItinerary={addTransportToItinerary}
      />

      <CreateTransportDialog isOpen={openCreateDialog} onClose={() => setOpenCreateDialog(false)} />
    </>
  );
};

export default AddTransports;
