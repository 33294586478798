import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const VerticalStepButtons = ({ activeStep, handleStepChange, stepsLength }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        gap: 2,
      }}
    >
      <Button
        disabled={activeStep === 0}
        onClick={() => handleStepChange(activeStep - 1)}
        variant="outlined"
        className="border-btn-dark"
      >
        {t("BACK")}
      </Button>
      <Button
        disabled={activeStep === stepsLength - 1}
        onClick={() => handleStepChange(activeStep + 1)}
        variant="contained"
        className="oniria-btn"
      >
        {t("NEXT")}
      </Button>
    </Box>
  );
};

export default VerticalStepButtons;
