import { Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { VERTICAL_STEEP_STYLE } from "../../../constants/styles";
import VerticalStepButtons from "../../shared/VerticalStepButtons";
import AddHosting from "./configuration/AddHosting";
import TripItineraries from "./configuration/routePoints/TripItineraries";
import AddTransports from "./configuration/AddTransports";
import AddTourLeaders from "./configuration/AddTourLeaders";

const ConfigurationSteps = ({ tripComponents }) => {
  const { t } = useTranslation();
  const { hasHosting, hasTransports, hasTourLeaders } = tripComponents || {};

  const steps = [
    {
      label: t("ROUTE"),
      description: t("ROUTE_DESCRIPTION"),
      isActive: true,
      component: <TripItineraries />,
    },
    {
      label: t("HOSTING"),
      description: t("ASSIGN_HOSTING"),
      isActive: hasHosting,
      component: <AddHosting />,
    },
    {
      label: t("TRANSPORTS"),
      description: t("ASSIGN_TRANSPORTS"),
      isActive: hasTransports,
      component: <AddTransports />,
    },
    {
      label: t("TOUR_LEADERS"),
      description: t("ASSIGN_TOUR_LEADERS"),
      isActive: hasTourLeaders,
      component: <AddTourLeaders />,
    },
  ];

  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps
        .filter((step) => step.isActive)
        .map((step, stepIndex) => (
          <Step key={step.label} sx={VERTICAL_STEEP_STYLE}>
            <StepLabel onClick={() => handleStepChange(stepIndex)}>
              <Typography variant="body1" fontWeight="bold" pt={2}>
                {step.label}
              </Typography>
              <Typography variant="body2">{step.description}</Typography>
            </StepLabel>
            {activeStep === stepIndex && <StepContent>{step.component}</StepContent>}
            <StepContent>
              <VerticalStepButtons
                activeStep={activeStep}
                handleStepChange={handleStepChange}
                stepsLength={steps.length}
              />
            </StepContent>
          </Step>
        ))}
    </Stepper>
  );
};

export default ConfigurationSteps;
