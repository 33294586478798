import { SERVICE } from "../constants/api";
import http from "../constants/axios";
import { EVENTS_TYPE } from "../constants/variables";

export async function getEventById(id) {
  const { data } = await http.get(`${SERVICE.EVENTS}${id}`);
  return data;
}
export async function deleteEvent(id) {
  const { data } = await http.delete(`${SERVICE.EVENTS}${id}`);
  return data;
}
export async function createEvent(body) {
  const { data } = await http.post(`${SERVICE.EVENTS}`, body);
  return data;
}
export async function editEvent(id, body) {
  const { data } = await http.put(`${SERVICE.EVENTS}${id}`, body);
  return data;
}
export async function deleteBlockEvent(id) {
  const { data } = await http.delete(`${SERVICE.EVENTS}blocks/${id}`);
  return data;
}
export async function deleteEventItinerary(id) {
  const { data } = await http.delete(`${SERVICE.EVENTS}itineraries/${id}`);
  return data;
}
//
export async function getAllEventsFiltered(
  city,
  search,
  date,
  page,
  perPage,
  group,
  premise,
  eventType,
  organization
) {
  let params = new URLSearchParams();

  if (city && city !== "all") params.append("city", city);
  if (search) params.append("search", search);
  if (date) params.append("date", date);
  if (page) params.append("page", page);
  if (perPage) params.append("perpage", perPage);
  if (group && group !== "all") params.append("grouping_id", group);
  if (premise && premise !== "all") params.append("premise_id", premise);
  if (eventType && eventType !== "all") params.append("type", eventType);
  if (organization && organization !== "all") params.append("organization_id", organization);

  const { data } = await http.get(`${SERVICE.ALLEVENTS}?${params}`);
  return data;
}

//Domain
export async function getEventsDomains(
  page,
  perPage,
  domain,
  search,
  category_id,
  subcategory_id,
  date,
  type,
  cityFilter,
  premise,
  grouping,
  organization
) {
  let params = new URLSearchParams();
  if (page) params.append("page", page);
  if (perPage) params.append("perpage", perPage);
  if (domain && domain !== "all") params.append("domain", domain);
  if (search) params.append("search", search);
  if (category_id && category_id !== "all") params.append("category_id", category_id);
  if (subcategory_id && subcategory_id !== "all") params.append("subcategory_id", subcategory_id);
  if (date && date !== "all") params.append("date", date);
  if (type && type !== "all") params.append("type", EVENTS_TYPE.TRIP_COMPOSITE);
  if (cityFilter && cityFilter !== "all") params.append("city", cityFilter);
  if (premise && premise !== "all") params.append("premise_id", premise);
  if (grouping && grouping !== "all") params.append("grouping_id", grouping);
  if (organization && organization !== "all") params.append("organization_id", organization);

  const { data } = await http.get(`${SERVICE.ALLEVENTS}/domain?${params}`);
  return data;
}

//Partys
export async function getEvents() {
  const { data } = await http.get(`${SERVICE.PARTYS}`);
  return data;
}
//Activities
export async function getActivities() {
  const { data } = await http.get(`${SERVICE.ACTIVITIES}`);
  return data;
}
//Trips
export async function getTrips() {
  const { data } = await http.get(`${SERVICE.TRIPS}`);
  return data;
}
export async function getFilterTrips(
  city,
  search,
  date,
  page,
  perPage,
  group,
  premise,
  organization
) {
  const cityParameter = city === "all" ? "" : `&city=${city}`;
  const searchParameter = search === "" ? "" : `&search=${search}`;
  const dateParameter = date === "all" ? "" : `&date=${date}`;
  const pageParameter = page === "" ? "" : `&page=${page}`;
  const perPageParameter = perPage === "" ? "" : `&perpage=${perPage}`;
  const groupParameter = group === "all" ? "" : `&grouping_id=${group}`;
  const premiseParameter = premise === "all" ? "" : `&premise_id=${premise}`;
  const organizationParameter = organization === "all" ? "" : `&organization_id=${organization}`;
  const { data } = await http.get(
    `${SERVICE.TRIPS}${cityParameter}${searchParameter}${dateParameter}${pageParameter}${perPageParameter}${premiseParameter}${groupParameter}${organizationParameter}`
  );
  return data;
}
////Assistants
export async function getAllAssistants(id, selectedDomain, page, perPage, filters) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (page) params.append("page", page);
  if (perPage) params.append("perpage", perPage);
  if (filters?.search) params.append("search", filters.search);
  if (filters?.ticket_id) params.append("ticket_id", filters.ticket_id);
  if (filters?.asc && filters?.asc.length > 0) {
    params.append("asc", filters.asc.join(","));
  }
  if (filters?.desc && filters?.desc.length > 0) {
    params.append("desc", filters.desc.join(","));
  }

  const { data } = await http.get(`${SERVICE.ASSISTANTS}${id}?${params}`);
  return data;
}
export async function getAssistantDetail(id, eventId) {
  const { data } = await http.get(`${SERVICE.ASSISTANT_DETAIL}${id}?eventid=${eventId}`);
  return data;
}
export async function getAssistantDetailNoEvent(id) {
  const { data } = await http.get(`${SERVICE.ASSISTANT_DETAIL}${id}`);
  return data;
}
////Orders
export async function getAllOrders(
  id,
  selectedDomain,
  page,
  perPage,
  search,
  order,
  selectedTicket,
  selectedStatus
) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (page) params.append("page", page);
  if (perPage) params.append("perpage", perPage);
  if (search) params.append("search", search);
  if (order?.asc && order?.asc.length > 0) {
    params.append("asc", order.asc.join(","));
  }
  if (order?.desc && order?.desc.length > 0) {
    params.append("desc", order.desc.join(","));
  }
  if (selectedTicket && selectedTicket !== "all") params.append("ticket_id", selectedTicket);
  if (selectedStatus && selectedStatus !== "all") params.append("status", selectedStatus);

  const { data } = await http.get(`${SERVICE.ORDERS_EVENT}${id}?${params}`);
  return data;
}
export async function getOrderDetail(id) {
  const { data } = await http.get(`${SERVICE.ORDERS}${id}`);
  return data;
}
export async function validateTicket(id) {
  const { data } = await http.put(`${SERVICE.CONSUME_TICKET}${id}`);
  return data;
}
export async function validateExtra(id) {
  const { data } = await http.put(`${SERVICE.CONSUME_EXTRA}${id}`);
  return data;
}
export async function createOrder(body) {
  const { data } = await http.post(`${SERVICE.ORDERS}`, body);
  return data;
}

// Tickets
export async function createTicket(body) {
  const { data } = await http.post(`${SERVICE.TICKETS}`, body);
  return data;
}
export async function getTicketsByEventId(id, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  const { data } = await http.get(`${SERVICE.TICKETS}event/${id}?${params}`);
  return data;
}
export async function updateTicket(id, body) {
  const { data } = await http.put(`${SERVICE.TICKETS}${id}`, body);
  return data;
}
export async function deleteTicket(id) {
  const { data } = await http.delete(`${SERVICE.TICKETS}${id}`);
  return data;
}
export async function getTicketsAndExtras(id) {
  const { data } = await http.get(`${SERVICE.TICKETS_AND_EXTRAS}${id}`);
  return data;
}
export async function deleteGroupDiscount(id) {
  const { data } = await http.delete(`${SERVICE.GROUP_DISCOUNTS}${id}`);
  return data;
}
// Extras
export async function createExtra(body) {
  const { data } = await http.post(`${SERVICE.EXTRAS}`, body);
  return data;
}
export async function getExtrasByEventId(id) {
  const { data } = await http.get(`${SERVICE.EXTRAS}event/${id}`);
  return data;
}
export async function updateExtra(id, body) {
  const { data } = await http.put(`${SERVICE.EXTRAS}${id}`, body);
  return data;
}
export async function deleteExtra(id) {
  const { data } = await http.delete(`${SERVICE.EXTRAS}${id}`);
  return data;
}
// Cities
export async function getCities() {
  const { data } = await http.get(`${SERVICE.CITIES}`);
  return data;
}
//All Events
export async function getAllEvents(premise) {
  const premiseParameter = premise === "" ? "" : `?premiseid=${premise}`;
  const { data } = await http.get(`${SERVICE.ALLEVENTS}/list${premiseParameter}`);
  return data;
}

//Statics of event
export async function getEventStatics(eventId, selectedDomain) {
  const params = new URLSearchParams();
  if (selectedDomain) params.append("domain", selectedDomain);
  if (eventId) params.append("eventid", eventId);

  const { data } = await http.get(`${SERVICE.STATISTICS}?${params}`);
  return data;
}

// Check valid event URL
export async function checkAvailableUrl(url) {
  const { data } = await http.get(`${SERVICE.CHECK_URL}/${url}`);
  return data;
}

// Check valid event SKU
export async function checkAvailableSku(sku) {
  const { data } = await http.get(`${SERVICE.CHECK_SKU}/${sku}`);
  return data;
}

// event sales for event card
export async function getEventSales(eventId) {
  const { data } = await http.get(`${SERVICE.EVENT_SALES}${eventId}`);
  return data;
}

export async function getEventSalesDomain(eventId) {
  const { data } = await http.get(`${SERVICE.EVENT_SALES_DOMAIN}${eventId}`);
  return data;
}

// CHILD EVENTS
export async function getParentChildEvents(eventId) {
  const { data } = await http.get(`${SERVICE.EVENTS}childs/${eventId}`);
  return data;
}
