import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Button, Grid, Step, StepButton, Stepper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { STEEP_STYLE } from "../../constants/styles";
import { DEFAULT_LANGUAGE, TRIP_STEPS } from "../../constants/variables";
import useDuplicateTrip from "../../hooks/duplicateEvent/useDuplicateTrip";
import { TRIP_STEPS_VALIDATION, tripSchema } from "../../schemas/trips";
import {
  createEvent,
  createExtra,
  createTicket,
  editEvent,
  updateExtra,
  updateTicket,
} from "../../services/eventsServices";
import { prepareTripToReceive, prepareTripToSend } from "../../utils/trips";
import Header from "../Header";
import { Loading } from "../shared/Loading";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";
import TripComponents from "./components/TripComponents";
import TripConfiguration from "./configuration/TripConfiguration";
import PrincipalInformationPlus from "./details/PrincipalInformationPlus";
import TripExtras from "./extras/TripsExtras";
import TripMoreInfo from "./moreInformation/TripMoreInfo";
import TripTickets from "./tickets/TripTickets";
import TripPlusContainer from "./TripPlusContainer";

const TripPlusForm = ({ formData, extraTemplateId, isChild }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingFormData, setLoadingFormData] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const breadcrumbs = [
    {
      name: t("TRAVELS"),
      link: ROUTES.TRIPS,
    },
    {
      name: t("CREATE_TRAVEL"),
      link: ROUTES.CREATE_TRIP_PLUS,
    },
  ];

  const formMethods = useForm({
    resolver: zodResolver(tripSchema),
    mode: "onBlur",
    defaultValues: {
      default_language: DEFAULT_LANGUAGE,
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
    trigger,
    getValues,
  } = formMethods;

  console.log("errors", errors);
  console.log("values", getValues());

  const validateStep = async () => {
    const isValid = await trigger(TRIP_STEPS_VALIDATION[activeStep]);
    return isValid;
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    const response = await validateStep();
    if (!response) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleChangeStep = async (e, step) => {
    e.preventDefault();
    const response = await validateStep();
    if (!response) return;
    setActiveStep(step);
  };

  const handleSetFormData = (data) => {
    setLoadingFormData(true);
    const newData = prepareTripToReceive(data);
    setTimeout(() => {
      reset(newData);
      setLoadingFormData(false);
    }, 500);
  };

  const handleEditTrip = async (data) => {
    const { tickets, extras, id } = data;

    // TICKETS
    if (tickets && tickets.length > 0) {
      const updatedTickets = await Promise.all(
        tickets.map(async (ticket) => {
          ticket.event_id = id;
          if (ticket.id) {
            await updateTicket(ticket.id, ticket);
            return ticket;
          } else {
            const createdTicket = await createTicket(ticket);
            return { ...ticket, id: createdTicket.id };
          }
        })
      );
      data.tickets = updatedTickets;
    }

    // EXTRAS
    if (extras && extras.length > 0) {
      const updatedExtras = await Promise.all(
        extras.map(async (extra) => {
          extra.event_id = id;
          if (extra.id) {
            await updateExtra(extra.id, extra);
            return extra;
          } else {
            const createdExtra = await createExtra(extra);
            return { ...extra, id: createdExtra.id };
          }
        })
      );
      data.extras = updatedExtras;
    }
    const dataWithoutTicketsExtras = JSON.parse(JSON.stringify(data));
    delete dataWithoutTicketsExtras.tickets;
    delete dataWithoutTicketsExtras.extras;
    // PRINCIPAL INFO
    await editEvent(id, dataWithoutTicketsExtras);
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setLoadingSubmit(true);
    try {
      const bodyToSend = await prepareTripToSend(data);
      if (data.id) {
        // EDIT
        await handleEditTrip(bodyToSend);
      } else {
        // NEW
        await createEvent(bodyToSend);
      }
      if (data.id) {
        toastMessageSuccess(t("EDITED_SUCCESSFULLY"));
      } else {
        setTimeout(() => {
          handleGoBack();
        }, 1500);
        toastMessageSuccess(t("CREATED_SUCCESSFULLY"));
      }
    } catch (e) {
      console.log("Error al crear viaje", e);
      toastMessageError(e?.response?.data?.error || t("ERROR"));
    } finally {
      setLoadingSubmit(false);
    }
  };

  const onError = (errors) => {
    console.log("Errores formulario de viajes", errors);
  };

  const handleGoBack = () => {
    navigate(ROUTES.TRIPS);
  };

  const handleBack = async (e) => {
    e.preventDefault();
    const response = await validateStep();
    if (!response) return;
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return activeStep === TRIP_STEPS.length - 1;
  };

  // FOR EDIT TRIP
  useEffect(() => {
    if (formData) {
      handleSetFormData(formData);
    }
  }, [formData]);

  //FOR DUPLICATE TRIP
  const { formInfo, loadingDuplicate } = useDuplicateTrip(id, isChild);

  useEffect(() => {
    if (formInfo && !loadingDuplicate && !formData) {
      handleSetFormData(formInfo);
    }
  }, [formInfo, loadingDuplicate]);

  // FOR EXTRA TEMPLATE
  useEffect(() => {
    if (extraTemplateId) {
      setActiveStep(5);
    }
  }, [extraTemplateId]);

  return loadingFormData ? (
    <Loading />
  ) : (
    <Grid container mt={!!formData ? 7 : 0} mb={5}>
      {!formData && <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_CREATE_TRIP")} />}
      <Grid
        item
        xs={12}
        sm={10}
        lg={12}
        sx={{
          mb: 5,
          flexDirection: "row",
          justifyContent: "initial",
        }}
      >
        <Grid container flexWrap="nowrap" justifyContent="center">
          <Button
            onClick={handleBack}
            sx={{ color: "var(--secondary-color)" }}
            disabled={activeStep === 0}
          >
            <ArrowBackIos fontSize="small" sx={{ color: "var(--secondary-color)" }} />
          </Button>
          <Stepper
            nonLinear
            activeStep={activeStep}
            sx={{
              overflowX: {
                xs: "auto",
                lg: "hidden",
              },
              overflowY: "hidden",
              height: "auto",
              maxHeight: "100%",
            }}
          >
            {TRIP_STEPS.map((label, index) => (
              <Step
                key={label}
                sx={{
                  ...STEEP_STYLE,
                  flexShrink: 0,
                  display: {
                    xs: activeStep !== index ? "none" : "block",
                    sm: "block",
                  },
                }}
              >
                <StepButton color="inherit" onClick={(e) => handleChangeStep(e, index)}>
                  {t(label)}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <Button
            sx={{ color: "var(--secondary-color)" }}
            onClick={handleContinue}
            disabled={activeStep === TRIP_STEPS.length - 1}
          >
            <ArrowForwardIos />
          </Button>
        </Grid>
        {!!errors && Object.keys(errors).length > 0 ? (
          <Typography sx={{ color: "var(--oniria-red)" }}>*{t("CHECK_FIELDS")}</Typography>
        ) : null}
      </Grid>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onError)} style={{ width: "100%" }}>
          <TripPlusContainer
            handleContinue={handleContinue}
            handleBack={handleBack}
            isFirstStep={isFirstStep()}
            isLastStep={isLastStep()}
            loadingSubmit={loadingSubmit}
            isEdit={!!formData}
          >
            {activeStep === 0 && <PrincipalInformationPlus />}
            {activeStep === 1 && <TripConfiguration />}
            {activeStep === 2 && <TripComponents />}
            {activeStep === 3 && <TripMoreInfo />}
            {activeStep === 4 && <TripTickets />}
            {activeStep === 5 && <TripExtras extraTemplateId={extraTemplateId} />}
          </TripPlusContainer>
        </form>
      </FormProvider>
    </Grid>
  );
};

export default TripPlusForm;
