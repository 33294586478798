import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { createTrip, createTripErrors } from "../../../classes/tripClass";
import Header from "../../../components/Header";
import Details from "../../../components/events/details/Details";
import Extras from "../../../components/events/extras/Extras";
import EventLanguages from "../../../components/events/languages/EventLanguage";
import MoreInformation from "../../../components/events/moreInformation/MoreInformation";
import Tickets from "../../../components/events/trip/tickets/Tickets";
import {
  cleanObjectOfVoidFields,
  transformObjectWithUrls,
} from "../../../components/shared/FormsValidator";
import { Loading } from "../../../components/shared/Loading";
import { toastMessageError, toastMessageSuccess } from "../../../components/shared/toastMessage";
import { sanitizeBoolean, sanitizeForSending } from "../../../constants/utils";
import useEventLogic from "../../../hooks/useEventLogic";
import { checkAvailableUrl, createEvent } from "../../../services/eventsServices";

const CreateTrip = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      name: t("TRAVELS"),
      link: "/trips",
    },
    {
      name: t("CREATE_TRAVEL"),
      link: "/create_trip",
    },
  ];
  const steps = [t("DETAILS"), t("MORE_INFO"), t("TICKETS"), t("EXTRAS")];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(createTrip)));
  const [formParamsErrors, setFormParamsErrors] = useState(
    JSON.parse(JSON.stringify(createTripErrors))
  );
  const [buttonClicked, setButtonClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { eventId } = useParams();
  useEventLogic(eventId, formData, setFormData, setFormParamsErrors, setLoading);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const setFormParams = (objectName, field, e, atribute = "value") => {
    let object = formData[objectName];
    object[field] = e.target[atribute];
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrors = (objectName, field, value) => {
    let object = formParamsErrors[objectName];
    object[field] = value;
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrorsTicketsAndExtras = (objectName, value) => {
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: value,
    }));
  };

  const setEventLanguageInfoError = (index, field, value) => {
    setFormParamsErrors((prev) => {
      const updatedEventLanguageInfos = [...prev.details.event_language_infos];
      if (updatedEventLanguageInfos[index]) {
        updatedEventLanguageInfos[index] = {
          ...updatedEventLanguageInfos[index],
          [field]: value,
        };
      } else {
        return prev;
      }
      return {
        ...prev,
        details: {
          ...prev.details,
          event_language_infos: updatedEventLanguageInfos,
        },
      };
    });
  };

  const setFormParamsValue = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    const { details, moreInformation, tickets, extras, id } = formData;
    //
    try {
      //
      const tripComplete = JSON.parse(JSON.stringify({ ...details, ...moreInformation }));
      // Check url
      const responseUrl = await checkAvailableUrl(tripComplete.seo_name);
      if (!responseUrl) {
        toastMessageError(t("URL_NOT_AVAILABLE"));
        return;
      }
      tripComplete.description =
        typeof tripComplete.description !== "string"
          ? JSON.stringify(tripComplete.description)
          : tripComplete.description;
      tripComplete.not_included_options = JSON.stringify(tripComplete.not_included_options);
      tripComplete.additional_info = JSON.stringify(tripComplete.additional_info);
      tripComplete.rating = tripComplete?.rating ? parseFloat(tripComplete.rating) : null;
      tripComplete.event_type = "trip";
      // OPTIONAL DATES
      tripComplete.start_date = tripComplete.start_date || null;
      tripComplete.start_date_time = tripComplete.start_date_time || null;
      tripComplete.end_date = tripComplete.end_date || null;
      tripComplete.end_date_time = tripComplete.end_date_time || null;
      tripComplete.publication_date = tripComplete.publication_date || null;
      tripComplete.publication_date_time = tripComplete.publication_date_time || null;
      tripComplete.end_of_publication_date = tripComplete.end_of_publication_date || null;
      tripComplete.end_of_publication_date_time = tripComplete.end_of_publication_date_time || null;
      tripComplete.start_sale_date = tripComplete.start_sale_date || null;
      tripComplete.start_sale_date_time = tripComplete.start_sale_date_time || null;
      tripComplete.end_sale_date = tripComplete.end_sale_date || null;
      tripComplete.end_sale_date_time = tripComplete.end_sale_date_time || null;
      tripComplete.second_payment_end_date = tripComplete.second_payment_end_date || null;
      tripComplete.second_payment_end_date_time = tripComplete.second_payment_end_date_time || null;
      tripComplete.second_payment_start_date = tripComplete.second_payment_start_date || null;
      tripComplete.second_payment_start_date_time =
        tripComplete.second_payment_start_date_time || null;
      //
      tripComplete.min_age = parseInt(tripComplete.min_age);
      tripComplete.latitude = parseFloat(tripComplete.latitude);
      tripComplete.longitude = parseFloat(tripComplete.longitude);
      tripComplete.limit_tickets_order = parseInt(tripComplete.limit_tickets_order);
      //Double payment
      tripComplete.has_double_payment =
        tripComplete.has_double_payment === 1 || tripComplete.has_double_payment === true;
      tripComplete.first_payment_percentage = parseFloat(tripComplete.first_payment_percentage);
      tripComplete.countries = parseInt(tripComplete.countries || 0);
      tripComplete.cities = parseInt(tripComplete.cities || 0);
      tripComplete.days = parseInt(tripComplete.days || 0);
      tripComplete.nights = parseInt(tripComplete.nights || 0);
      if (
        typeof tripComplete.slider_urls === "string" ||
        (typeof tripComplete.slider_urls === "object" && tripComplete.slider_urls.mime)
      ) {
        tripComplete.slider_urls = [tripComplete.slider_urls];
      }
      //
      tripComplete.total_capacity = 0;
      //Booleans
      tripComplete.passport_required =
        tripComplete.passport_required === 1 || tripComplete.passport_required === true;
      tripComplete.without_night =
        tripComplete.without_night === 1 || tripComplete.without_night === true;
      tripComplete.multi_date = tripComplete.multi_date === 1 || tripComplete.multi_date === true;
      //
      for (let i = 0; i < tickets.length; i++) {
        tripComplete.total_capacity += parseInt(tickets[i].initial_stock);
      }
      //
      const optionValues = [];
      for (let i = 0; i < tripComplete.included_options.length; i++) {
        optionValues.push(tripComplete.included_options[i].value);
      }
      tripComplete.included_options = JSON.stringify(optionValues);
      //
      for (let i = 0; i < tripComplete.itineraries.length; i++) {
        tripComplete.itineraries[i].position = i;
        tripComplete.itineraries[i].event_id = "";
        tripComplete.itineraries[i].id = "";
      }
      //
      for (let i = 0; i < tripComplete.blocks.length; i++) {
        if (!tripComplete.blocks[i].event_id) {
          tripComplete.blocks[i].event_id = id;
        }
        if (
          typeof tripComplete.blocks[i].images_urls === "string" &&
          tripComplete.blocks[i].images_urls !== ""
        ) {
          tripComplete.blocks[i].images_urls = [tripComplete.blocks[i].images_urls];
        } else {
          tripComplete.blocks[i].images_urls = [];
        }
        tripComplete.blocks[i].position = i;
        //tripComplete.blocks[i].description = JSON.stringify(tripComplete.blocks[i].description);
      }
      // LANGUAGES
      if (tripComplete.event_language_infos.length > 0) {
        for (let i = 0; i < tripComplete.event_language_infos.length; i++) {
          const language = tripComplete.event_language_infos[i];
          delete language.id;
          delete language.event_id;
          if (typeof language.description === "object") {
            language.description = JSON.stringify(language.description);
          }
          if (typeof language.not_included_options === "object") {
            language.not_included_options = JSON.stringify(language.not_included_options);
          }
          if (typeof language.additional_info === "object") {
            language.additional_info = JSON.stringify(language.additional_info);
          }
        }
      }
      //
      const tripToSend = await transformObjectWithUrls(tripComplete);
      //
      tripToSend.tickets = [];
      for (let i = 0; i < tickets.length; i++) {
        const ticketCopy = await JSON.parse(JSON.stringify(tickets[i]));
        const ticketToSend = await cleanObjectOfVoidFields(ticketCopy);
        ticketToSend.position = i;
        tripComplete.latitude =
          !isNaN(tripComplete.latitude) && tripComplete.latitude !== "" ? tripComplete.latitude : 0;
        tripComplete.longitude =
          !isNaN(tripComplete.longitude) && tripComplete.longitude !== ""
            ? tripComplete.longitude
            : 0;
        ticketToSend.description =
          typeof ticketToSend.description !== "string"
            ? JSON.stringify(ticketToSend.description)
            : ticketToSend.description;
        // TODO: Revisar como gestionamos estos atributos
        ticketToSend.initial_stock = parseInt(ticketToSend.initial_stock);
        ticketToSend.price = parseFloat(ticketToSend.price);
        ticketToSend.iva = parseInt(ticketToSend.iva);
        ticketToSend.only_international =
          ticketToSend.only_international === 1 || ticketToSend.only_international === true;
        ticketToSend.only_in_app = ticketToSend.only_in_app === 1;
        delete ticketToSend.stock;
        //
        if (ticketToSend.international_discount)
          ticketToSend.international_discount = parseFloat(ticketToSend.international_discount);
        if (ticketToSend.early_payment_discount)
          ticketToSend.early_payment_discount = parseFloat(ticketToSend.early_payment_discount);
        if (ticketToSend.promotional_code_discount)
          ticketToSend.promotional_code_discount = parseFloat(
            ticketToSend.promotional_code_discount
          );
        // PROMO CODE DISCOUNTS
        for (let k = 0; k < ticketToSend.promotional_code_discounts.length; k++) {
          ticketToSend.promotional_code_discounts[k].discount = parseFloat(
            ticketToSend.promotional_code_discounts[k].discount
          );
          ticketToSend.promotional_code_discounts[k].is_active = ticketToSend
            .promotional_code_discounts[k].is_active
            ? true
            : false;
          ticketToSend.promotional_code_discounts[k].code =
            ticketToSend.promotional_code_discounts[k].code || ".";
          delete ticketToSend.promotional_code_discounts[k].id;
        }
        //
        for (let j = 0; j < ticketToSend.group_discounts.length; j++) {
          ticketToSend.group_discounts[j].min_size = parseInt(
            ticketToSend.group_discounts[j].min_size
          );
          ticketToSend.group_discounts[j].max_size = parseInt(
            ticketToSend.group_discounts[j].max_size
          );
          ticketToSend.group_discounts[j].discount = parseFloat(
            ticketToSend.group_discounts[j].discount
          );
          ticketToSend.group_discounts[j].ticket_id = tickets[i].id;
          delete ticketToSend.group_discounts[j].id;
        }
        // Ticket Languages
        if (ticketToSend.ticket_language_infos.length > 0) {
          for (let j = 0; j < ticketToSend.ticket_language_infos.length; j++) {
            const language = ticketToSend.ticket_language_infos[j];
            if (typeof language.description === "object") {
              language.description = JSON.stringify(language.description);
            }
            delete language.id;
            delete language.ticket_id;
          }
        }
        //
        ticketToSend.only_international =
          ticketToSend.only_international === 1 || ticketToSend.only_international === true;
        //
        tripToSend.tickets.push(ticketToSend);
      }
      //

      //
      tripToSend.extras = [];
      for (let i = 0; i < extras.length; i++) {
        const extraCopy = await JSON.parse(JSON.stringify(extras[i]));
        const extraImages = await transformObjectWithUrls(extraCopy);
        const extraToSend = await cleanObjectOfVoidFields(extraImages);
        extraToSend.is_visible = sanitizeBoolean(extraToSend.is_visible);
        extraToSend.only_international = sanitizeBoolean(extraToSend.only_international);
        extraToSend.no_after_sales = sanitizeBoolean(extraToSend.no_after_sales);
        extraToSend.initial_stock = parseInt(extraToSend.initial_stock);
        extraToSend.price = parseFloat(extraToSend.price);
        extraToSend.stock_by_ticket_max = parseInt(extraToSend.stock_by_ticket_max);
        extraToSend.times_can_be_consumed = parseInt(extraToSend.times_can_be_consumed ?? 1);
        extraToSend.position = i;
        extraToSend.extra_template_id = extraToSend.extra_template_id ?? "";
        extraToSend.description = sanitizeForSending(extraToSend.description);
        extraToSend.description_design = sanitizeForSending(extraToSend.description_design);
        if (extraToSend.tickets_ids) {
          extraToSend.tickets_positions = [...extraToSend?.tickets_ids];
        } else {
          extraToSend.tickets_positions = [];
        }

        //EXTRA LANGUAGES
        if (extraToSend.extra_language_infos?.length > 0) {
          for (let j = 0; j < extraToSend.extra_language_infos.length; j++) {
            const language = extraToSend.extra_language_infos[j];
            language.description = sanitizeForSending(language.description);
            delete language.id;
            delete language.extra_id;
            delete language.extra_template_id;
          }
        }

        tripToSend.extras.push(extraToSend);
      }
      //
      await createEvent(tripToSend);
      //
      toastMessageSuccess(t("EDIT_SUCCESS"));
      setTimeout(() => {
        navigate("/trips");
      }, 1500);
    } catch (error) {
      toastMessageError(error.response.data.error || t("EDIT_ERROR"));
    } finally {
      setLoading(false);
    }
  };

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return activeStep === 3;
  };

  const formController = {
    formData,
    setFormData,
    formParams: setFormParams,
    next: handleNext,
    back: handleBack,
    validation: formParamsErrors,
    isFirstStep,
    isLastStep,
    finish: handleSubmit,
    setFormParamsNotEvent,
    setFormErrors,
    setFormErrorsTicketsAndExtras,
    setFormParamsValue,
    setFormParamsErrors,
    setEventLanguageInfoError,
  };

  const addComponent = (activeStep) => {
    return activeStep === 0 ? (
      <Details
        formController={formController}
        typeTrip={true}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
      />
    ) : activeStep === 1 ? (
      <MoreInformation
        formController={formController}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
      />
    ) : activeStep === 2 ? (
      <Tickets
        formController={formController}
        eventType={"trip"}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
      />
    ) : (
      <Extras
        formController={formController}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
      />
    );
  };

  if (loading) return <Loading />;

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_CREATE_TRIP")} />
      <Grid
        item
        xs={12}
        sm={10}
        lg={12}
        sx={{ mb: 5, display: "flex", flexDirection: "row", justifyContent: "initial" }}
      >
        <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <Grid item xs={12} sm={5} md={9}>
            {!isMobile ? (
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ color: "var(--secondary-color)" }}>
                    <ArrowBackIos fontSize="small" sx={{ color: "var(--secondary-color)" }} />
                  </Button>
                )}
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step
                        sx={{
                          "& .MuiStepLabel-root .Mui-disabled": { color: "var(--secondary-color)" },
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: "var(--secondary-color)",
                            opacity: "100%",
                            fontWeight: "bolder",
                          },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: "var(--secondary-color)",
                            opacity: "100%",
                            fontWeight: "bolder",
                          },
                          "& .MuiSvgIcon-root": { color: "var(--secondary-color)" },
                          "& .MuiSvgIcon-root, .Mui-disabled": { opacity: "80%" },
                          "& .MuiStepIcon-text ": { fill: "white" },
                          mx: 2,
                        }}
                        key={label}
                        {...stepProps}
                      >
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ color: "var(--secondary-color)" }}>
                    <ArrowBackIos fontSize="small" sx={{ color: "var(--secondary-color)" }} />
                  </Button>
                )}
                <Stepper connector activeStep={activeStep} sx={{ display: "block" }}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step
                        sx={{
                          "& .MuiStepLabel-root .Mui-disabled": { display: "none" },
                          "& .MuiStepLabel-root .Mui-completed": { display: "none" },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: "var(--secondary-color)",
                            opacity: "100%",
                            fontWeight: "bolder",
                          },
                          "& .MuiSvgIcon-root, .Mui-disabled": { opacity: "80%" },
                          "& .MuiStepIcon-text ": { fill: "white" },
                          mx: 4,
                        }}
                        key={label}
                        {...stepProps}
                      >
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep !== 3 && (
                  <Button sx={{ color: "var(--secondary-color)" }} onClick={handleNext}>
                    <ArrowForwardIosIcon />
                  </Button>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <EventLanguages formController={formController} />
      {addComponent(activeStep)}
    </>
  );
};

export default CreateTrip;
