import { sendPdfInBase64, transformObjectWithUrls } from "../components/shared/FormsValidator";
import {
  convertStringToObject,
  sanitizeBoolean,
  sanitizeFloat,
  sanitizeForSending,
} from "../constants/utils";

export const prepareHotelToSend = async (hotel, isForTrip) => {
  const newHotel = JSON.parse(JSON.stringify(hotel));
  const hotelToSend = await transformObjectWithUrls(newHotel);

  if (!hotelToSend.id) {
    hotelToSend.is_active = true;
  } else {
    hotelToSend.is_active = sanitizeBoolean(hotelToSend.is_active);
  }

  hotelToSend.social_media_urls = sanitizeForSending(hotelToSend.social_media_urls);
  hotelToSend.description = sanitizeForSending(hotelToSend.description);
  hotelToSend.stars = sanitizeFloat(hotelToSend.stars);

  hotelToSend.pdf_info = await sendPdfInBase64(hotelToSend.pdf_info);

  if (Array.isArray(hotelToSend.image_gallery_urls)) {
    hotelToSend.image_gallery_urls = hotelToSend.image_gallery_urls.join(";");
  }

  if (hotelToSend.hotel_language_infos && hotelToSend.hotel_language_infos.length > 0) {
    hotelToSend.hotel_language_infos = await Promise.all(
      hotelToSend.hotel_language_infos.map(async (hotelLanguageInfo) => {
        hotelLanguageInfo.description = sanitizeForSending(hotelLanguageInfo.description);
        hotelLanguageInfo.hotel_id = hotelToSend.id || null;
        hotelLanguageInfo.id = hotelLanguageInfo.id || null;
        return hotelLanguageInfo;
      })
    );
  }

  if (isForTrip) {
    if (hotelToSend.rooms && hotelToSend.rooms.length > 0) {
      hotelToSend.rooms = hotelToSend.rooms?.map((room) => {
        room.hotel_id = hotelToSend.id || null;
        room.id = room.id || null;
        room.description = sanitizeForSending(room.description);
        room.stock = sanitizeFloat(room.stock);
        room.price = sanitizeFloat(room.price);
        room.beds_amount = sanitizeFloat(room.beds_amount);
        if (room.hotel_room_language_infos && room.hotel_room_language_infos.length > 0) {
          room.hotel_room_language_infos = room.hotel_room_language_infos?.map(
            (hotelRoomLanguageInfo) => {
              hotelRoomLanguageInfo.description = sanitizeForSending(
                hotelRoomLanguageInfo.description
              );
              hotelRoomLanguageInfo.hotel_room_id = room.id || null;
              hotelRoomLanguageInfo.id = hotelRoomLanguageInfo.id || null;
              return hotelRoomLanguageInfo;
            }
          );
        }
        return room;
      });
    }
    if (hotelToSend.extra_options && hotelToSend.extra_options.length > 0) {
      hotelToSend.extra_options = hotelToSend.extra_options?.map((extra) => {
        extra.hotel_id = hotelToSend.id || null;
        extra.id = extra.id || null;
        extra.description = sanitizeForSending(extra.description);
        extra.price = sanitizeFloat(extra.price);
        extra.hotel_extra_option_language_infos = extra.hotel_extra_option_language_infos?.map(
          (extraLanguageInfo) => {
            extraLanguageInfo.description = sanitizeForSending(extraLanguageInfo.description);
            return extraLanguageInfo;
          }
        );
        return extra;
      });
    }
  }

  delete hotelToSend.pool;
  delete hotelToSend.wifi;
  delete hotelToSend.parking;
  delete hotelToSend.type_hotel_id;
  delete hotelToSend.created_at;
  if (!hotelToSend.id) {
    delete hotelToSend.id;
  }

  return hotelToSend;
};

export const prepareHotelToReceive = async (hotel) => {
  const hotelToReceive = JSON.parse(JSON.stringify(hotel));

  hotelToReceive.social_media_urls = convertStringToObject(hotelToReceive.social_media_urls);
  hotelToReceive.image_gallery_urls = hotelToReceive.image_gallery_urls?.split(";");

  return hotelToReceive;
};

export const ASSIGNED_TAB = 1;
export const TEMPLATES_TAB = 0;
