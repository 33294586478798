import { Box, Divider, Grid, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PAGINATION_STYLES } from "../../../../constants/styles";
import { ASSIGN_TRIP_TYPES } from "../../../../constants/variables";
import useQueryHotelTemplates from "../../../../hooks/queries/hotels/useQueryHotels";
import useDebounce from "../../../../hooks/useDebouncing";
import usePagination from "../../../../hooks/usePagination";
import { TEMPLATES_TAB } from "../../../../utils/hotels";
import SearchFilter from "../../../events/filters/filter/SearchFilter";
import CreateHotelDialog from "../../../hotels/createDialog/CreateHotelDialog";
import AssignComponent from "../../../shared/AssignComponent";
import AssignHotelDialog from "../../../shared/assignHotelDialog/AssignHotelDialog";

const AssignHosting = () => {
  const { t } = useTranslation();

  const { getValues, setValue } = useFormContext();
  const { page, changePage } = usePagination();
  const [search, setSearch] = useState("");
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [hotelId, setHotelId] = useState(null);
  const [selectedItinerary, setSelectedItinerary] = useState(null);
  const [openAssign, setOpenAssign] = useState(false);

  const tripInfo = getValues();

  const { data } = useQueryHotelTemplates(
    page,
    3,
    {
      search,
      isActive: true,
    },
    TEMPLATES_TAB,
    () => {}
  );
  const hotels = data?.data;
  const totalPages = Math.ceil((data?.total || 0) / 3);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleAssignHotel = async (hotelId, itineraryId) => {
    setHotelId(hotelId);
    setOpenAssign(true);
    setSelectedItinerary(itineraryId);
  };

  const addHotelToItinerary = (hotelId) => {
    const prevItineraries = getValues("itineraries");
    const newItineraries = prevItineraries.map((itinerary, index) => {
      if (itinerary.id?.includes(selectedItinerary) || selectedItinerary == index) {
        if (!itinerary.hotel_ids) {
          itinerary.hotel_ids = [hotelId];
        } else {
          itinerary.hotel_ids = [...itinerary?.hotel_ids, hotelId];
        }
      }
      return itinerary;
    });
    setValue("itineraries", newItineraries);
  };

  const handleAcceptCreate = () => {
    setOpenAssign(false);
  };

  const handleRemoveHotel = (itemId, itineraryId) => {
    const prevItineraries = getValues("itineraries");
    const newItineraries = prevItineraries.map((itinerary, index) => {
      if (itinerary.id?.includes(itineraryId) || itineraryId == index) {
        if (itinerary.hotel_ids) {
          itinerary.hotel_ids = itinerary?.hotel_ids.filter((hotelId) => hotelId !== itemId);
        }
      }
      return itinerary;
    });

    // Delete the extras with the deleted hotel
    const extras = getValues("extras") || [];
    const newExtras = extras.filter((extra) => extra.hotel_id !== itemId);

    setValue("extras", newExtras);
    setValue("itineraries", newItineraries);
  };

  const handleCreateHotel = () => {
    setOpenCreateDialog(true);
  };

  return (
    <>
      <Divider sx={{ width: "100%", borderColor: "var(--secondary-color)", mt: 2, mb: 2 }} />
      <Typography variant="body1" sx={{ fontWeight: 900, width: "100%" }}>
        {t("ASSIGN_HOSTING")}
      </Typography>
      <Typography variant="caption" sx={{ mb: 2 }}>
        {t("ASSIGN_HOSTING_DESCRIPTION")}
      </Typography>
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <Grid container spacing={2} sx={{ width: "45%", justifyContent: "start" }}>
          <Grid item xs={12} sm={6}>
            <SearchFilter onSearchChange={debounceSearch} />
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent="flex-end">
            <Pagination
              count={totalPages}
              page={page}
              onChange={changePage}
              sx={PAGINATION_STYLES}
            />
          </Grid>
        </Grid>
      </Box>
      <AssignComponent
        tripInfo={tripInfo}
        itemsToAssign={hotels}
        handleAssign={handleAssignHotel}
        handleRemove={handleRemoveHotel}
        type={ASSIGN_TRIP_TYPES.HOSTING}
        handleCreateNew={handleCreateHotel}
      />

      <AssignHotelDialog
        isOpen={openAssign}
        onClose={() => setOpenAssign(false)}
        onAccept={handleAcceptCreate}
        tripInfo={tripInfo}
        hotelTemplateId={hotelId}
        addHotelToItinerary={addHotelToItinerary}
        selectedItinerary={selectedItinerary}
      />

      <CreateHotelDialog isOpen={openCreateDialog} onClose={() => setOpenCreateDialog(false)} />
    </>
  );
};

export default AssignHosting;
