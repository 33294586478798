import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { EVENTS_TYPE } from "../../../constants/variables";
import InfoToolTip from "../../shared/InfoToolTip";

function CreateTripDialog({ isOpen, onClose }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [typeTrip, setTypeTrip] = useState(null);

  const handleAccept = () => {
    if (typeTrip === EVENTS_TYPE.TRIP) {
      navigate(ROUTES.CREATE_TRIP);
    } else if (typeTrip === EVENTS_TYPE.TRIP_COMPOSITE) {
      navigate(ROUTES.CREATE_TRIP_PLUS);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)", textAlign: "center" }}>
        {t("CREATE_TRIP")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: "black", textAlign: "center" }}>
          {t("CHOOSE_TYPE_TRIP")}
        </DialogContentText>
        <FormControl component="fieldset" sx={{ width: "100%", mt: 2 }}>
          <RadioGroup
            name="typeTrip"
            value={typeTrip}
            onChange={(e) => setTypeTrip(e.target.value)}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box>
              <FormControlLabel
                value={EVENTS_TYPE.TRIP}
                control={<Radio className="radio-oniria" />}
                label={t("SIMPLE_TRIP")}
              />
              <InfoToolTip text={t("SIMPLE_TRIP_INFO")} />
            </Box>
            <Box>
              <FormControlLabel
                value={EVENTS_TYPE.TRIP_COMPOSITE}
                control={<Radio className="radio-oniria" />}
                label={t("COMPOSITE_TRIP")}
              />
              <InfoToolTip text={t("COMPOSITE_TRIP_INFO")} />
            </Box>
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          gap: 2,
          padding: 2,
        }}
      >
        <Button
          type="button"
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="button"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          disabled={!typeTrip}
          onClick={handleAccept}
        >
          {t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateTripDialog;
