export const defaultBlog = {
  id: "", // UUID - Inicialmente vacío
  title: "",
  description: "",
  default_language: "ES",
  image_url: "", // UrlValueObject - Puede ser una URL vacía inicialmente
  slug: "", // StringValueObject - Campo obligatorio para el slug del post
  premise_id: "", // UUID - ID de la sede, obligatorio
  author_id: "", // UUID - ID del autor, obligatorio
  visible: "", // BooleanValueObject - Por defecto, el post no es visible
  status: "", // StringValueObject - Estado del post, por defecto vacío
  featured: false, // BooleanValueObject - Por defecto, el post no es destacado
  view_count: 0, // NumberValueObject - Por defecto, el contador de vistas es 0
  comments_count: 0, // NumberValueObject - Por defecto, el contador de comentarios es 0
  is_sponsored: false, // BooleanValueObject - Por defecto, el post no es patrocinado
  created_at: null, // TimestampValueObject - Fecha de creación, por defecto la fecha actual
  modified_at: null, // TimestampValueObject - Fecha de última modificación, por defecto la fecha actual
  translations: [],
  tag_ids: [], // Array de UUIDs - Inicialmente vacío
  domain_ids: [], // Array de UUIDs - Inicialmente vacío
  html: "", // StringValueObject - Inicialmente vacío
  design: "", // StringValueObject - Inicialmente vacío
};

export const defaultTag = {
  background_color: "#000000",
  color_icon: "#000000",
  color_text: "#000000",
  description: "",
  domains: [],
  font_weight: "",
  icon: "",
  name: "",
  slug: "",
  target: "",
  translations: [{ language: "EN", name: "", description: "" }],
  type: "",
  is_parent: false,
  parent_tags_ids: [],
};

export const defaultTagError = {
  name: "",
  slug: "",
  description: "",
  type: "",
  translations: [
    {
      name: "",
      description: "",
    },
  ],
  domains: "",
};
