import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AVAILABLE_LANGUAGES } from "../../../../../constants/variables";
import { DEFAULT_ITINERARY_LANGUAGE } from "../../../../../schemas/trips";

const ItineraryLanguageSelect = ({ index }) => {
  const { t } = useTranslation();
  const { watch, getValues, setValue } = useFormContext();

  const itineraryLanguages = watch(`itineraries[${index}].itinerary_language_infos`);

  const checkLanguage = (idiom) => {
    const translations = itineraryLanguages || [];
    return translations?.some((info) => info.language === idiom) || false;
  };

  const handleChangeCheckBox = (e, value) => {
    const isChecked = e.target.checked;
    const newLanguage = {
      ...DEFAULT_ITINERARY_LANGUAGE,
      language: value,
    };
    const prevLanguages = getValues(`itineraries[${index}].itinerary_language_infos`) || [];
    if (isChecked) {
      setValue(`itineraries[${index}].itinerary_language_infos`, [...prevLanguages, newLanguage]);
    } else {
      setValue(
        `itineraries[${index}].itinerary_language_infos`,
        prevLanguages?.filter((info) => info.language !== value)
      );
    }
  };

  return (
    <Grid item xs={12}>
      <Accordion
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid var(--grey-cancelled)",
          boxShadow: "none",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid container alignItems="center">
            <Typography>{t("LANGUAGES")}</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {AVAILABLE_LANGUAGES.filter(
            (lang) => lang.id !== getValues(`itineraries[${index}].language`)
          ).map((lang, index) => (
            <Grid item xs={12} key={index} container alignItems="center">
              <Checkbox
                className="checkbox-oniria"
                checked={checkLanguage(lang.id)}
                onChange={(e) => handleChangeCheckBox(e, lang.id)}
              />
              <Typography>{t(lang.name)}</Typography>
            </Grid>
          ))}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default ItineraryLanguageSelect;
