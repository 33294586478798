import { Grid, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { convertStringToObject } from "../../../constants/utils";
import { DEFAULT_LANGUAGE } from "../../../constants/variables";
import EmailsEditor from "../../emailEditor/EmailEditor";
import { handleSelectImageReactHook } from "../../shared/FormsValidator";
import ImageUpload from "../../shared/ImageUpload";
import LanguageTabs from "../../shared/languages/LanguageTabs";
import { focusColor } from "../../shared/textFieldStyle";
import ExtraLanguageSelect from "./ExtraLanguageSelect";

const TripExtraBasicInfo = ({ index }) => {
  const { t } = useTranslation();
  const emailEditorRefs = useRef([]);

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useFormContext();

  const extra = watch(`extras[${index}]`);

  const extraLanguages = watch(`extras[${index}].extra_language_infos`) || [];
  const defaultLanguage = watch(`extras[${index}].default_language`);
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const languageIndex = extraLanguages?.findIndex((info) => info.language === selectedLanguage);

  const handleChangeDescription = (data, name) => {
    const newExtras = getValues("extras");
    const updatedExtras = newExtras.map((extra, i) => {
      if (i === index) {
        return { ...extra, [name]: data };
      }
      return extra;
    });
    setValue("extras", updatedExtras);
  };

  const handleChangeLanguageDescription = (data, name) => {
    const newExtras = getValues("extras");
    const updatedExtras = newExtras.map((extra, i) => {
      if (i === index) {
        return {
          ...extra,
          extra_language_infos: extra.extra_language_infos.map((info, idx) =>
            idx === languageIndex ? { ...info, [name]: data } : info
          ),
        };
      }
      return extra;
    });
    setValue("extras", updatedExtras);
  };

  return (
    <>
      <ExtraLanguageSelect index={index} />
      <Grid item xs={12} mb={2}>
        <LanguageTabs
          languagesInfo={extraLanguages}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          defaultLanguage={defaultLanguage}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("NAME")}*</Typography>
        {selectedLanguage === defaultLanguage ? (
          <>
            <TextField
              {...register(`extras[${index}].name`)}
              error={!!errors?.extras?.[index]?.name}
              helperText={errors?.extras?.[index]?.name?.message}
              fullWidth
              sx={focusColor}
              placeholder={t("NAME")}
              required
            />
          </>
        ) : (
          <TextField
            {...register(`extras[${index}].extra_language_infos[${languageIndex}].name`)}
            error={!!errors?.extras?.[index]?.extra_language_infos?.[languageIndex]?.name}
            helperText={
              errors?.extras?.[index]?.extra_language_infos?.[languageIndex]?.name?.message
            }
            fullWidth
            sx={focusColor}
            placeholder={t("NAME")}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("SHORT_DESCRIPTION")}</Typography>
        {selectedLanguage === defaultLanguage ? (
          <>
            <TextField
              {...register(`extras[${index}].short_description`)}
              error={!!errors?.extras?.[index]?.short_description}
              helperText={errors?.extras?.[index]?.short_description?.message}
              fullWidth
              sx={focusColor}
              placeholder={t("SHORT_DESCRIPTION")}
            />
          </>
        ) : (
          <TextField
            {...register(
              `extras[${index}].extra_language_infos[${languageIndex}].short_description`
            )}
            error={
              !!errors?.extras?.[index]?.extra_language_infos?.[languageIndex]?.short_description
            }
            helperText={
              errors?.extras?.[index]?.extra_language_infos?.[languageIndex]?.short_description
                ?.message
            }
            fullWidth
            sx={focusColor}
            placeholder={t("SHORT_DESCRIPTION")}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("DESCRIPTION")}</Typography>
        {selectedLanguage === defaultLanguage ? (
          <>
            <EmailsEditor
              ref={(el) => el && (emailEditorRefs.current[0] = el)}
              initialDesign={convertStringToObject(extra?.description_design)}
              onExportHtml={(html) => handleChangeDescription(html, "description")}
              onDesignChange={(design) => handleChangeDescription(design, "description_design")}
              key={`editor-extra-event-${DEFAULT_LANGUAGE}-${index}`}
            />
          </>
        ) : (
          <EmailsEditor
            ref={(el) => el && (emailEditorRefs.current[0] = el)}
            initialDesign={convertStringToObject(
              extra?.extra_language_infos?.[languageIndex]?.description_design
            )}
            onExportHtml={(html) => handleChangeLanguageDescription(html, "description")}
            onDesignChange={(design) =>
              handleChangeLanguageDescription(design, "description_design")
            }
            key={`editor-extra-event-${DEFAULT_LANGUAGE}-${index}`}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("IMAGE")}</Typography>
        <ImageUpload
          onImageSelect={(e) => handleSelectImageReactHook(e, `extras[${index}].images`, setValue)}
          url={watch(`extras[${index}].images`)}
        />
      </Grid>
    </>
  );
};

export default TripExtraBasicInfo;
