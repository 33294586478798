import { DeleteOutline, DragIndicator, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { GREY_BUTTON_STYLE } from "../../../constants/styles";
import CustomDialog from "../../shared/CustomDialog";
import { handleSelectImageReactHook } from "../../shared/FormsValidator";
import ImageUpload from "../../shared/ImageUpload";
import InfoToolTip from "../../shared/InfoToolTip";
import LanguageTabs from "../../shared/languages/LanguageTabs";
import { focusColor } from "../../shared/textFieldStyle";
import TextEditor from "../../textEditor/TextEditor";

const TripBlocks = () => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    register,
    formState: { errors },
    getValues,
  } = useFormContext();

  const [openDelete, setOpenDelete] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const defaultLanguage = watch("default_language");
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const blocks = watch("blocks") || [];
  const eventLanguages = watch("event_language_infos") || [];

  const handleSetBlocksPosition = ({ destination, source }) => {
    if (!destination) return;
    const newBlocks = [...blocks];
    const [removed] = newBlocks.splice(source.index, 1);
    newBlocks.splice(destination.index, 0, removed);

    const updatedBlocks = newBlocks.map((block, index) => ({
      ...block,
      position: index,
    }));

    setValue("blocks", updatedBlocks);
  };

  const handleOpenDelete = (e, index) => {
    e.stopPropagation();
    setSelectedIndex(index);
    setOpenDelete(true);
  };

  const handleRemoveBlock = () => {
    const updatedBlocks = blocks.filter((_, i) => i !== selectedIndex);
    setValue("blocks", updatedBlocks);
    setOpenDelete(false); // Cierra el diálogo al eliminar
  };

  const handleAddBlock = () => {
    const prev = getValues("blocks") || [];
    const newBlock = {
      title: "",
      subtitle: "",
      description: "",
      images_urls: [],
      position: "",
      language: selectedLanguage,
    };
    setValue("blocks", [...prev, newBlock]);
  };

  return (
    <Grid item xs={12}>
      <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
        {t("MORE_INFO_BLOCKS")}
        <InfoToolTip text={t("MORE_INFO_BLOCKS_INFO")} />
      </InputLabel>
      <LanguageTabs
        languagesInfo={eventLanguages}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        defaultLanguage={defaultLanguage}
      />
      <DragDropContext onDragEnd={handleSetBlocksPosition}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "100%",
              }}
            >
              {blocks
                ?.filter((block) => block.language === selectedLanguage)
                .map((block) => {
                  const originalIndex = blocks?.findIndex((b) => b === block);
                  return (
                    <Draggable
                      key={`draggable-block-${originalIndex}`}
                      draggableId={`draggable-block-${originalIndex}`}
                      index={originalIndex}
                    >
                      {(provided, snapshot) => (
                        <Accordion
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            backgroundColor: snapshot.isDragging ? "var(--grey-cancelled)" : "",
                            display: "flex",
                            flexDirection: "column",
                            border: "1px solid var(--grey-cancelled)",
                            boxShadow: "none",
                          }}
                        >
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid container flexDirection="row" justifyContent="space-between">
                              <Grid item xs={6} container alignItems="center">
                                <DragIndicator sx={{ marginRight: 2 }} />
                                <Typography>{block.title || t("TITLE")}</Typography>
                              </Grid>
                              <Grid item>
                                <IconButton
                                  onClick={(e) => handleOpenDelete(e, originalIndex)}
                                  aria-label={t("DELETE_BLOCK")}
                                  className="oniria-icon-btn-delete"
                                >
                                  <DeleteOutline />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <TextField
                                  {...register(`blocks[${originalIndex}].title`)}
                                  error={!!errors?.blocks?.[originalIndex]?.title}
                                  helperText={errors?.blocks?.[originalIndex]?.title?.message}
                                  fullWidth
                                  sx={focusColor}
                                  placeholder={t("TITLE")}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  {...register(`blocks[${originalIndex}].subtitle`)}
                                  error={!!errors?.blocks?.[originalIndex]?.subtitle}
                                  helperText={errors?.blocks?.[originalIndex]?.subtitle?.message}
                                  fullWidth
                                  sx={focusColor}
                                  placeholder={t("SUBTITLE")}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel sx={{ fontSize: "18px", color: "black", mt: 1 }}>
                                  {t("TRIP_IMAGES")}
                                </InputLabel>
                                <ImageUpload
                                  url={block.images_urls}
                                  selectedImagesInForm={block.images_urls}
                                  onImageSelect={(e) =>
                                    handleSelectImageReactHook(
                                      e,
                                      `blocks[${originalIndex}].images_urls`,
                                      setValue
                                    )
                                  }
                                  multi
                                />
                              </Grid>
                              <Grid item xs={12} sx={{ mt: 2 }}>
                                <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                                  {t("DESCRIPTION")}
                                </InputLabel>
                                <TextEditor
                                  data={block.description}
                                  setData={(data) =>
                                    setValue(`blocks[${originalIndex}].description`, data)
                                  }
                                  id={`block-${originalIndex}-description`}
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Button
        fullWidth
        variant="outlined"
        size="large"
        sx={{
          ...GREY_BUTTON_STYLE,
          mt: 2,
        }}
        onClick={handleAddBlock}
      >
        {t("ADD_BLOCK")}
      </Button>

      <CustomDialog
        isOpen={openDelete}
        onClose={() => setOpenDelete(false)}
        title={t("CANCEL_BLOCK")}
        content={t("CONFIRM_CANCEL_BLOCK")}
        onAccept={handleRemoveBlock}
      />
    </Grid>
  );
};

export default TripBlocks;
