import { transformObjectWithUrls } from "../components/shared/FormsValidator";

export const prepareTourLeaderToSend = async (data) => {
  const newBody = JSON.parse(JSON.stringify(data));
  const bodyToSend = await transformObjectWithUrls(newBody);

  if (!bodyToSend.id) {
    bodyToSend.is_active = true;
  }

  if (bodyToSend.languages && bodyToSend.languages.length > 0) {
    bodyToSend.languages = bodyToSend.languages.join(";");
  }

  return bodyToSend;
};

export const prepareTourLeaderToReceive = (data) => {
  const newBody = JSON.parse(JSON.stringify(data));

  newBody.languages = newBody.languages?.split(";") || [];
  newBody.born_at = newBody.born_at ? new Date(newBody.born_at).toISOString().split("T")[0] : "";
  const tourLeader = newBody;

  return tourLeader;
};
