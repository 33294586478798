import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DEFAULT_EVENT_LANGUAGE } from "../../../constants/variables";
import { focusColor } from "../../shared/textFieldStyle";

const ExtraShortDescription = ({
  checkExtraField,
  index,
  extras,
  errors,
  sendButtonClicked,
  isDisabled,
  extra_language_infos,
  handleChangeLanguageParam,
  selectedLanguage,
  defaultLanguage = DEFAULT_EVENT_LANGUAGE,
}) => {
  const { t } = useTranslation();
  const extra = extras[index];
  const error = errors[index];

  const selectedLanguageIndex = extra_language_infos?.findIndex(
    (language) => language.language === selectedLanguage
  );

  return (
    <Grid item xs={12}>
      {selectedLanguage === defaultLanguage ? (
        <TextField
          onChange={(e) =>
            checkExtraField(
              "short_description",
              e,
              /^(?!.*[|\\#·$~%¬=^[\]*{}_-]).{0,250}$/,
              t("MAX_250"),
              false,
              index
            )
          }
          margin="normal"
          fullWidth
          value={extra.short_description}
          id="short_description"
          name="short_description"
          autoComplete="short_description"
          autoFocus
          label={t("SHORT_DESCRIPTION_250")}
          error={
            (error.short_description !== "" && error.short_description !== "empty") ||
            (error.short_description === "empty" && sendButtonClicked)
          }
          helperText={error.short_description !== "empty" ? error.short_description : ""}
          disabled={isDisabled}
          sx={focusColor}
        />
      ) : (
        <TextField
          onChange={(e) =>
            handleChangeLanguageParam(e.target.value, "short_description", selectedLanguageIndex)
          }
          margin="normal"
          fullWidth
          value={extra_language_infos[selectedLanguageIndex]?.short_description}
          id="short_description"
          name="short_description"
          autoComplete="short_description"
          autoFocus
          label={t("SHORT_DESCRIPTION_250")}
          disabled={isDisabled}
          sx={focusColor}
        />
      )}
    </Grid>
  );
};

export default ExtraShortDescription;
