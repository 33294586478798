import { Grid, Tab, Tabs } from "@mui/material";
import { MUI_SELECT_PRIM_COLOR } from "../../../constants/styles";
import { DEFAULT_EVENT_LANGUAGE } from "../../../constants/variables";

const LanguageTabs = ({
  languagesInfo,
  selectedLanguage,
  setSelectedLanguage,
  defaultLanguage = DEFAULT_EVENT_LANGUAGE,
}) => {
  const handleChange = (_, newValue) => {
    setSelectedLanguage(newValue);
  };

  return languagesInfo && languagesInfo.length > 0 ? (
    <Grid container justifyContent="center" height="50px">
      <Tabs
        value={selectedLanguage}
        onChange={handleChange}
        TabIndicatorProps={{
          style: { backgroundColor: "var(--secondary-color)" },
        }}
        sx={{ mb: 2, color: "var(--primary-color)" }}
      >
        <Tab
          label={defaultLanguage}
          value={defaultLanguage}
          sx={[{ padding: 0 }, MUI_SELECT_PRIM_COLOR]}
        />
        {languagesInfo.map((language) => (
          <Tab
            key={language.language}
            label={language.language}
            value={language.language}
            sx={[{ padding: 0 }, MUI_SELECT_PRIM_COLOR]}
          />
        ))}
      </Tabs>
    </Grid>
  ) : null;
};

export default LanguageTabs;
