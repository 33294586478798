import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import HotelForm from "../HotelForm";

function CreateHotelDialog({ isOpen, onClose }) {
  const handleGoBack = () => {
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <Grid container sx={{ padding: 2 }}>
        <HotelForm goBack={handleGoBack} />
      </Grid>
    </Dialog>
  );
}

export default CreateHotelDialog;
