import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBack } from "@mui/icons-material";
import { Button, Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE, TRANSPORT_TYPES } from "../../constants/variables";
import useQueryTransportDetails from "../../hooks/queries/transports/useQueryTransportDetails";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import { defaultTransportLanguageInfo, transportSchema } from "../../schemas/transports";
import {
  assignDomainToTransport,
  createTransport,
  editTransport,
} from "../../services/transportServices";
import { prepareTransportToReceive, prepareTransportToSend } from "../../utils/transports";
import LanguageSelect from "../shared/languages/LanguageSelect";
import LanguageTabs from "../shared/languages/LanguageTabs";
import { Loading } from "../shared/Loading";
import SelectHookForm from "../shared/SelectHookForm";
import { focusColor } from "../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";
import TextEditor from "../textEditor/TextEditor";
import TransportFormTrips from "./TransportFormTrips";

const TransportForm = ({ isTrip, goBack }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [languageTab, setLanguageTab] = useState(DEFAULT_LANGUAGE);

  const { data: premises = [] } = useQueryPremisesList();
  const { data: domains = [] } = useQueryDomains();

  const formMethods = useForm({
    resolver: zodResolver(transportSchema),
    mode: "onBlur",
    defaultValues: {
      default_language: DEFAULT_LANGUAGE,
    },
  });

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
  } = formMethods;

  const handleSetFormData = (data) => {
    const newData = prepareTransportToReceive(data);
    reset(newData);
  };

  const { isLoading, isRefetching } = useQueryTransportDetails(id, handleSetFormData, isTrip);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setLoadingSubmit(true);
    try {
      const bodyToSend = await prepareTransportToSend(data);
      if (id) {
        // EDIT
        await editTransport(id, bodyToSend, isTrip);
        if (bodyToSend.domains_ids && bodyToSend.domains_ids.length > 0) {
          await assignDomainToTransport(id, bodyToSend.domains_ids, isTrip);
        }
      } else {
        // NEW
        const response = await createTransport(bodyToSend, isTrip);
        if (response.id && bodyToSend.domains_ids && bodyToSend.domains_ids.length > 0) {
          await assignDomainToTransport(response.id, bodyToSend.domains_ids, isTrip);
        }
      }
      setTimeout(() => {
        handleGoBack();
      }, 1500);
      toastMessageSuccess(t("CREATED_SUCCESSFULLY"));
    } catch (e) {
      toastMessageError(e?.response?.data?.error || t("ERROR"));
    } finally {
      setLoadingSubmit(false);
    }
  };

  const onError = (errors) => {
    console.log("Errores formulario transporte", errors);
  };

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigate(ROUTES.TRANSPORTS);
    }
  };

  const handleChangeDescription = (data) => {
    setValue("description", data);
  };

  const handleChangeCheckbox = (isChecked, value) => {
    const newLanguage = JSON.parse(JSON.stringify(defaultTransportLanguageInfo));
    const languages = getValues("transport_language_infos") || [];
    if (isChecked) {
      newLanguage.language = value;
      setValue("transport_language_infos", [...languages, newLanguage]);
    } else {
      setValue(
        "transport_language_infos",
        languages.filter((language) => language?.language !== value)
      );
    }
  };

  const defaultLanguage = watch("default_language");
  const description = watch("description");
  const transportTranslations = watch("transport_language_infos") || [];
  const translationIndex = transportTranslations.findIndex(
    (transport) => transport.language === languageTab
  );
  const handleChangeLanguageDescription = (data) => {
    setValue(`transport_language_infos[${translationIndex}].description`, data);
  };

  useEffect(() => {
    if (defaultLanguage) {
      setLanguageTab(defaultLanguage);
    }
  }, [defaultLanguage]);

  return (
    <Grid mt={7} mb={5}>
      <IconButton onClick={handleGoBack}>
        <ArrowBack />
      </IconButton>
      <Typography variant="h5" mb={5} mt={3}>
        {!!id ? t("EDIT") : t("CREATE")} {t("TRANSPORT")}
      </Typography>
      {isLoading || isRefetching ? (
        <Loading />
      ) : (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectHookForm
                  name="default_language"
                  control={control}
                  label={t("DEFAULT_LANGUAGE")}
                  required={true}
                  error={errors?.default_language}
                  defaultValue={""}
                  options={AVAILABLE_LANGUAGES.map((lang) => (
                    <MenuItem key={lang.id} value={lang.id}>
                      {t(lang.name)}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12}>
                <LanguageSelect
                  translatedFields={watch("transport_language_infos")}
                  handleChangeCheckbox={handleChangeCheckbox}
                  defaultLanguage={defaultLanguage}
                />
                {transportTranslations.length > 0 ? (
                  <LanguageTabs
                    languagesInfo={transportTranslations}
                    selectedLanguage={languageTab}
                    setSelectedLanguage={setLanguageTab}
                    defaultLanguage={defaultLanguage}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("SKU")}*</Typography>
                <TextField
                  placeholder={t("SKU")}
                  {...register("sku")}
                  error={!!errors.sku}
                  helperText={errors.sku?.message}
                  fullWidth
                  sx={focusColor}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("TRANSPORT_NAME")}*</Typography>
                {languageTab === defaultLanguage ? (
                  <TextField
                    {...register("name")}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    fullWidth
                    sx={focusColor}
                    placeholder={t("NAME")}
                    required
                  />
                ) : (
                  <>
                    <TextField
                      {...register(`transport_language_infos[${translationIndex}].name`)}
                      error={!!errors.transport_language_infos?.[translationIndex]?.name}
                      helperText={
                        errors.transport_language_infos?.[translationIndex]?.name?.message
                      }
                      fullWidth
                      sx={focusColor}
                      placeholder={t("NAME")}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography mb={1}>{t("TRANSPORT_TYPE")}*</Typography>
                <SelectHookForm
                  name={"type"}
                  control={control}
                  label={t("TRANSPORT_TYPE")}
                  error={errors.type}
                  options={TRANSPORT_TYPES.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {t(type.name)}
                    </MenuItem>
                  ))}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("NUMBER_OF_SEATS")}*</Typography>
                <TextField
                  placeholder={t("NUMBER_OF_SEATS")}
                  {...register("places")}
                  error={!!errors.places}
                  helperText={errors.places?.message}
                  fullWidth
                  sx={focusColor}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography mb={1}>{t("DOMAINS")}</Typography>
                <SelectHookForm
                  name={"domains_ids"}
                  control={control}
                  label={t("DOMAINS")}
                  error={errors.domains_ids}
                  multiple
                  defaultValue={[]}
                  options={domains.map((domain) => (
                    <MenuItem key={domain.domain_id} value={domain.domain_id}>
                      {domain.domain_name}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography mb={1}>{t("PREMISE")}*</Typography>
                <SelectHookForm
                  name={"owner_premise_id"}
                  control={control}
                  label={t("PREMISE")}
                  error={errors.owner_premise_id}
                  options={premises.map((premise) => (
                    <MenuItem key={premise.id} value={premise.id}>
                      {premise.name}
                    </MenuItem>
                  ))}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("DESCRIPTION")}</Typography>
                {languageTab === defaultLanguage ? (
                  <TextEditor
                    data={description}
                    id={`transport_description_${id}`}
                    setData={handleChangeDescription}
                  />
                ) : (
                  <>
                    <TextEditor
                      data={transportTranslations[translationIndex]?.description}
                      id={`transport_description_${id}_${translationIndex}`}
                      setData={handleChangeLanguageDescription}
                    />
                  </>
                )}
              </Grid>
              {isTrip && <TransportFormTrips />}

              <Grid item xs={12} container justifyContent="center" gap={2}>
                <Button
                  onClick={handleGoBack}
                  className="oniria-btn-cancel"
                  variant="contained"
                  size="medium"
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                >
                  {t("CANCEL")}
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  className="oniria-btn"
                  disabled={loadingSubmit}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                  onClick={(e) => onSubmit(getValues(), e)}
                >
                  {loadingSubmit ? t("SAVING") : t("SAVE")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      )}
    </Grid>
  );
};

export default TransportForm;
