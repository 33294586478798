import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getParentChildEvents } from "../../../services/eventsServices";

const useQueryChildEvents = (eventId, showEvents) => {
  return useQuery([RQ_KEY.CHILD_EVENTS, eventId, showEvents], () => getParentChildEvents(eventId), {
    refetchOnWindowFocus: false,
    enabled: !!eventId && showEvents,
  });
};

export default useQueryChildEvents;
