import { Check, Close, CurrencyExchange, PaidOutlined, Visibility } from "@mui/icons-material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SearchIcon from "@mui/icons-material/Search";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Button,
  ButtonGroup,
  Chip,
  Grid,
  IconButton,
  Pagination,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import "jspdf-autotable";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getOrderAmount,
  getOrderCommission,
  getOrderPremiseCommission,
} from "../../constants/orders";
import { PAGINATION_STYLES } from "../../constants/styles";
import { formatCurrency, formatDate } from "../../constants/utils";
import { EVENT_TABS, EVENTS_TYPE } from "../../constants/variables";
import useQueryOrdersEvent from "../../hooks/queries/events/useQueryOrdersEvent";
import useDebounce from "../../hooks/useDebouncing";
import useDownloadOrders from "../../hooks/useDownloadOrders";
import usePagination from "../../hooks/usePagination";
import useUserRoles from "../../hooks/useUserRoles";
import RefundDialog from "../refunds/RefundDialog";
import { Loading } from "../shared/Loading";
import OrderButtons from "../shared/OrderButtons";
import SelectPerPage from "../shared/SelectPerPage";
import { focusColor } from "../shared/textFieldStyle";
import CompletePaymentDialog from "./CompletePaymentDialog";
import SelectFilter from "./filters/filter/SelectFilter";
import OrderDetail from "./OrderDetail";
import { renderExtrasCellOrders, renderTicketCell } from "./orderDetails/IndividualCells";

const Orders = (props) => {
  const { t } = useTranslation();
  const eventId = props.eventId;
  const [search, setSearch] = useState("");
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showDetailOrder, setShowDetailOrder] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);
  const [order, setOrder] = useState({ asc: [], desc: ["payed_at"] });
  const [selectedTicket, setSelectedTicket] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [enabledDownload, setEnabledDownload] = useState(false);
  const [openCompletePayment, setOpenCompletePayment] = useState(false);
  const { page, perPage, changePage, changePerPage } = usePagination();
  const userInfo = useSelector((state) => state.userInfo);

  const { isSuperAdmin, isManager, isAdmin } = useUserRoles();

  const {
    selectedTab: selectedEventTab,
    domains: userDomains = [],
    selectedDomain: stateSelectedDomain,
  } = useSelector((state) => state.b2bInfo);

  const selectedDomain = useMemo(() => {
    if (selectedEventTab === EVENT_TABS.MY_EVENTS) {
      return stateSelectedDomain;
    }
    return stateSelectedDomain || userDomains.join(";");
  }, [selectedEventTab, stateSelectedDomain, userDomains]);

  //Calcular precio para la devolución
  const maxPrice = () => {
    if (selectedOrder) {
      const amount = parseFloat(selectedOrder.amount);
      const firstPaymentAmount = parseFloat(selectedOrder.first_payment_amount || 0);
      const extras =
        selectedOrder?.only_extra_orders?.reduce(
          (acc, item) => acc + parseFloat(item.amount || 0),
          0
        ) || 0;
      const premiseCommission = parseFloat(selectedOrder?.premise_commission || 0);
      if (isNaN(firstPaymentAmount)) return amount + extras;
      return selectedOrder.payed_at
        ? amount + firstPaymentAmount + extras + premiseCommission
        : selectedOrder.first_payment_amount;
    }
  };

  const handleQuerySuccess = (data) => {
    setTotalOrders(data?.amount);
    const uniqOrders = data?.data?.reduce(
      (acc, order) => {
        if (!acc.seen[order.order_id]) {
          acc.seen[order.order_id] = true;
          acc.result.push(order);
        }
        return acc;
      },
      { seen: {}, result: [] }
    ).result;
    setOrders(uniqOrders);
  };

  const { data, isLoading, refetch } = useQueryOrdersEvent(
    eventId,
    selectedDomain,
    page,
    perPage,
    search,
    order,
    selectedTicket,
    selectedStatus,
    true,
    handleQuerySuccess
  );

  const handleSearchChange = (event) => {
    changePage(null, 1);
    setSearch(event.target.value);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleRowClick = (order) => {
    setSelectedOrder(order);
    setShowDetailOrder(true);
  };

  //download options
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenPopover = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setEnabledDownload(true);
  };
  const handleClosePopover = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  //Refunds
  const handleOpenRefund = (order) => {
    setSelectedOrder(order);
    setOpenRefund(true);
  };
  const handleCloseRefund = () => {
    setOpenRefund(false);
    refetch();
  };

  const isIncompleteClientInfo = (order) => {
    if (order.type !== EVENTS_TYPE.TRIP) return false;
    return order.tickets.some((ticket) => {
      return ticket.client_email === null;
    });
  };

  const hasPremiseCommission = () => {
    return orders.some((order) => !!order?.premise_commission);
  };

  const totalPages = Math.ceil(Number(totalOrders ?? 0) / Number(perPage ?? 10));

  //Order
  const handleOrder = (id, type) => {
    const orderCopy = { ...order };
    const oppositeType = type === "asc" ? "desc" : "asc";
    if (orderCopy[type]?.includes(id)) {
      orderCopy[type] = orderCopy[type]?.filter((item) => item !== id);
    } else {
      orderCopy[type].push(id);
      orderCopy[oppositeType] = orderCopy[oppositeType]?.filter((item) => item !== id);
    }
    setOrder(orderCopy);
  };

  const commonCellStyle = (isExtraOrder) => ({
    borderBottom: isExtraOrder ? "none" : "",
    paddingBottom: isExtraOrder && 0,
  });

  // DOWNLOAD ORDERS
  const { generatePDF, tableHeadersCsv, tableDataCsv, isSuccess } = useDownloadOrders(
    eventId,
    selectedDomain,
    search,
    order,
    selectedTicket,
    selectedStatus,
    enabledDownload
  );

  const ticketOptions = [{ id: "all", name: t("ALL_TICKETS") }, ...(data?.tickets || [])];
  const statusOptions = [
    { id: "all", name: t("ORDER_STATUS") },
    { id: "payed", name: t("ORDER_PAYED") },
    { id: "refund", name: t("ORDER_STATUS_REFUNDED") },
    { id: "parcial", name: t("ORDER_PARCIAL") },
  ];

  const handleOpenCompletePayment = (e, order) => {
    e.stopPropagation();
    setSelectedOrder(order);
    setOpenCompletePayment(true);
  };

  useEffect(() => {
    if (!showDetailOrder) {
      refetch();
    }
  }, [showDetailOrder]);

  return (
    <>
      <Grid
        container
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 3 }}
      >
        <Grid item xs={11} sx={{ display: "flex", flexDirection: "column" }}>
          {showDetailOrder ? (
            <OrderDetail
              order={selectedOrder.order_id}
              transactionId={selectedOrder.transaction_id}
              firstPaymentTransactionId={selectedOrder.first_payment_transaction_id}
              onBack={() => setShowDetailOrder(false)}
            />
          ) : (
            <>
              <Grid container sx={{ display: "flex", flexDirection: "row-reverse", mb: 5, mt: 2 }}>
                {(isAdmin || isSuperAdmin || isManager) && (
                  <Grid item xs={9} md={2.1} mb={2}>
                    <Button
                      className="oniria-btn"
                      variant="contained"
                      size="medium"
                      sx={{
                        fontSize: "12px",
                        borderRadius: 3,
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                      onClick={handleOpenPopover}
                    >
                      <CloudDownloadIcon />
                      {t("DOWNLOAD_LIST")}
                    </Button>
                    <Popover
                      open={openPopover}
                      anchorEl={anchorEl}
                      onClose={handleClosePopover}
                      id={popoverId}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <ButtonGroup
                        variant="contained"
                        orientation="vertical"
                        aria-label="vertical button group"
                      >
                        <Button
                          disableElevation
                          fullWidth
                          variant="contained"
                          className="popover-btn"
                          aria-label="download csv"
                          disabled={!isSuccess}
                        >
                          <CSVLink
                            data={tableDataCsv}
                            filename={"orders.csv"}
                            target="_blank"
                            separator={";"}
                            headers={tableHeadersCsv}
                            className="csv-link"
                            style={{ textAlign: "center" }}
                          >
                            {t("CSV_FORMAT")}
                            <p style={{ fontSize: "10px", textTransform: "lowercase" }}>
                              ({t("INFO_FULL")})
                            </p>
                          </CSVLink>
                        </Button>
                        <Button
                          disableElevation
                          fullWidth
                          variant="contained"
                          className="popover-btn"
                          aria-label="download pdf"
                          onClick={generatePDF}
                          disabled={!isSuccess}
                          style={{ textAlign: "center", display: "flex", flexDirection: "column" }}
                        >
                          {t("PDF_FORMAT")}
                          <p style={{ fontSize: "10px", textTransform: "lowercase" }}>
                            ({t("INFO_BASE")})
                          </p>
                        </Button>
                      </ButtonGroup>
                    </Popover>
                  </Grid>
                )}
              </Grid>
              <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                {/* TICKET FILTER */}
                <Grid item>
                  <SelectFilter
                    value={selectedTicket}
                    onChange={setSelectedTicket}
                    options={ticketOptions}
                  />
                </Grid>
                {/* STATUS */}
                <Grid item>
                  <SelectFilter
                    value={selectedStatus}
                    onChange={setSelectedStatus}
                    options={statusOptions}
                  />
                </Grid>
                {/* SEARCH */}
                <Grid item xs={12} md={4}>
                  <SearchIcon
                    sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }}
                  />
                  <TextField
                    label={t("SEARCH")}
                    size="small"
                    onChange={(event) => debounceSearch(event)}
                    sx={[focusColor, { width: "75%" }]}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container marginBottom={2}>
                <Grid item>
                  {t("TOTAL_ORDERS")}: {totalOrders}
                </Grid>
                <Grid item container justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <SelectPerPage
                      text={t("ORDERS")}
                      change={changePerPage}
                      value={perPage}
                      total={totalOrders}
                    />
                  </Grid>
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={changePage}
                    sx={PAGINATION_STYLES}
                  />
                </Grid>
              </Grid>
              {isLoading ? (
                <Loading />
              ) : orders.length === 0 ? (
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="h6">{t("NO_ORDERS_FOUND")}</Typography>
                </Grid>
              ) : (
                <Grid item xs={11} md={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow
                          sx={{
                            borderBottom: "2px solid var(--secondary-color)",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <TableCell>
                            <OrderButtons
                              columnName={t("NAME")}
                              id="buyer_first_name"
                              order={order}
                              handleClickAsc={handleOrder}
                              handleClickDesc={handleOrder}
                            />
                          </TableCell>
                          <TableCell>
                            <OrderButtons
                              columnName={t("SURNAME")}
                              id="buyer_last_name"
                              order={order}
                              handleClickAsc={handleOrder}
                              handleClickDesc={handleOrder}
                            />
                          </TableCell>
                          <TableCell>{t("TRANSACTION_ID")}</TableCell>
                          <TableCell>{t("TICKETS")}</TableCell>
                          <TableCell>
                            <OrderButtons
                              columnName={t("PAYMENT_DATE")}
                              id="payed_at"
                              order={order}
                              handleClickAsc={handleOrder}
                              handleClickDesc={handleOrder}
                            />
                          </TableCell>
                          <TableCell>{t("DOMAIN")}</TableCell>
                          <TableCell>
                            <OrderButtons
                              columnName={t("AMOUNT")}
                              id="amount"
                              order={order}
                              handleClickAsc={handleOrder}
                              handleClickDesc={handleOrder}
                            />
                          </TableCell>
                          {hasPremiseCommission() && (
                            <TableCell>{t("PREMISE_COMMISSIONS")}</TableCell>
                          )}
                          <TableCell>{t("COMMISSION_SERVICE")}</TableCell>
                          <TableCell>{t("STATUS")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orders?.map((order, index) => (
                          <Fragment key={index}>
                            <TableRow>
                              <TableCell sx={commonCellStyle(order.only_extra_orders)}>
                                {order.buyer_first_name || order.client_name}
                              </TableCell>
                              <TableCell sx={commonCellStyle(order.only_extra_orders)}>
                                {order.buyer_last_name || order.client_surname}
                              </TableCell>
                              <TableCell sx={commonCellStyle(order.only_extra_orders)}>
                                {order.transaction_id || order.first_payment_transaction_id}
                              </TableCell>
                              {renderTicketCell(order.tickets, order.only_extra_orders)}
                              <TableCell sx={commonCellStyle(order.only_extra_orders)}>
                                {order.payed_at
                                  ? formatDate(order.payed_at)
                                  : order.first_payment_payed_at
                                  ? formatDate(order.first_payment_payed_at)
                                  : ""}
                              </TableCell>
                              <TableCell sx={commonCellStyle(order.only_extra_orders)}>
                                {order.bought_in_domain}
                              </TableCell>
                              <TableCell sx={commonCellStyle(order.only_extra_orders)}>
                                {formatCurrency(getOrderAmount(order))}
                              </TableCell>
                              {hasPremiseCommission() && (
                                <TableCell sx={commonCellStyle(order.only_extra_orders)}>
                                  {formatCurrency(getOrderPremiseCommission(order) || 0)}
                                </TableCell>
                              )}
                              <TableCell sx={commonCellStyle(order.only_extra_orders)}>
                                {formatCurrency(getOrderCommission(order) || 0)}
                              </TableCell>
                              <TableCell sx={commonCellStyle(order.only_extra_orders)}>
                                {!!order.payed_at ? (
                                  <Chip
                                    label={t("PAYMENT_COMPLETED")}
                                    color="success"
                                    icon={<Check sx={{ fontSize: "1.5rem" }} />}
                                  />
                                ) : (
                                  <Chip
                                    label={t("PENDING_PAYMENT")}
                                    color="error"
                                    icon={<Close sx={{ fontSize: "1.5rem" }} />}
                                  />
                                )}
                              </TableCell>
                              <TableCell sx={commonCellStyle(order.only_extra_orders)}>
                                <Grid container alignItems="center" flexWrap="nowrap" columnGap={1}>
                                  {isIncompleteClientInfo(order) && (
                                    <Tooltip title={t("PENDING_TICKET_INFO")}>
                                      <IconButton onClick={() => handleRowClick(order)}>
                                        <WarningAmberIcon sx={{ color: "var(--oniria-red)" }} />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  <Tooltip title={t("SEE")}>
                                    <IconButton onClick={() => handleRowClick(order)}>
                                      <Visibility sx={{ color: "var(--secondary-color)" }} />
                                    </IconButton>
                                  </Tooltip>
                                  {(isSuperAdmin || isManager || isAdmin) &&
                                  order.amount > 0 &&
                                  !order.refund_status ? (
                                    <Tooltip title={t("REFUND_APPLY")}>
                                      <IconButton onClick={() => handleOpenRefund(order)}>
                                        <CurrencyExchange
                                          sx={{ color: "var(--secondary-color)" }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Typography>
                                      {order.refund_status
                                        ? t(order.refund_status.toUpperCase())
                                        : ""}
                                    </Typography>
                                  )}
                                  {(isSuperAdmin || isManager || isAdmin) && !order.payed_at && (
                                    <Tooltip title={t("COMPLETE_PAYMENT")}>
                                      <IconButton
                                        onClick={(e) => handleOpenCompletePayment(e, order)}
                                      >
                                        <PaidOutlined sx={{ color: "var(--secondary-color)" }} />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Grid>
                              </TableCell>
                            </TableRow>
                            {/** Extra orders */}
                            {Array.isArray(order?.only_extra_orders) &&
                              order.only_extra_orders.length > 0 &&
                              order.only_extra_orders?.map((item, index) => {
                                const isLastRow = index === order.only_extra_orders.length - 1;
                                const borderBottomStyle = { borderBottom: isLastRow ? "" : "none" };
                                console.log("extras", item);
                                return (
                                  <TableRow
                                    key={index}
                                    sx={{
                                      ...borderBottomStyle,
                                    }}
                                  >
                                    <TableCell sx={{ padding: 0, ...borderBottomStyle }} />
                                    <TableCell sx={{ padding: 0, ...borderBottomStyle }} />
                                    <TableCell
                                      sx={{ padding: 0, paddingLeft: "36px", ...borderBottomStyle }}
                                    >
                                      {item.transaction_id}
                                    </TableCell>
                                    {renderExtrasCellOrders(item.extras, isLastRow)}
                                    <TableCell
                                      sx={{ padding: 0, paddingLeft: "36px", ...borderBottomStyle }}
                                    >
                                      {formatDate(
                                        item.payed_at || item.first_payment_payed_at || ""
                                      )}
                                    </TableCell>
                                    <TableCell
                                      sx={{ padding: 0, paddingLeft: "36px", ...borderBottomStyle }}
                                    >
                                      {item?.bought_in_domain}
                                    </TableCell>
                                    <TableCell
                                      sx={{ padding: 0, paddingLeft: "36px", ...borderBottomStyle }}
                                    >
                                      {formatCurrency(item.amount || 0)}
                                    </TableCell>
                                    {hasPremiseCommission() ? (
                                      <TableCell
                                        sx={{
                                          padding: 0,
                                          paddingLeft: "36px",
                                          ...borderBottomStyle,
                                        }}
                                      >
                                        {formatCurrency(item.premise_commission)}
                                      </TableCell>
                                    ) : (
                                      <TableCell sx={{ ...borderBottomStyle }}></TableCell>
                                    )}
                                    <TableCell sx={{ ...borderBottomStyle }}></TableCell>
                                    <TableCell
                                      sx={{ padding: 0, paddingLeft: "16px", ...borderBottomStyle }}
                                    >
                                      <Grid
                                        container
                                        alignItems="center"
                                        flexWrap="nowrap"
                                        columnGap={1}
                                      >
                                        <Tooltip title={t("SEE")}>
                                          <IconButton onClick={() => handleRowClick(item)}>
                                            <Visibility sx={{ color: "var(--secondary-color)" }} />
                                          </IconButton>
                                        </Tooltip>
                                        {(isSuperAdmin || isManager || isAdmin) &&
                                        item.amount > 0 &&
                                        !item.refund_status ? (
                                          <Tooltip title={t("REFUND_APPLY")}>
                                            <Button onClick={() => handleOpenRefund(item)}>
                                              <CurrencyExchange
                                                sx={{ color: "var(--secondary-color)" }}
                                              />
                                            </Button>
                                          </Tooltip>
                                        ) : (
                                          <Typography>
                                            {item.refund_status
                                              ? t(item.refund_status.toUpperCase())
                                              : ""}
                                          </Typography>
                                        )}
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
              <Grid container justifyContent="center" alignItems="center" mt={5} mb={25}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={changePage}
                  sx={PAGINATION_STYLES}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <RefundDialog
        openRefund={openRefund}
        handleCloseRefund={handleCloseRefund}
        maxPrice={maxPrice}
        user={userInfo}
        order_id={selectedOrder?.order_id}
      />
      <CompletePaymentDialog
        isOpen={openCompletePayment}
        onClose={() => setOpenCompletePayment(false)}
        order={selectedOrder}
        refetch={refetch}
      />
    </>
  );
};

export default Orders;
