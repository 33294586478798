import { SERVICE } from "../constants/api";
import http from "../constants/axios";
import { ASSIGNED_TAB } from "../utils/hotels";

export const getTransports = async (page, perPage, filters, selectedTab) => {
  try {
    const url = selectedTab === ASSIGNED_TAB ? SERVICE.TRANSPORTS : SERVICE.TEMPLATE_TRANSPORTS;
    const { asc, desc, search, transit, startPoint, endPoint, isActive } = filters;
    const params = new URLSearchParams();
    if (page) params.append("page", page);
    if (perPage) params.append("perpage", perPage);
    if (search) params.append("search", search);
    if (transit && transit !== "all") params.append("transit", transit);
    if (isActive) params.append("is_active", isActive);
    if (startPoint && startPoint !== "all") {
      params.append("start_point_id", startPoint);
    }
    if (endPoint && endPoint !== "all") {
      params.append("end_point_id", endPoint);
    }

    if (asc && asc.length > 0) {
      params.append("asc", asc.join(","));
    }

    if (desc && desc.length > 0) {
      params.append("desc", desc.join(","));
    }

    const response = await http.get(`${url}?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching transports:", error);
    throw error;
  }
};

export const getTransportDetails = async (id, isTrip) => {
  try {
    const url = isTrip ? SERVICE.TRANSPORTS : SERVICE.TEMPLATE_TRANSPORTS;
    const response = await http.get(`${url}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching transport details:", error);
    throw error;
  }
};

export const createTransport = async (body, isTrip) => {
  try {
    const url = isTrip ? SERVICE.TRANSPORTS : SERVICE.TEMPLATE_TRANSPORTS;
    const response = await http.post(`${url}`, body);
    return response.data;
  } catch (error) {
    console.error("Error creating transport:", error);
    throw error;
  }
};

export const editTransport = async (id, body, isTrip) => {
  try {
    const url = isTrip ? SERVICE.TRANSPORTS : SERVICE.TEMPLATE_TRANSPORTS;
    const response = await http.put(`${url}/${id}`, body);
    return response.data;
  } catch (error) {
    console.error("Error editing transport:", error);
    throw error;
  }
};

export const deleteTransport = async (id, selectedTab) => {
  try {
    const url = selectedTab === ASSIGNED_TAB ? SERVICE.TRANSPORTS : SERVICE.TEMPLATE_TRANSPORTS;
    const response = await http.delete(`${url}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting transport:", error);
    throw error;
  }
};

export const assignDomainToTransport = async (id, domains, isTrip) => {
  const body = {
    domains_ids: domains,
  };
  const url = isTrip ? SERVICE.TRANSPORTS : SERVICE.TEMPLATE_TRANSPORTS;

  try {
    const response = await http.put(`${url}/domains/${id}`, body);
    return response.data;
  } catch (error) {
    console.error("Error assign domains:", error);
    throw error;
  }
};
