// CLEAR THE IDS FOR DUPLICATE TRIP
export default async function prepareDuplicateTickets(tickets) {
  const newTickets = tickets.map((ticket) => {
    const newTicket = JSON.parse(JSON.stringify(ticket));
    newTicket.id = null;

    delete newTicket.event_id;
    delete newTicket.last_modified_at;
    delete newTicket.created_at;

    // DISCOUNTS
    if (newTicket.promotional_code_discounts && newTicket.promotional_code_discounts.length > 0) {
      newTicket.promotional_code_discounts = newTicket.promotional_code_discounts.map(
        (discount) => {
          discount.id = null;
          discount.ticket_id = null;
          return discount;
        }
      );
    }
    if (newTicket.group_discounts && newTicket.group_discounts.length > 0) {
      newTicket.group_discounts = newTicket.group_discounts.map((discount) => {
        discount.id = null;
        discount.ticket_id = null;
        return discount;
      });
    }

    // LANGUAGES
    if (newTicket.ticket_language_infos && newTicket.ticket_language_infos.length > 0) {
      newTicket.ticket_language_infos = newTicket.ticket_language_infos.map((languageInfo) => {
        languageInfo.id = null;
        languageInfo.ticket_id = null;
        return languageInfo;
      });
    }

    return newTicket;
  });
  return newTickets;
}
