import { CheckBox, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../constants/utils";
import { useQueryAssignDomains } from "../../../hooks/queries/useQueryAssignDomains";
import InfoToolTip from "../../shared/InfoToolTip";

const SELECT_OPTIONS = {
  ALL: "all",
  SELECT: "select",
};

const TripExtraWhereToBuy = ({ index }) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const ticketsIds = watch(`extras[${index}].tickets_ids`) || [];
  const eventTickets = watch("tickets") || [];
  const extraDomains = watch(`extras[${index}].domain_names`) || [];
  const eventCategories = watch("details.event_categories") || [];

  const [selectedValue, setSelectedValue] = useState(() => {
    return ticketsIds?.length > 0 ? SELECT_OPTIONS.SELECT : SELECT_OPTIONS.ALL;
  });
  const [domainValue, setDomainValue] = useState(() => {
    return extraDomains?.length > 0 ? SELECT_OPTIONS.SELECT : SELECT_OPTIONS.ALL;
  });

  const { data: domainCategories } = useQueryAssignDomains();

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (value === SELECT_OPTIONS.ALL) {
      setValue(`extras[${index}].tickets_ids`, []);
    }
  };

  const handleRadioDomainChange = (event) => {
    const value = event.target.value;
    setDomainValue(value);
    if (value === SELECT_OPTIONS.ALL) {
      setValue(`extras[${index}].domain_names`, []);
    }
  };

  const handleCheckBoxChange = (id, isChecked) => {
    const prevTicketsIds = [...ticketsIds];
    if (isChecked) {
      prevTicketsIds.push(id);
      setValue(`extras[${index}].tickets_ids`, [...prevTicketsIds]);
    } else {
      const index = prevTicketsIds.indexOf(id);
      if (index > -1) {
        prevTicketsIds.splice(index, 1);
      }
      setValue(`extras[${index}].tickets_ids`, [...prevTicketsIds]);
    }
  };

  const isDomainChecked = (domain) => {
    return domain.categories.some(
      (category) =>
        eventCategories?.some((selectedCategory) => selectedCategory.category_id === category.id) ||
        category?.subcategories.some((subcategory) =>
          eventCategories?.some(
            (selectedCategory) => selectedCategory.subcategory_id === subcategory.id
          )
        )
    );
  };

  const handleDomainChange = (domain, isChecked) => {
    const prevDomains = [...extraDomains];
    if (isChecked) {
      prevDomains.push(domain.name);
      setValue(`extras[${index}].domain_names`, [...prevDomains]);
    } else {
      const index = prevDomains.indexOf(domain.name);
      if (index > -1) {
        prevDomains.splice(index, 1);
      }
      setValue(`extras[${index}].domain_names`, [...prevDomains]);
    }
  };

  return (
    <Grid item xs={12} mt={1} container>
      <Grid item container>
        <FormControl>
          <Grid container alignItems="center">
            <Typography>{t("CAN_BUY_WITH")}:</Typography>
            <InfoToolTip text={t("VISIBLE_IN_EXTRAS_INFO")} />
          </Grid>
          <RadioGroup value={selectedValue} onChange={handleRadioChange} name="radio-buttons-group">
            <FormControlLabel
              value={SELECT_OPTIONS.ALL}
              control={<Radio className="radio-oniria" />}
              label={t("ALL_TICKETS")}
            />
            <FormControlLabel
              value={SELECT_OPTIONS.SELECT}
              control={<Radio className="radio-oniria" />}
              label={t("SELECT_TICKETS")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {selectedValue === SELECT_OPTIONS.SELECT && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <FormLabel>{t("TICKETS")}</FormLabel>
            </AccordionSummary>
            <AccordionDetails>
              {eventTickets
                .sort((a, b) => a.position - b.position)
                .map((ticket, index) => (
                  <Fragment key={ticket.id}>
                    <Grid>
                      <FormControlLabel
                        control={
                          <CheckBox
                            checked={ticketsIds?.includes(ticket.id || index)}
                            onChange={(e) =>
                              handleCheckBoxChange(ticket.id || index, e.target.checked)
                            }
                            className="checkbox-oniria"
                          />
                        }
                        label={
                          <Typography>
                            {ticket.name} {ticket.start_date ? formatDate(ticket.start_date) : ""}
                            {ticket.end_date ? ` - ${formatDate(ticket.end_date)}` : ""}
                          </Typography>
                        }
                      />
                    </Grid>
                  </Fragment>
                ))}
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
      {/* DOMAINS */}
      <Grid item container>
        <FormControl>
          <Typography>{t("SALES_ON")}:</Typography>
          <RadioGroup
            value={domainValue}
            onChange={handleRadioDomainChange}
            name="radio-buttons-group"
          >
            <FormControlLabel
              value={SELECT_OPTIONS.ALL}
              control={<Radio className="radio-oniria" />}
              label={t("OPTION_ALL_DOMAINS")}
            />
            <FormControlLabel
              value={SELECT_OPTIONS.SELECT}
              control={<Radio className="radio-oniria" />}
              label={t("CHOSE_DOMAINS")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {domainValue === SELECT_OPTIONS.SELECT && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>{t("DOMAINS")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {domainCategories
                ?.filter((domain) => isDomainChecked(domain))
                ?.map((domain) => (
                  <Fragment key={domain.id}>
                    <Grid>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={extraDomains?.includes(domain.name) || false}
                            onChange={(e) => handleDomainChange(domain, e.target.checked)}
                            className="checkbox-oniria"
                          />
                        }
                        label={<Typography>{domain.name}</Typography>}
                      />
                    </Grid>
                  </Fragment>
                ))}
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
    </Grid>
  );
};

export default TripExtraWhereToBuy;
