import { DeleteOutline, DragIndicator, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ABECEDARY } from "../../../../../constants/variables";
import LanguageTabs from "../../../../shared/languages/LanguageTabs";
import SelectHookForm from "../../../../shared/SelectHookForm";
import { focusColor } from "../../../../shared/textFieldStyle";
import TextEditor from "../../../../textEditor/TextEditor";
import AddTourPointDialog from "./AddTourPointDialog";
import ItineraryLanguageSelect from "./ItineraryLanguageSelect";

const TripItinerary = ({ index, handleOpenDelete, tourPoints, refetchTourPoints }) => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    register,
    formState: { errors },
    control,
  } = useFormContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const defaultLanguage = watch(`itineraries[${index}].language`);
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
    setValue(`itineraries[${index}].tour_point_id`, null);
  };

  const itinerary = watch(`itineraries[${index}]`) || {};
  const itineraryLanguages = watch(`itineraries[${index}].itinerary_language_infos`);

  const languageIndex = itineraryLanguages?.findIndex((info) => info.language === selectedLanguage);

  const orderTourPoints = tourPoints?.sort((a, b) => a?.name?.localeCompare(b?.name));

  return (
    <>
      <Draggable draggableId={`draggable-itinerary-${index}`} index={index}>
        {(provided, snapshot) => (
          <Accordion
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{
              backgroundColor: snapshot.isDragging ? "var(--grey-cancelled)" : "",
              display: "flex",
              flexDirection: "column",
              border: "1px solid var(--grey-cancelled)",
              boxShadow: "none",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Grid container flexDirection="row" justifyContent="space-between">
                <Grid item xs={6} container alignItems="center">
                  <DragIndicator sx={{ marginRight: 2 }} />
                  <Typography>
                    {t("POINT")} {ABECEDARY[index]} -{" "}
                    {itinerary.title ? itinerary.title : t("TITLE")}
                  </Typography>
                </Grid>
                <Grid item marginRight={2}>
                  <IconButton
                    onClick={(e) => handleOpenDelete(e, index)}
                    className="oniria-icon-btn-delete"
                  >
                    <DeleteOutline />
                  </IconButton>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <ItineraryLanguageSelect index={index} />
                <Grid item xs={12} mb={2}>
                  <LanguageTabs
                    languagesInfo={itineraryLanguages || []}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    defaultLanguage={defaultLanguage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>{t("TITLE")}*</Typography>
                  {selectedLanguage === defaultLanguage ? (
                    <>
                      <TextField
                        {...register(`itineraries[${index}].title`)}
                        error={!!errors?.itineraries?.[index]?.title}
                        helperText={errors?.itineraries?.[index]?.title?.message}
                        fullWidth
                        sx={focusColor}
                        placeholder={t("TITLE")}
                        required
                      />
                    </>
                  ) : (
                    <TextField
                      {...register(
                        `itineraries[${index}].itinerary_language_infos[${languageIndex}].title`
                      )}
                      error={
                        !!errors?.itineraries?.[index]?.itinerary_language_infos?.[languageIndex]
                          ?.title
                      }
                      helperText={
                        errors?.itineraries?.[index]?.itinerary_language_infos?.[languageIndex]
                          ?.title?.message
                      }
                      fullWidth
                      sx={focusColor}
                      placeholder={t("TITLE")}
                      required
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography>{t("TOUR_POINT")}*</Typography>
                  <SelectHookForm
                    name={`itineraries[${index}].tour_point_id`}
                    control={control}
                    defaultValue=""
                    options={[
                      { id: null, name: t("NONE") },
                      ...orderTourPoints,
                      { id: "create", name: `+ ${t("CREATE_NEW_TOUR_POINT")}` },
                    ].map((point) => (
                      <MenuItem
                        key={point.id}
                        value={point.id}
                        onClick={point.id === "create" ? handleOpenDialog : undefined}
                        className={point.id === "create" ? "oniria-border-btn" : ""}
                        sx={{ margin: point.id === "create" ? "10px" : "" }}
                      >
                        {point.name}
                      </MenuItem>
                    ))}
                    placeholder={t("TOUR_POINT")}
                    error={errors?.itineraries?.[index]?.tour_point_id}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>{t("SUBTITLE")}</Typography>
                  {selectedLanguage === defaultLanguage ? (
                    <>
                      <TextField
                        {...register(`itineraries[${index}].subtitle`)}
                        error={!!errors?.itineraries?.[index]?.subtitle}
                        helperText={errors?.itineraries?.[index]?.subtitle?.message}
                        fullWidth
                        sx={focusColor}
                        placeholder={t("SUBTITLE")}
                        required
                      />
                    </>
                  ) : (
                    <TextField
                      {...register(
                        `itineraries[${index}].itinerary_language_infos[${languageIndex}].subtitle`
                      )}
                      error={
                        !!errors?.itineraries?.[index]?.itinerary_language_infos?.[languageIndex]
                          ?.subtitle
                      }
                      helperText={
                        errors?.itineraries?.[index]?.itinerary_language_infos?.[languageIndex]
                          ?.subtitle?.message
                      }
                      fullWidth
                      sx={focusColor}
                      placeholder={t("SUBTITLE")}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography>{t("NIGHTS_DURATION")}</Typography>
                  <TextField
                    placeholder={"00"}
                    {...register(`itineraries[${index}].nights`)}
                    error={!!errors?.itineraries?.[index]?.nights}
                    helperText={errors?.itineraries?.[index]?.nights?.message}
                    fullWidth
                    sx={focusColor}
                    type="number"
                    inputProps={{ min: 0, max: 5 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register(`itineraries[${index}].start_point`)}
                        checked={watch(`itineraries.${index}.start_point`)}
                        className="checkbox-oniria"
                      />
                    }
                    label={t("START_POINT")}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register(`itineraries[${index}].end_point`)}
                        checked={watch(`itineraries.${index}.end_point`)}
                        className="checkbox-oniria"
                      />
                    }
                    label={t("END_POINT")}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("DESCRIPTION")}
                  </InputLabel>
                  {selectedLanguage === defaultLanguage ? (
                    <>
                      <TextEditor
                        data={watch(`itineraries.${index}.description`)}
                        setData={(data) => setValue(`itineraries.${index}.description`, data)}
                        id={`itinerary-${index}-description`}
                      />
                    </>
                  ) : (
                    <TextEditor
                      data={watch(
                        `itineraries.${index}.itinerary_language_infos.${languageIndex}.description`
                      )}
                      setData={(data) =>
                        setValue(
                          `itineraries.${index}.itinerary_language_infos.${languageIndex}.description`,
                          data
                        )
                      }
                      id={`itinerary-${index}-description`}
                    />
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Draggable>
      <AddTourPointDialog
        isDialogOpen={isDialogOpen}
        handleCloseDialog={() => setIsDialogOpen(false)}
        refetchTourPoints={refetchTourPoints}
      />
    </>
  );
};

export default TripItinerary;
