import { Grid, InputLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoToolTip from "../../../shared/InfoToolTip";
import { focusColor } from "../../../shared/textFieldStyle";

const TicketDates = ({
  checkTicketField,
  index,
  ticket,
  ticketErrors,
  buttonClicked,
  isDisabled,
  has_double_payment,
}) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(ticket.start_date || "");
  const [endDate, setEndDate] = useState(ticket.end_date || "");
  const [secondPaymentEndDate, setSecondPaymentEndDate] = useState(
    ticket.second_payment_end_date || ""
  );

  const handleDateChange = (field, date) => {
    if (field === "start_date") {
      setStartDate(date);
    } else if (field === "end_date") {
      setEndDate(date);
    } else if (field === "second_payment_end_date") {
      setSecondPaymentEndDate(date);
    }
    const event = { target: { value: date } };
    checkTicketField(field, event, /.*/, t("CHOOSE_OPTION"), true, index);
  };

  const handleTimeChange = (field, time) => {
    const newDate =
      field === "start_time"
        ? startDate.split(" ")[0] + " " + time
        : field === "end_time"
        ? endDate.split(" ")[0] + " " + time
        : secondPaymentEndDate.split(" ")[0] + " " + time;

    const event = { target: { value: newDate } };
    if (field === "start_time") {
      setStartDate(newDate);
      checkTicketField("start_date", event, /.*/, t("CHOOSE_OPTION"), true, index);
    } else if (field === "end_time") {
      setEndDate(newDate);
      checkTicketField("end_date", event, /.*/, t("CHOOSE_OPTION"), true, index);
    } else if (field === "second_payment_end_time") {
      setSecondPaymentEndDate(newDate);
      checkTicketField("second_payment_end_date", event, /.*/, t("CHOOSE_OPTION"), true, index);
    }
  };

  useEffect(() => {
    if (ticket?.start_date_time) {
      setStartDate((prev) => `${prev} ${ticket.start_date_time}`);
    }
    if (ticket?.end_date_time) {
      setEndDate((prev) => `${prev} ${ticket.end_date_time}`);
    }
    if (ticket?.second_payment_end_date_time) {
      setSecondPaymentEndDate((prev) => `${prev} ${ticket.second_payment_end_date_time}`);
    }
  }, []);

  return (
    <Grid item xs={12} container columnSpacing={2}>
      <Grid item xs={12} container columnSpacing={2}>
        <Grid item xs={12}>
          <InputLabel sx={{ fontSize: "18px", color: "black" }}>
            <div
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                display: "flex",
              }}
            >
              {t("START_DATE")}
              <InfoToolTip text={t("INFO_TICKET_START_DATE")} />
            </div>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e) => handleDateChange("start_date", e.target.value)}
            type="date"
            margin="normal"
            required
            fullWidth
            id="start_date"
            name="start_date"
            value={startDate.split(" ")[0] || ""}
            autoComplete="start_date"
            error={
              (ticketErrors.start_date !== "" && ticketErrors.start_date !== "empty") ||
              (ticketErrors.start_date === "empty" && buttonClicked)
            }
            helperText={ticketErrors.start_date !== "empty" ? ticketErrors.start_date : ""}
            disabled={isDisabled}
            sx={focusColor}
            inputProps={{
              min: new Date().toISOString().split(" ")[0],
              max: "2100-12-31",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e) => handleTimeChange("start_time", e.target.value)}
            type="time"
            margin="normal"
            required
            fullWidth
            id="start_time"
            name="start_time"
            value={(startDate.split(" ")[1] || "").substring(0, 5)}
            autoComplete="start_time"
            disabled={isDisabled}
            sx={focusColor}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container columnSpacing={2}>
        <Grid item xs={12}>
          <InputLabel sx={{ fontSize: "18px", color: "black" }}>
            <div
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                display: "flex",
              }}
            >
              {t("END_DATE")}
              <InfoToolTip text={t("INFO_TICKET_END_DATE")} />
            </div>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e) => handleDateChange("end_date", e.target.value)}
            type="date"
            margin="normal"
            required
            fullWidth
            id="end_date"
            name="end_date"
            value={endDate.split(" ")[0] || ""}
            autoComplete="end_date"
            error={
              (ticketErrors.end_date !== "" && ticketErrors.end_date !== "empty") ||
              (ticketErrors.end_date === "empty" && buttonClicked)
            }
            helperText={ticketErrors.end_date !== "empty" ? ticketErrors.end_date : ""}
            disabled={isDisabled}
            sx={focusColor}
            inputProps={{
              min: startDate.split(" ")[0],
              max: "2100-12-31",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e) => handleTimeChange("end_time", e.target.value)}
            type="time"
            margin="normal"
            required
            fullWidth
            id="end_time"
            name="end_time"
            value={(endDate.split(" ")[1] || "").substring(0, 5)}
            autoComplete="end_time"
            disabled={isDisabled}
            sx={focusColor}
          />
        </Grid>
      </Grid>
      {has_double_payment && (
        <Grid item xs={12} container columnSpacing={2}>
          <Grid item xs={12}>
            <InputLabel sx={{ fontSize: "18px", color: "black" }}>
              <div
                style={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  display: "flex",
                }}
              >
                {t("SECOND_PAYMENT_END_DATE")}
              </div>
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={(e) => handleDateChange("second_payment_end_date", e.target.value)}
              type="date"
              margin="normal"
              required
              fullWidth
              id="second_payment_end_date"
              name="second_payment_end_date"
              value={secondPaymentEndDate.split(" ")[0] || ""}
              autoComplete="second_payment_end_date"
              error={
                (ticketErrors.second_payment_end_date !== "" &&
                  ticketErrors.second_payment_end_date !== "empty") ||
                (ticketErrors.second_payment_end_date === "empty" && buttonClicked)
              }
              helperText={
                ticketErrors.second_payment_end_date !== "empty"
                  ? ticketErrors.second_payment_end_date
                  : ""
              }
              disabled={isDisabled}
              sx={focusColor}
              inputProps={{
                min: new Date().toISOString().split(" ")[0],
                max: endDate.split(" ")[0] || "2100-12-31",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={(e) => handleTimeChange("second_payment_end_time", e.target.value)}
              type="time"
              margin="normal"
              required
              fullWidth
              id="second_payment_end_time"
              name="second_payment_end_time"
              value={(secondPaymentEndDate.split(" ")[1] || "").substring(0, 5)}
              autoComplete="second_payment_end_time"
              disabled={isDisabled}
              sx={focusColor}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TicketDates;
