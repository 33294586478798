import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { focusColor } from "./textFieldStyle";
import { Controller } from "react-hook-form";

const SelectHookForm = ({
  control,
  label = "",
  error,
  options = [],
  disabled = false,
  name,
  defaultValue = "",
  required = false,
  multiple = false,
  placeholder = "",
}) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth sx={focusColor}>
      {!!label && <InputLabel className="form-input-label">{label}</InputLabel>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ""}
        rules={{ required: required }}
        render={({ field }) => {
          // Check if the current value is in the options
          const valueExists = options.some((option) => option.props.value === field.value);
          const adjustedOptions = valueExists
            ? options
            : [
                <MenuItem key="invalid-option" value={field.value} disabled>
                  {t("INVALID_SELECTION")}
                </MenuItem>,
                ...options,
              ];

          return (
            <Select
              {...field}
              value={field?.value || ""}
              label={label}
              className="input-select form-select-field"
              error={!!error}
              slotProps={{ input: { readOnly: disabled } }}
              multiple={multiple}
              required={required}
            >
              {placeholder && (
                <MenuItem value="" disabled>
                  {placeholder}
                </MenuItem>
              )}
              {adjustedOptions}
            </Select>
          );
        }}
      />
      {!!error && <Typography className="form-input-error">{t(error.message)}</Typography>}
    </FormControl>
  );
};

export default SelectHookForm;
