import { Grid } from "@mui/material";
import AddHostingExtras from "./AddHostingExtras";
import AssignHosting from "./AssignHosting";

const AddHosting = () => {
  return (
    <Grid container paddingLeft={2} sx={{ width: "100%" }}>
      <AddHostingExtras />
      <AssignHosting />
    </Grid>
  );
};

export default AddHosting;
