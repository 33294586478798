import { transformObjectWithUrls } from "../components/shared/FormsValidator";
import { convertStringToObject, sanitizeForSending, sanitizeInt } from "../constants/utils";

export const prepareTransportToSend = async (data) => {
  const newBody = JSON.parse(JSON.stringify(data));
  const bodyToSend = await transformObjectWithUrls(newBody);

  if (!bodyToSend.id) {
    bodyToSend.is_active = true;
  }
  bodyToSend.description = sanitizeForSending(bodyToSend.description);
  bodyToSend.places = sanitizeInt(bodyToSend.places);
  bodyToSend.assignation_limit = sanitizeInt(bodyToSend.assignation_limit);

  // IDIOMS
  if (
    Array.isArray(bodyToSend.transport_language_infos) &&
    bodyToSend.transport_language_infos.length > 0
  ) {
    bodyToSend.transport_language_infos = bodyToSend.transport_language_infos.map(
      (languageInfo) => {
        languageInfo.description = sanitizeForSending(languageInfo.description);
        languageInfo.transport_id = bodyToSend.id || null;
        languageInfo.id = languageInfo.id || null;
        return languageInfo;
      }
    );
  } else {
    bodyToSend.transport_language_infos = null;
  }

  bodyToSend.start_date = bodyToSend.start_date
    ? new Date(`${bodyToSend.start_date}T${bodyToSend.start_date_time}:00`)
    : null;
  bodyToSend.end_date = bodyToSend.end_date
    ? new Date(`${bodyToSend.end_date}T${bodyToSend.end_date_time}:00`)
    : null;

  return bodyToSend;
};

export const prepareTransportToReceive = (data) => {
  const newBody = JSON.parse(JSON.stringify(data));

  newBody.description = convertStringToObject(newBody.description);

  // IDIOMS
  if (
    Array.isArray(newBody.transport_language_infos) &&
    newBody.transport_language_infos.length > 0
  ) {
    newBody.transport_language_infos = newBody.transport_language_infos.map((languageInfo) => {
      languageInfo.description = convertStringToObject(languageInfo.description);
      return languageInfo;
    });
  }

  if (newBody.start_date) {
    newBody.start_date_time = newBody.start_date.split("T")[1].split(".")[0].slice(0, 5);
    newBody.start_date = new Date(newBody.start_date).toISOString().split("T")[0];
  }

  if (newBody.end_date) {
    newBody.end_date_time = newBody.end_date.split("T")[1].split(".")[0].slice(0, 5);
    newBody.end_date = new Date(newBody.end_date).toISOString().split("T")[0];
  }

  return newBody;
};
