import { EditOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Card, CardContent, CardMedia, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getRoutePointName } from "../../../constants/utils";
import useQueryHotelDetails from "../../../hooks/queries/hotels/useQueryHotelDetails";
import useQueryTourPointsList from "../../../hooks/queries/tourPoints/useQueryTourPointsList";
import AssignHotelDialog from "../assignHotelDialog/AssignHotelDialog";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

const AssignedHotelCard = ({ hotelId, handleClose, selectedItinerary }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const { getValues } = useFormContext();

  const tripInfo = getValues();

  const { data: tourPoints = [] } = useQueryTourPointsList();
  const { data: hotel } = useQueryHotelDetails(hotelId, () => {}, true);

  const handleSee = (id) => {
    navigate(`/hotels/edit-trip/${id}`);
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: 250,
          borderRadius: 2,
          position: "relative",
        }}
      >
        <Grid container spacing={1} padding={1}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end", position: "relative" }}
          >
            <IconButton
              onClick={handleClose}
              size="small"
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 1,
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>

          <Grid item xs={3}>
            <CardMedia
              component="img"
              height="70"
              image={hotel?.img_url}
              alt={hotel?.name || "Hotel Image"}
              sx={{ objectFit: "cover", width: "100%", borderRadius: 2 }}
            />
          </Grid>

          <Grid item xs={8}>
            <CardContent sx={{ padding: 0 }}>
              <Typography
                variant="body2"
                component="div"
                noWrap
                sx={{ fontWeight: 700, fontSize: "0.875rem" }}
              >
                {hotel?.name || "Hotel Name"}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: "0.75rem" }}>
                {getRoutePointName(hotel?.tour_point_id, tourPoints)}
              </Typography>
            </CardContent>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={1}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            {/* <Grid item>
            <Typography variant="body2">
              {hotel?.stock || 0} {t("PLACES")}
            </Typography>
          </Grid> */}
            {/* <Tooltip title={t("SEE_EVENTS")}>
              <IconButton onClick={() => handleSee(hotelId)}>
                <RemoveRedEyeOutlined sx={{ color: "var(--oniria-gold)" }} />
              </IconButton>
            </Tooltip> */}
            <Tooltip title={t("EDIT_HOTEL")}>
              <IconButton onClick={() => setOpenCreateDialog(true)}>
                <EditOutlined sx={{ color: "var(--oniria-gold)" }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>

      <AssignHotelDialog
        isOpen={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        onAccept={() => setOpenCreateDialog(false)}
        tripInfo={tripInfo}
        hotelTemplateId={hotelId}
        addHotelToItinerary={() => {}}
        selectedItinerary={selectedItinerary}
        isEdit
      />
    </>
  );
};

export default AssignedHotelCard;
