import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/Header";
import CardEvents from "../../../components/events/eventCard/CardEvents";
import SelectFilter from "../../../components/events/filters/filter/SelectFilter";
import CreateTripDialog from "../../../components/events/trip/CreateTripDialog";
import { Loading } from "../../../components/shared/Loading";
import SelectPerPage from "../../../components/shared/SelectPerPage";
import { focusColor } from "../../../components/shared/textFieldStyle";
import { RQ_KEY } from "../../../constants/query";
import { ROUTES } from "../../../constants/routes";
import { MUI_SELECT_PRIM_COLOR, PAGINATION_STYLES } from "../../../constants/styles";
import { EVENT_TABS, EVENTS_TYPE } from "../../../constants/variables";
import { useQueryEventsDomain } from "../../../hooks/queries/events/useQueryEventsDomain";
import { useQueryDomains } from "../../../hooks/queries/useQueryDomains";
import { useQueryPremisesList } from "../../../hooks/queries/useQueryPremisesList";
import useDebounce from "../../../hooks/useDebouncing";
import usePagination from "../../../hooks/usePagination";
import useUserRoles from "../../../hooks/useUserRoles";
import { getCities, getFilterTrips } from "../../../services/eventsServices";
import { getAllGrouping, getOrganizations } from "../../../services/organizationsServices";
import {
  clearTabDomain,
  setReducerDomain,
  setReducerTab,
} from "../../../store/reducers/b2bReducer";

let totalTrips = 0;

const Trips = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [dateFilter, setFilterDates] = useState("upcoming");
  const [cityFilter, setCityFilter] = useState("all");
  const [tripsFiltered, setTripsFiltered] = useState([]);
  const [cityNoTrips, setCityNoTrips] = useState(false);
  const [cities, setCities] = useState([]);
  const [grouping, setGrouping] = useState("all");
  const [premise, setPremise] = useState("all");
  const [organization, setOrganization] = useState("all");
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedDomain, setSelectedDomain] = useState("all");
  const [openCreateTrip, setOpenCreateTrip] = useState(false);

  const userInfo = useSelector((state) => state.userInfo);
  const { isSuperAdmin, isAdmin, isManager, isOfficeEditor } = useUserRoles();

  const { page, perPage, changePage, changePerPage } = usePagination();

  useEffect(() => {
    if (userInfo.grouping_id) {
      setGrouping(userInfo.grouping_id);
    }
  }, []);

  const breadcrumbs = [
    {
      name: t("TRAVELS"),
      link: ROUTES.TRIPS,
    },
  ];

  const handleQueryCities = (data) => {
    setCities(data);
  };
  const handleQuerySuccessFiltered = (data) => {
    totalTrips = data.total;
    const trips = data.data;
    setTripsFiltered(trips);
    setCityNoTrips(trips?.length === 0);
  };

  const handleCityFilter = (data) => {
    setCityFilter(data);
    changePage(null, 1);
  };
  const handleDateFilter = (data) => {
    setFilterDates(data);
    changePage(null, 1);
  };
  const handleSearchChange = (event) => {
    const search = event.target.value;
    setSearch(search);
    changePage(null, 1);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const { isLoading: isLoadingDomain, isRefetching: isRefetchingDomain } = useQueryEventsDomain(
    page,
    perPage,
    selectedTab,
    selectedDomain,
    search,
    "",
    "",
    dateFilter,
    EVENTS_TYPE.TRIP,
    cityFilter,
    premise,
    grouping,
    organization,
    handleQuerySuccessFiltered
  );

  const { refetch, isLoading, isRefetching } = useQuery(
    [
      RQ_KEY.ALL_TRIPS_FILTERED,
      cityFilter,
      search,
      dateFilter,
      page,
      perPage,
      grouping,
      premise,
      organization,
    ],
    () =>
      getFilterTrips(
        cityFilter,
        search,
        dateFilter,
        page,
        perPage,
        grouping,
        premise,
        organization
      ),
    {
      onSuccess: handleQuerySuccessFiltered,
      refetchOnWindowFocus: false,
      enabled: selectedTab === EVENT_TABS.MY_EVENTS,
    }
  );

  useQuery("AllTrips", () => getCities(), {
    onSuccess: handleQueryCities,
  });

  //Ciudades
  const menuItems = cities.map((city) => (
    <MenuItem key={city.city} value={city.city}>
      {city.city}
    </MenuItem>
  ));

  //Sedes
  const { data: premises } = useQueryPremisesList(organization, grouping);

  const premiseItems = premises?.map((premise) => (
    <MenuItem key={premise.id} value={premise.id}>
      {premise.name}
    </MenuItem>
  ));

  //Organizations
  const { data: organizations } = useQuery(
    ["organizations", grouping],
    () => getOrganizations(grouping),
    {
      refetchOnWindowFocus: false,
    }
  );

  const organizationItems = organizations?.map((organization) => (
    <MenuItem key={organization.id} value={organization.id}>
      {organization.legal_name}
    </MenuItem>
  ));

  //Agrupaciones
  const { data: groups, refetch: refetchGrouping } = useQuery(
    RQ_KEY.ALL_GROUPING,
    () => getAllGrouping(),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  //la ejecutamos solo si es super admin
  useEffect(() => {
    if (isSuperAdmin) {
      refetchGrouping();
    }
  }, []);

  const groupItems = groups?.map((group) => (
    <MenuItem key={group.id} value={group.id}>
      {group.name}
    </MenuItem>
  ));

  //Domains
  const { data: domains = [] } = useQueryDomains();

  const domainsFormat = domains.map((domain) => ({
    id: domain.domain_name,
    name: domain.domain_name,
  }));

  const handleGroupingFilter = (data) => {
    setGrouping(data);
    changePage(null, 1);
    setOrganization("all");
  };
  const handlePremiseFilter = (data) => {
    setPremise(data);
    changePage(null, 1);
  };
  const handleOrganizationFilter = (data) => {
    setOrganization(data);
    changePage(null, 1);
  };

  const handleChangeTab = (_, newValue) => {
    setSelectedTab(newValue);
    changePage(null, 1);
    dispatch(setReducerTab(newValue));
  };

  const handleChangeDomain = (data) => {
    setSelectedDomain(data);
    changePage(null, 1);
    dispatch(setReducerDomain(data));
  };

  useEffect(() => {
    dispatch(clearTabDomain());
  }, []);

  const numberPages = Math.ceil(parseFloat(totalTrips) / parseFloat(perPage));

  const renderLoading = isLoading || isRefetching || isLoadingDomain || isRefetchingDomain;

  const renderNoTripsFound = () => <Grid ml={3}>{t("TRIPS_NOT_FOUND")}</Grid>;

  const renderTrips = () => (
    <Grid
      container
      rowSpacing={2}
      sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mb: 20 }}
    >
      {tripsFiltered?.map((trip, i) => (
        <CardEvents event={trip} key={i} refetch={refetch} />
      ))}
    </Grid>
  );
  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_TRIPS")} />
      <Grid item xs={11} sm={12} container justifyContent="center">
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          TabIndicatorProps={{
            style: { backgroundColor: "var(--secondary-color)" },
          }}
          sx={{
            mb: 2,
            color: "var(--secondary-color)",
          }}
        >
          <Tab label={t("TRIPS_TABS_MY_EVENTS")} sx={MUI_SELECT_PRIM_COLOR} />
          <Tab label={t("TRIPS_TABS_B2B")} sx={MUI_SELECT_PRIM_COLOR} />
        </Tabs>
      </Grid>
      <Grid
        item
        xs={11}
        sm={12}
        sx={{ mb: 5, ml: 3, display: "flex", flexDirection: "row", justifyContent: "initial" }}
      >
        <Grid
          container
          spacing={1}
          sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
        >
          <Grid item xs={12} sm={12} md={4}>
            <SearchIcon sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }} />
            <TextField
              label={t("SEARCH")}
              onChange={(event) => debounceSearch(event)}
              size="small"
              sx={[focusColor, { width: "75%" }]}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={8}
            columnGap={1}
            rowGap={1}
            justifyContent={{ xs: "flex-start", md: "flex-end" }}
          >
            {selectedTab === EVENT_TABS.B2B && (
              <SelectFilter
                value={selectedDomain}
                onChange={handleChangeDomain}
                options={[{ id: "all", name: t("ALL_DOMAINS") }, ...domainsFormat]}
              />
            )}
            {isSuperAdmin && (
              <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
                <Select
                  value={grouping}
                  onChange={(e) => handleGroupingFilter(e.target.value)}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                >
                  <MenuItem value="all">{t("ALL_GROUPS")}</MenuItem>
                  {groupItems}
                </Select>
              </FormControl>
            )}
            <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
              <Select
                value={organization}
                onChange={(e) => handleOrganizationFilter(e.target.value)}
                sx={{ fontSize: "12px", borderRadius: 3 }}
              >
                <MenuItem value="all">{t("ALL_ORGANIZATIONS")}</MenuItem>
                {organizationItems}
              </Select>
            </FormControl>
            <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
              <Select
                value={premise}
                onChange={(e) => handlePremiseFilter(e.target.value)}
                sx={{ fontSize: "12px", borderRadius: 3 }}
              >
                <MenuItem value="all">{t("ALL_SITES")}</MenuItem>
                {premiseItems}
              </Select>
            </FormControl>
            <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
              <Select
                value={cityFilter}
                onChange={(e) => handleCityFilter(e.target.value)}
                sx={{ fontSize: "12px", borderRadius: 3 }}
              >
                <MenuItem value="all">{t("ALLS_CITIES")}</MenuItem>
                {menuItems}
              </Select>
            </FormControl>
            <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
              <Select
                value={dateFilter}
                onChange={(e) => handleDateFilter(e.target.value)}
                sx={{ fontSize: "12px", borderRadius: 3 }}
              >
                <MenuItem value="upcoming">{t("UPCOMING")}</MenuItem>
                <MenuItem value="pasts">{t("PAST")}</MenuItem>
                <MenuItem value="alls">{t("ALLS")}</MenuItem>
              </Select>
            </FormControl>
            {(isSuperAdmin || isAdmin || isManager || isOfficeEditor) && (
              <Button
                type="submit"
                onClick={() => setOpenCreateTrip(true)}
                className="oniria-btn"
                variant="contained"
                sx={{ fontSize: "12px", borderRadius: 3, height: "fit-content" }}
              >
                {t("ADD_TRIP")}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      {totalTrips > 0 ? (
        <Grid container justifyContent={"center"} pb={2}>
          <Grid
            item
            xs={11}
            sm={12}
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography>
              {t("TRAVELS")} : {totalTrips}
            </Typography>
            {numberPages >= 1 && (
              <Grid item xs={12} md={8} container justifyContent="flex-end">
                <Grid item>
                  <SelectPerPage
                    text={t("TRAVELS")}
                    change={changePerPage}
                    value={perPage}
                    total={totalTrips}
                  />
                </Grid>
                <Pagination
                  count={numberPages}
                  mr={3}
                  sx={PAGINATION_STYLES}
                  page={page}
                  onChange={changePage}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : null}
      {cityNoTrips ? renderNoTripsFound() : renderLoading ? <Loading /> : renderTrips()}
      {numberPages > 1 && (
        <Pagination
          count={numberPages}
          sx={{
            mb: 20,
            ...PAGINATION_STYLES,
          }}
          page={page}
          onChange={changePage}
        />
      )}
      <CreateTripDialog isOpen={openCreateTrip} onClose={() => setOpenCreateTrip(false)} />
    </>
  );
};
export default Trips;
