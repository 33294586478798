import { Grid, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import useEventLanguage from "../../../hooks/useEventLanguage";
import TextEditor from "../../textEditor/TextEditor";
import LanguageTabs from "../languages/LanguageTabs";

const AdditionalInfo = ({
  additionalInfo,
  setFormParamsValue,
  event_language_infos,
  setFormData,
  setEventLanguageInfoError,
  defaultLanguage,
}) => {
  const { t } = useTranslation();
  const { selectedLanguage, setSelectedLanguage, selectedLanguageInfo, handleChangeAttribute } =
    useEventLanguage(event_language_infos, setFormData, setEventLanguageInfoError, defaultLanguage);

  const handleSetData = (data) => {
    setFormParamsValue("moreInformation", "additional_info", data);
  };

  const handleChangeName = (data) => {
    handleChangeAttribute("additional_info", data, /^.[\s\S]{0,5000000}$/, t("MAX_5000"));
  };

  return (
    <Grid item xs={11} sx={{ mt: 2, mb: 2, display: "flex", flexDirection: "column" }}>
      <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
        {t("ADDITIONAL_INFO")}
      </InputLabel>
      {event_language_infos?.length > 0 ? (
        <LanguageTabs
          event_languages_info={event_language_infos}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          defaultLanguage={defaultLanguage}
        />
      ) : null}
      {selectedLanguage === defaultLanguage ? (
        <TextEditor data={additionalInfo} setData={handleSetData} id="additional_info" />
      ) : (
        <div>
          <TextEditor
            data={selectedLanguageInfo?.additional_info}
            setData={handleChangeName}
            id={`additional_info_${selectedLanguage}`}
          />
        </div>
      )}
    </Grid>
  );
};

export default AdditionalInfo;
