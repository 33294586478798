import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DEFAULT_EVENT_LANGUAGE } from "../../../../constants/variables";
import { focusColor } from "../../../shared/textFieldStyle";

const TicketName = ({
  ticket,
  errors,
  index,
  checkTicketField,
  isDisabled,
  catchButtonClicked,
  handleChangeLanguageParam,
  selectedLanguage,
  defaultLanguage = DEFAULT_EVENT_LANGUAGE,
}) => {
  const { t } = useTranslation();

  const selectedLanguageIndex = ticket.ticket_language_infos.findIndex(
    (language) => language.language === selectedLanguage
  );

  return (
    <>
      {selectedLanguage === defaultLanguage ? (
        <TextField
          onChange={(e) =>
            checkTicketField(
              "name",
              e,
              /^(?!.*[|\\#·$~%¬=^[\]*{}]).{1,50}$/,
              t("MAX_50"),
              false,
              index
            )
          }
          margin="normal"
          required
          fullWidth
          value={ticket.name}
          id="name"
          name="name"
          autoComplete="name"
          autoFocus
          label={t("TICKET_NAME")}
          error={
            (errors[index].name !== "" && errors[index].name !== "empty") ||
            (errors[index].name === "empty" && catchButtonClicked)
          }
          helperText={errors[index].name !== "empty" ? errors[index].name : ""}
          disabled={isDisabled}
          sx={focusColor}
        />
      ) : (
        <TextField
          onChange={(e) => handleChangeLanguageParam(e.target.value, "name", selectedLanguageIndex)}
          margin="normal"
          required
          fullWidth
          value={ticket.ticket_language_infos[selectedLanguageIndex].name}
          id="name"
          name="name"
          autoComplete="name"
          autoFocus
          label={t("TICKET_NAME")}
          disabled={isDisabled}
          sx={focusColor}
        />
      )}
    </>
  );
};

export default TicketName;
