import { Cancel, CheckCircle, Circle } from "@mui/icons-material";
import { Box } from "@mui/material";

const AddComponentsIcons = ({ stepIndex, tripComponents }) => {
  const stepNames = ["hasHosting", "hasTransports", "hasTourLeaders"];
  const componentKey = stepNames[stepIndex];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
        borderRadius: "50%",
        color: "var(--secondary-color)",
        fontSize: 18,
      }}
    >
      {tripComponents[componentKey] === null ? (
        <Circle fontSize="inherit" />
      ) : tripComponents[componentKey] ? (
        <CheckCircle fontSize="inherit" />
      ) : (
        <Cancel fontSize="inherit" />
      )}
    </Box>
  );
};

export default AddComponentsIcons;
