import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EXTRA_TAGS } from "../../../../constants/variables";

const GlobalPensions = ({ includedPension, handlePensionChange, extras = {} }) => {
  const { t } = useTranslation();

  const breakfastExtra = extras?.find((extra) => extra.tags?.includes(EXTRA_TAGS.BREAKFAST));
  const halfPensionExtra = extras?.find((extra) => extra.tags?.includes(EXTRA_TAGS.HALF_PENSION));
  const fullPensionExtra = extras?.find((extra) => extra.tags?.includes(EXTRA_TAGS.COMPLETE));

  return (
    <Grid container mt={2}>
      <Typography fontWeight="bold">
        {t("PENSIONS")} ({t("GLOBALS")})
      </Typography>
      <Typography variant="caption">{t("GLOBAL_PENSIONS_DESCRIPTION")}</Typography>
      <br />
      {breakfastExtra && (
        <FormControlLabel
          control={
            <Checkbox
              checked={includedPension?.includes(EXTRA_TAGS.BREAKFAST)}
              onChange={(e) => handlePensionChange(EXTRA_TAGS.BREAKFAST, e.target.checked)}
              className="checkbox-oniria"
            />
          }
          label={t("BREAKFAST")}
        />
      )}
      {halfPensionExtra && (
        <FormControlLabel
          control={
            <Checkbox
              checked={includedPension?.includes(EXTRA_TAGS.HALF_PENSION)}
              onChange={(e) => handlePensionChange(EXTRA_TAGS.HALF_PENSION, e.target.checked)}
              className="checkbox-oniria"
            />
          }
          label={t("HALF_PENSION")}
        />
      )}
      {fullPensionExtra && (
        <FormControlLabel
          control={
            <Checkbox
              checked={includedPension?.includes(EXTRA_TAGS.COMPLETE)}
              onChange={(e) => handlePensionChange(EXTRA_TAGS.COMPLETE, e.target.checked)}
              className="checkbox-oniria"
            />
          }
          label={t("FULL_PENSION")}
        />
      )}
    </Grid>
  );
};

export default GlobalPensions;
