import { sendPdfInBase64, transformObjectWithUrls } from "../components/shared/FormsValidator";
import { convertStringToObject, sanitizeFloat, sanitizeForSending } from "../constants/utils";

export const prepareTourPointToSend = async (data) => {
  const newBody = JSON.parse(JSON.stringify(data));
  const bodyToSend = await transformObjectWithUrls(newBody);
  bodyToSend.long_description = sanitizeForSending(bodyToSend.long_description);

  bodyToSend.pdf_info = await sendPdfInBase64(bodyToSend.pdf_info);

  bodyToSend.latitude = sanitizeFloat(bodyToSend.latitude);
  bodyToSend.longitude = sanitizeFloat(bodyToSend.longitude);

  if (Array.isArray(bodyToSend.image_gallery_urls)) {
    bodyToSend.image_gallery_urls = bodyToSend.image_gallery_urls.join(";");
  }

  if (Array.isArray(bodyToSend.tour_point_language_infos)) {
    bodyToSend.tour_point_language_infos = await Promise.all(
      bodyToSend.tour_point_language_infos.map(async (tourPointLanguageInfo) => {
        tourPointLanguageInfo.long_description = sanitizeForSending(
          tourPointLanguageInfo.long_description
        );
        tourPointLanguageInfo.tour_point_id = bodyToSend.id || null;
        tourPointLanguageInfo.id = tourPointLanguageInfo.id || null;
        tourPointLanguageInfo.pdf_info = await sendPdfInBase64(tourPointLanguageInfo.pdf_info);
        return tourPointLanguageInfo;
      })
    );
  }

  bodyToSend.city_id = bodyToSend.city_id || null;

  return bodyToSend;
};

export const prepareTourPointToReceive = (data) => {
  const newBody = JSON.parse(JSON.stringify(data));

  newBody.long_description = convertStringToObject(newBody.long_description);

  if (Array.isArray(newBody.tour_point_language_infos)) {
    newBody.tour_point_language_infos = newBody.tour_point_language_infos.map(
      (tourPointLanguageInfo) => {
        tourPointLanguageInfo.long_description = convertStringToObject(
          tourPointLanguageInfo.long_description
        );
        return tourPointLanguageInfo;
      }
    );
  }

  newBody.image_gallery_urls = newBody.image_gallery_urls?.split(";");

  newBody.city_id = newBody.city_id || "";
  newBody.country_code = newBody.country_code || "";
  newBody.region_code = newBody.region_code || "";
  newBody.owner_premise_id = newBody.owner_premise_id || "";
  newBody.domains_ids = newBody.domains_ids || [];

  return newBody;
};
