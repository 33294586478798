import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AVAILABLE_LANGUAGES } from "../../../constants/variables";
import { DEFAULT_EVENT_LANGUAGE_INFO } from "../../../schemas/trips";
import SelectHookForm from "../../shared/SelectHookForm";

const TripLanguages = () => {
  const { t } = useTranslation();
  const {
    watch,
    getValues,
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const eventLanguages = watch("event_language_infos");

  const checkLanguage = (idiom) => {
    const translations = eventLanguages || [];
    return translations?.some((info) => info.language === idiom) || false;
  };

  const handleChangeCheckBox = (e, value) => {
    const isChecked = e.target.checked;
    const newLanguage = {
      ...DEFAULT_EVENT_LANGUAGE_INFO,
      language: value,
    };
    const prevLanguages = getValues("event_language_infos") || [];
    if (isChecked) {
      setValue("event_language_infos", [...prevLanguages, newLanguage]);
    } else {
      setValue(
        "event_language_infos",
        prevLanguages?.filter((info) => info.language !== value)
      );
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h6">{t("LANGUAGES_TRIP_TITLE")}</Typography>
        <Typography variant="body1">{t("LANGUAGES_TRIP_TITLE_DESCRIPTION")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Accordion
          sx={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid var(--grey-cancelled)",
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Grid container alignItems="center">
              <Typography>{t("TRIP_LANGUAGES")}</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <SelectHookForm
                name="default_language"
                control={control}
                label={t("DEFAULT_LANGUAGE")}
                required={true}
                error={errors?.default_language}
                defaultValue={""}
                options={AVAILABLE_LANGUAGES.map((lang) => (
                  <MenuItem key={lang.id} value={lang.id}>
                    {t(lang.name)}
                  </MenuItem>
                ))}
              />

              <Accordion
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid var(--grey-cancelled)",
                  boxShadow: "none",
                  marginTop: 2,
                  width: "100%",
                }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Grid container alignItems="center">
                    <Typography>{t("TRIP_LANGUAGES_AVAILABLE")}</Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {AVAILABLE_LANGUAGES.filter(
                    (lang) => lang.id !== getValues("default_language")
                  ).map((lang, index) => (
                    <Grid item xs={12} key={index} container alignItems="center">
                      <Checkbox
                        className="checkbox-oniria"
                        checked={checkLanguage(lang.id)}
                        onChange={(e) => handleChangeCheckBox(e, lang.id)}
                      />
                      <Typography>{t(lang.name)}</Typography>
                    </Grid>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default TripLanguages;
