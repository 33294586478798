import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryAssignDomains } from "../../../hooks/queries/useQueryAssignDomains";
import { focusColor } from "../../shared/textFieldStyle";
import InfoToolTip from "../../shared/InfoToolTip";

const TripCategoriesDomain = () => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const { data: domainCategories } = useQueryAssignDomains();
  const [selectedDomains, setSelectedDomains] = useState([]);

  const {
    field: { value: eventCategories = [] },
  } = useController({
    name: "event_categories",
    control,
  });

  const handleChangeCategory = (categoryId, categoryName, isChecked) => {
    const currentCategories = [...eventCategories];
    const categoryIndex = currentCategories.findIndex((c) => c.category_id === categoryId);

    if (isChecked && categoryIndex === -1) {
      currentCategories.push({ category_id: categoryId, category_name: categoryName });
    } else if (!isChecked && categoryIndex !== -1) {
      currentCategories.splice(categoryIndex, 1);
    }

    setValue("event_categories", currentCategories);
  };

  const handleChangeSubcategory = (subcategoryId, subcategoryName, isChecked) => {
    const currentCategories = [...eventCategories];
    const subcategoryIndex = currentCategories.findIndex((c) => c.subcategory_id === subcategoryId);

    if (isChecked && subcategoryIndex === -1) {
      currentCategories.push({ subcategory_id: subcategoryId, subcategory_name: subcategoryName });
    } else if (!isChecked && subcategoryIndex !== -1) {
      currentCategories.splice(subcategoryIndex, 1);
    }

    setValue("event_categories", currentCategories);
  };

  const isCategoryChecked = (category) => {
    return eventCategories.some((c) => c.category_id === category.id);
  };

  const isSubcategoryChecked = (subcategory) => {
    return eventCategories.some((c) => c.subcategory_id === subcategory.id);
  };

  const handleDomainChange = (domainId, isChecked) => {
    setSelectedDomains((prev) => {
      if (isChecked) {
        return [...prev, domainId];
      }
      return prev.filter((id) => id !== domainId);
    });
  };

  const renderCategories = (categories) =>
    categories?.map((category) => (
      <Box key={category.id} sx={{ ml: 3 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isCategoryChecked(category)}
              onChange={(e) => handleChangeCategory(category.id, category.name, e.target.checked)}
              className="checkbox-oniria"
            />
          }
          label={category.name}
        />
        {category.subcategories?.map((subcategory) => (
          <Box key={subcategory.id} sx={{ ml: 6 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSubcategoryChecked(subcategory)}
                  onChange={(e) =>
                    handleChangeSubcategory(subcategory.id, subcategory.name, e.target.checked)
                  }
                  className="checkbox-oniria"
                />
              }
              label={subcategory.name}
            />
          </Box>
        ))}
      </Box>
    ));

  return (
    <Grid item xs={12}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography>{t("CATEGORIES_AND_DOMAINS")}</Typography>
        <InfoToolTip text={t("CATEGORIES_DESCRIPTION")} />
      </Box>
      <FormControl fullWidth sx={focusColor}>
        {domainCategories?.length > 0 && (
          <Accordion
            sx={{
              border: "1px solid",
              borderColor: "grey.400",
              borderRadius: "4px",
              boxShadow: "none",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                minHeight: 48,
                px: 1,
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                },
              }}
            >
              <Typography>{t("DOMAIN_CATEGORIES")}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                px: 2,
                pb: 1,
                maxHeight: 300,
                overflowY: "auto",
              }}
            >
              {domainCategories.map((domain) => (
                <Fragment key={domain.id}>
                  <Grid>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedDomains.includes(domain.id)}
                          onChange={(e) => handleDomainChange(domain.id, e.target.checked)}
                          className="checkbox-oniria"
                        />
                      }
                      label={<Typography>{domain.name}</Typography>}
                    />
                  </Grid>
                  {selectedDomains.includes(domain.id) && (
                    <FormGroup>{renderCategories(domain.categories)}</FormGroup>
                  )}
                </Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        )}
      </FormControl>
    </Grid>
  );
};

export default TripCategoriesDomain;
