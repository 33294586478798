export default async function prepareDuplicateExtras(extras, ids) {
  const newExtras = extras.map((extra) => {
    const newExtra = JSON.parse(JSON.stringify(extra));
    newExtra.id = null;
    delete newExtra.event_id;
    delete newExtra.tickets_ids;
    delete newExtra.created_at;
    delete newExtra.last_modified_at;

    newExtra.initial_stock = newExtra.initial_stock || 0;

    //LANGUAGES
    if (newExtra.extra_language_infos && newExtra.extra_language_infos.length > 0) {
      newExtra.extra_language_infos = newExtra.extra_language_infos.map((languageInfo) => {
        languageInfo.id = null;
        languageInfo.extra_id = null;
        return languageInfo;
      });
    }

    // HOTELS -> change prev ids to new ids
    if (newExtra.hotel_ids && newExtra.hotel_ids.length > 0) {
      newExtra.hotel_ids = newExtra.hotel_ids.map((hotelId) => {
        return ids[hotelId];
      });
    }

    return newExtra;
  });
  return newExtras;
}
