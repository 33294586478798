import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TagsForm from "./components/blog/Tags/TagsForm";
import ExtrasForm from "./components/extras/ExtrasForm";
import ExtraStatistics from "./components/extras/ExtraStatistics";
import HotelForm from "./components/hotels/HotelForm";
import HotelDetail from "./pages/hotels/HotelDetail";
import TourLeaderForm from "./components/tourLeaders/TourLeaderForm";
import TourPointForm from "./components/tourPoints/TourPointForm";
import DomainAdmins from "./components/whiteLabel/editorUsers/DomainAdmins";
import { ROUTES } from "./constants/routes";
import { ROLES } from "./constants/variables";
import "./index.css";
import AccountSuccess from "./pages/AccountSuccess";
import AdminDetail from "./pages/admins/AdminDetail";
import AdminList from "./pages/admins/AdminList";
import CreateAdmin from "./pages/admins/CreateAdmin";
import Billing from "./pages/Billing/Billing";
import BillingPlus from "./pages/Billing/BillingPlus";
import Blog from "./pages/blog/Blog";
import BlogDetail from "./pages/blog/BlogDetail";
import BlogForm from "./pages/blog/BlogForm";
import CalendarEvents from "./pages/CalendarEvents";
import Cards from "./pages/cards/Cards";
import Categories from "./pages/categories/Categories";
import CreateAccount from "./pages/CreateAccount";
import Error404 from "./pages/Error404";
import CreateAssistant from "./pages/events/CreateAssistant";
import EventDetail from "./pages/events/EventDetail";
import Events from "./pages/events/Events";
import CreateEvent from "./pages/events/partys/CreateEvent";
import CreateTrip from "./pages/events/trips/CreateTrip";
import Trips from "./pages/events/trips/Trips";
import EventStatistics from "./pages/EventStatistics";
import ExtrasPage from "./pages/extras/ExtrasPage";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import HotelsPage from "./pages/hotels/HotelsPage";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import Orders from "./pages/orders/Orders";
import CreateOrganization from "./pages/organizations/CreateOrganization";
import OrganizationDetail from "./pages/organizations/OrganizationDetail";
import Organizations from "./pages/organizations/Organizations";
import ValidationOrganizations from "./pages/organizations/ValidationOrganizations";
import CreatePremise from "./pages/premises/CreatePremise";
import MemberCard from "./pages/premises/MemberCard";
import PremiseDetail from "./pages/premises/PremiseDetail";
import PremisePendingUsers from "./pages/premises/PremisePendingUsers";
import Premises from "./pages/premises/Premises";
import ValidationPremises from "./pages/premises/ValidationPremises";
import RefundList from "./pages/refunds/RefundList";
import ResetPassword from "./pages/ResetPassword";
import CreateRrppUser from "./pages/rrpps/CreateRrppUser";
import EditRrpp from "./pages/rrpps/EditRrpp";
import RrppDetail from "./pages/rrpps/RrppDetail";
import RrppsList from "./pages/rrpps/RrppsList";
import Statistics from "./pages/Statistics";
import AppStyles from "./pages/styles/AppStyles";
import Tags from "./pages/tags/Tags";
import TourLeadersPage from "./pages/tourLeaders/TourLeadersPage";
import TourPointsPage from "./pages/tourPoints/TourPointsPage";
import CreateUser from "./pages/users/CreateUser";
import PendingUsers from "./pages/users/PendingUsers";
import UserDetail from "./pages/users/UserDetail";
import UserInfo from "./pages/users/UserInfo";
import UsersList from "./pages/users/UsersList";
import Validate from "./pages/Validate";
import ValidateApp from "./pages/ValidateApp";
import VerifyUop from "./pages/VerifyUop";
import WhiteLabel from "./pages/whiteLabel/WhiteLabel";
import TransportsPage from "./pages/transports/TransportPage";
import TransportForm from "./components/transports/TransportForm";
import Tpv from "./pages/tpv/Tpv";
import Wikibliss from "./pages/Wikibliss";
import TripPlusForm from "./components/tripPlus/TripPlusForm";

export const App = () => {
  const userInfo = useSelector((state) => state.userInfo);

  const authToken = userInfo.token;
  const rol = userInfo.roles;
  const canPublishTrips = userInfo.trips;

  const isAuthenticated = () => {
    if (authToken !== null && authToken !== "") {
      return true;
    } else return false;
  };

  const getUserRole = () => {
    if (rol !== null && rol.length > 0) {
      return rol;
    } else return null;
  };

  const PrivateRoute = ({ path, element, requiredRole }) => {
    const userIsAuthenticated = isAuthenticated();
    const userRole = getUserRole();

    if (!userIsAuthenticated) {
      return <Navigate to="/login" />;
    }
    if (requiredRole && !requiredRole.some((rol) => userRole.includes(rol))) {
      return <Navigate to="/404" />;
    }
    if (path === "trips" && !canPublishTrips && !userRole.includes(ROLES.SUPER_ADMIN)) {
      return <Navigate to="/404" />;
    }
    return element;
  };

  const ProtectedLogin = () => {
    const userIsAuthenticated = isAuthenticated();
    if (userIsAuthenticated) {
      return <Navigate to="/home" />;
    }
    return <Login />;
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ zIndex: "100001" }}
      />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<PrivateRoute element={<Home />} />} />
          <Route path={ROUTES.HOME} exact element={<PrivateRoute element={<Home />} />} />
          <Route path={ROUTES.WIKIBLISS} exact element={<PrivateRoute element={<Wikibliss />} />} />
          <Route
            path={ROUTES.CALENDAR}
            exact
            element={<PrivateRoute element={<CalendarEvents />} />}
          />
          <Route path={ROUTES.BLOG} exact element={<PrivateRoute element={<Blog />} />} />
          <Route
            path={ROUTES.BLOG_DETAIL}
            exact
            element={<PrivateRoute element={<BlogDetail />} />}
          />
          <Route
            path={ROUTES.CREATE_BLOG_POST}
            exact
            element={
              <PrivateRoute
                element={<BlogForm isEditMode={false} />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.EDIT_BLOG_POST}
            exact
            element={
              <PrivateRoute
                element={<BlogForm isEditMode />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.DUPLICATE_BLOG}
            exact
            element={
              <PrivateRoute
                element={<BlogForm isDuplicateMode />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route path={ROUTES.TAGS} exact element={<PrivateRoute element={<Tags />} />} />
          <Route
            path={ROUTES.CREATE_TAG}
            exact
            element={
              <PrivateRoute
                element={<TagsForm />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.EDIT_TAG}
            exact
            element={
              <PrivateRoute
                element={<TagsForm />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.CREATE_EXTRA}
            exact
            element={
              <PrivateRoute
                element={<ExtrasForm />}
                requiredRole={[
                  ROLES.SUPER_ADMIN,
                  ROLES.MANAGER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.OFFICE_ADMIN_CAN_EDIT,
                  ROLES.AFFILIATED,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.EDIT_EXTRA}
            exact
            element={
              <PrivateRoute
                element={<ExtrasForm />}
                requiredRole={[
                  ROLES.SUPER_ADMIN,
                  ROLES.MANAGER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.AFFILIATED,
                  ROLES.OFFICE_ADMIN_CAN_EDIT,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.EXTRA_EVENTS}
            exact
            element={
              <PrivateRoute
                element={<ExtraStatistics />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.STATISTICS}
            exact
            element={
              <PrivateRoute
                element={<Statistics />}
                requiredRole={[
                  ROLES.SUPER_ADMIN,
                  ROLES.MANAGER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.OFFICE_ADMIN_CAN_EDIT,
                  ROLES.OFFICE_ADMIN,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.ORGANIZATIONS}
            exact
            element={<PrivateRoute element={<Organizations />} />}
          />
          <Route
            path={ROUTES.VALIDATE_ORGANIZATIONS}
            exact
            element={
              <PrivateRoute
                element={<ValidationOrganizations />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.CREATE_ORGANIZATION}
            exact
            element={
              <PrivateRoute
                element={<CreateOrganization />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.ORGANIZATION_DETAIL}
            exact
            element={<PrivateRoute element={<OrganizationDetail />} />}
          />
          <Route path={ROUTES.PREMISES} exact element={<PrivateRoute element={<Premises />} />} />
          <Route
            path={ROUTES.VALIDATE_PREMISES}
            exact
            element={
              <PrivateRoute element={<ValidationPremises />} requiredRole={[ROLES.SUPER_ADMIN]} />
            }
          />
          <Route
            path={ROUTES.CREATE_PREMISE}
            exact
            element={
              <PrivateRoute
                element={<CreatePremise />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.PREMISE_DETAIL}
            exact
            element={<PrivateRoute element={<PremiseDetail />} />}
          />
          <Route
            path={ROUTES.PREMISE_PENDING_USERS}
            exact
            element={
              <PrivateRoute
                element={<PremisePendingUsers />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.AFFILIATED]}
              />
            }
          />
          <Route
            path={ROUTES.PREMISE_MEMBER_CARD}
            exact
            element={
              <PrivateRoute
                element={<MemberCard />}
                requiredRole={[
                  ROLES.SUPER_ADMIN,
                  ROLES.MANAGER_ADMIN,
                  ROLES.AFFILIATED,
                  ROLES.ADMIN,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.ADMINS}
            exact
            element={
              <PrivateRoute
                element={<AdminList />}
                requiredRole={[
                  ROLES.SUPER_ADMIN,
                  ROLES.MANAGER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.AFFILIATED,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.ADMIN_DETAIL}
            exact
            element={
              <PrivateRoute
                element={<AdminDetail />}
                requiredRole={[
                  ROLES.SUPER_ADMIN,
                  ROLES.MANAGER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.AFFILIATED,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.CREATE_ADMIN}
            exact
            element={
              <PrivateRoute
                element={<CreateAdmin />}
                requiredRole={[
                  ROLES.SUPER_ADMIN,
                  ROLES.MANAGER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.AFFILIATED,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.CREATE_ADMIN_SIMPLE}
            exact
            element={
              <PrivateRoute
                element={<CreateAdmin isAdminSimple />}
                requiredRole={[
                  ROLES.SUPER_ADMIN,
                  ROLES.MANAGER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.AFFILIATED,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.USERS}
            exact
            element={
              <PrivateRoute
                element={<UsersList />}
                requiredRole={[
                  ROLES.SUPER_ADMIN,
                  ROLES.AFFILIATED,
                  ROLES.ADMIN,
                  ROLES.OFFICE_ADMIN,
                  ROLES.OFFICE_ADMIN_CAN_EDIT,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.USER_DETAIL}
            exact
            element={
              <PrivateRoute
                element={<UserDetail />}
                requiredRole={[
                  ROLES.SUPER_ADMIN,
                  ROLES.AFFILIATED,
                  ROLES.ADMIN,
                  ROLES.OFFICE_ADMIN,
                  ROLES.OFFICE_ADMIN_CAN_EDIT,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.USER_INFO}
            exact
            element={
              <PrivateRoute
                element={<UserInfo />}
                requiredRole={[
                  ROLES.SUPER_ADMIN,
                  ROLES.AFFILIATED,
                  ROLES.ADMIN,
                  ROLES.OFFICE_ADMIN,
                  ROLES.OFFICE_ADMIN_CAN_EDIT,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.CREATE_USER}
            exact
            element={
              <PrivateRoute
                element={<CreateUser />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.AFFILIATED]}
              />
            }
          />
          <Route
            path={ROUTES.CREATE_USER_SIMPLE}
            exact
            element={
              <PrivateRoute
                element={<CreateUser isUserSimple />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.AFFILIATED]}
              />
            }
          />
          <Route
            path={ROUTES.PENDING_USERS}
            exact
            element={
              <PrivateRoute
                element={<PendingUsers />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.AFFILIATED]}
              />
            }
          />
          <Route path={ROUTES.EVENTS} exact element={<PrivateRoute element={<Events />} />} />
          <Route
            path={ROUTES.CREATE_EVENT}
            exact
            element={<PrivateRoute element={<CreateEvent />} />}
          />
          <Route
            path={ROUTES.CREATE_EVENT_WITH_ID}
            exact
            element={<PrivateRoute element={<CreateEvent />} />}
          />
          <Route
            path={ROUTES.TRIPS}
            exact
            element={<PrivateRoute element={<Trips />} path="trips" />}
          />
          <Route
            path={ROUTES.EVENT_DETAIL}
            exact
            element={<PrivateRoute element={<EventDetail />} />}
          />
          <Route
            path={ROUTES.ADD_EXTRA_EVENT}
            exact
            element={<PrivateRoute element={<EventDetail />} />}
          />
          <Route
            path={ROUTES.ADD_ASSISTANT}
            exact
            element={<PrivateRoute element={<CreateAssistant />} />}
          />
          <Route
            path={ROUTES.CREATE_TRIP}
            exact
            element={<PrivateRoute element={<CreateTrip path="trips" />} />}
          />
          <Route
            path={ROUTES.CREATE_TRIP_WITH_ID}
            exact
            element={<PrivateRoute element={<CreateTrip path="trips" />} />}
          />
          <Route
            path={ROUTES.CREATE_ACTIVITY}
            exact
            element={<PrivateRoute element={<CreateTrip path="trips" />} />}
          />
          <Route
            path={ROUTES.SALES}
            exact
            element={
              <PrivateRoute
                element={<Billing />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.BILLING}
            exact
            element={
              <PrivateRoute
                element={<BillingPlus />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.REFUNDS}
            exact
            element={
              <PrivateRoute
                element={<RefundList />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route path={ROUTES.RRPPS} exact element={<PrivateRoute element={<RrppsList />} />} />
          <Route
            path={ROUTES.RRPP_DETAIL}
            exact
            element={<PrivateRoute element={<RrppDetail />} />}
          />
          <Route
            path={ROUTES.CREATE_RRPP}
            exact
            element={<PrivateRoute element={<CreateRrppUser />} />}
          />
          <Route path={ROUTES.EDIT_RRPP} exact element={<PrivateRoute element={<EditRrpp />} />} />
          <Route
            path={ROUTES.DOMAINS}
            element={
              <PrivateRoute
                element={<WhiteLabel />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.DOMAIN_ADMINS}
            element={
              <PrivateRoute
                element={<DomainAdmins />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.APP_STYLES}
            element={
              <PrivateRoute
                element={<AppStyles />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.TPV}
            element={
              <PrivateRoute
                element={<Tpv />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.ORDERS}
            element={
              <PrivateRoute
                element={<Orders />}
                requiredRole={[
                  ROLES.SUPER_ADMIN,
                  ROLES.MANAGER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.OFFICE_ADMIN_CAN_EDIT,
                  ROLES.OFFICE_ADMIN,
                ]}
              />
            }
          />
          <Route path={ROUTES.CARDS} element={<PrivateRoute element={<Cards />} />} />
          <Route path={ROUTES.CATEGORIES} element={<PrivateRoute element={<Categories />} />} />
          <Route
            path={ROUTES.EVENT_STATISTICS}
            element={
              <PrivateRoute
                element={<EventStatistics />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route path={ROUTES.EXTRAS} element={<PrivateRoute element={<ExtrasPage />} />} />
          <Route
            path={ROUTES.TOUR_POINTS}
            element={<PrivateRoute element={<TourPointsPage />} />}
          />
          <Route
            path={ROUTES.CREATE_TOUR_POINT}
            element={<PrivateRoute element={<TourPointForm />} />}
          />
          <Route
            path={ROUTES.EDIT_TOUR_POINT}
            element={<PrivateRoute element={<TourPointForm />} />}
          />
          {/* <Route path={ROUTES.TRANSPORTS} element={<PrivateRoute element={<TransportsPage />} />} />
          <Route
            path={ROUTES.CREATE_TRANSPORT}
            element={<PrivateRoute element={<TourPointForm />} />}
          />
          <Route
            path={ROUTES.EDIT_TRANSPORT}
            element={<PrivateRoute element={<TourPointForm />} />}
          /> */}
          <Route path={ROUTES.HOTELS} element={<PrivateRoute element={<HotelsPage />} />} />
          <Route path={ROUTES.CREATE_HOTEL} element={<PrivateRoute element={<HotelForm />} />} />
          <Route
            path={ROUTES.CREATE_TRIP_HOTEL}
            element={<PrivateRoute element={<HotelForm isForTrip />} />}
          />
          <Route
            path={ROUTES.EDIT_HOTEL_TRIP}
            element={<PrivateRoute element={<HotelForm isForTrip />} />}
          />
          <Route
            path={ROUTES.VIEW_HOTEL}
            element={<PrivateRoute element={<HotelDetail isForTrip />} />}
          />
          <Route path={ROUTES.EDIT_HOTEL} element={<PrivateRoute element={<HotelForm />} />} />
          <Route
            path={ROUTES.TOUR_LEADERS}
            element={<PrivateRoute element={<TourLeadersPage />} />}
          />
          <Route
            path={ROUTES.CREATE_TOUR_LEADER}
            element={<PrivateRoute element={<TourLeaderForm />} />}
          />
          <Route
            path={ROUTES.EDIT_TOUR_LEADER}
            element={<PrivateRoute element={<TourLeaderForm />} />}
          />
          <Route path={ROUTES.TRANSPORTS} element={<PrivateRoute element={<TransportsPage />} />} />
          <Route
            path={ROUTES.CREATE_TRANSPORT}
            element={<PrivateRoute element={<TransportForm />} />}
          />
          <Route
            path={ROUTES.CREATE_TRIP_TRANSPORT}
            element={<PrivateRoute element={<TransportForm isTrip />} />}
          />
          <Route
            path={ROUTES.EDIT_TRANSPORT}
            element={<PrivateRoute element={<TransportForm />} />}
          />
          <Route
            path={ROUTES.EDIT_TRIP_TRANSPORT}
            element={<PrivateRoute element={<TransportForm isTrip />} />}
          />
          <Route
            path={ROUTES.CREATE_TRIP_PLUS}
            element={<PrivateRoute element={<TripPlusForm />} />}
          />
          <Route
            path={ROUTES.DUPLICATE_TRIP_PLUS}
            element={<PrivateRoute element={<TripPlusForm />} />}
          />
          <Route
            path={ROUTES.CREATE_CHILD_TRIP}
            element={<PrivateRoute element={<TripPlusForm isChild />} />}
          />
        </Route>

        <Route path="*" element={<PrivateRoute element={<Error404 />} />} />
        <Route path={ROUTES.LOGIN} element={<ProtectedLogin />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.CREATE_ACCOUNT} element={<CreateAccount />} />
        <Route path={ROUTES.ERROR_404} element={<Error404 />} />
        <Route path={ROUTES.ACCOUNT_SUCCESS} element={<AccountSuccess />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={ROUTES.VERIFY_DASHBOARD} element={<Validate />} />
        <Route path={ROUTES.VERIFY_APP} element={<ValidateApp />} />
        <Route path={ROUTES.VERIFY_UOP} element={<VerifyUop />} />
      </Routes>
    </>
  );
};
