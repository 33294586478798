import { Close, EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Avatar, Card, CardContent, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useQueryTourLeaderDetails from "../../../hooks/queries/tourLeaders/useQueryTourLeaderDetails";

const AssignedTourLeaderCard = ({ tourLeaderId, handleDelete }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: leader } = useQueryTourLeaderDetails(tourLeaderId, () => {});

  const { name, avatar_url } = leader || {};

  const handleEdit = (id) => {
    navigate(`/tour-leaders/edit/${id}`);
  };

  return (
    <Card sx={{ maxWidth: 345, position: "relative" }}>
      <IconButton
        onClick={() => {
          handleDelete(tourLeaderId);
        }}
        sx={{
          position: "absolute",
          top: 2,
          right: 2,
        }}
      >
        <Close />
      </IconButton>

      <CardContent>
        <Grid container gap={2} alignItems="center">
          <Avatar alt={name} src={avatar_url} sx={{ width: 50, height: 50 }} />
          <Typography variant="h6" component="div" sx={{ marginTop: 1 }}>
            {name}
          </Typography>
          <Tooltip title={t("EDIT_TOUR_LEADER")} sx={{ mt: 1 }}>
            <IconButton onClick={() => handleEdit(tourLeaderId)}>
              <EditOutlined sx={{ color: "var(--oniria-gold)" }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AssignedTourLeaderCard;
