import { Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DEFAULT_EVENT_LANGUAGE } from "../../../constants/variables";
import { focusColor } from "../../shared/textFieldStyle";
import TextEditor from "../../textEditor/TextEditor";
import { checkRenderEditorJs } from "../../textEditor/tools";
import EmailsEditor from "../../emailEditor/EmailEditor";
import { useRef } from "react";
import { convertStringToObject } from "../../../constants/utils";

const ExtraDescription = ({
  checkExtraField,
  index,
  extras,
  errors,
  sendButtonClicked,
  isDisabled,
  extra_language_infos,
  handleChangeLanguageParam,
  selectedLanguage,
  defaultLanguage = DEFAULT_EVENT_LANGUAGE,
}) => {
  const { t } = useTranslation();
  const extra = extras[index];
  const error = errors[index];
  const emailEditorRefs = useRef([]);

  const selectedLanguageIndex = extra_language_infos?.findIndex(
    (language) => language.language === selectedLanguage
  );

  const handleChangeDescription = (data, name) => {
    const e = { target: { value: data } };
    checkExtraField(name, e, /^.[\s\S]{0,5000000}$/, t("MAX_1000"), true, index);
  };

  const isEmailEditor = () => {
    const { extra_template_id, description, description_design } = extra || {};

    const isDescriptionEmpty = !description || description === "";
    const isDescriptionDesignEmpty = !description_design || description_design === "";

    if (extra_template_id) return true;
    if (isDescriptionEmpty && isDescriptionDesignEmpty) return true;

    if (typeof description === "string" && description.includes("DOCTYPE")) return true;
    return !!description_design;
  };

  return (
    <Grid item xs={12}>
      <Typography>{t("DESCRIPTION")}</Typography>
      {selectedLanguage === defaultLanguage ? (
        isEmailEditor() ? (
          <EmailsEditor
            ref={(el) => el && (emailEditorRefs.current[0] = el)}
            initialDesign={convertStringToObject(extra?.description_design)}
            onExportHtml={(html) => handleChangeDescription(html, "description")}
            onDesignChange={(design) => handleChangeDescription(design, "description_design")}
            key={`editor-extra-event-${defaultLanguage}`}
          />
        ) : checkRenderEditorJs("", extra.description) ? (
          <>
            <TextEditor
              data={extra.description}
              setData={(value) => handleChangeDescription(value, "description")}
              id={`extra-description-${index}`}
              disabled={isDisabled}
            />
            {error.description !== "" && error.description !== "empty" ? (
              <p style={{ color: "red" }}>{error.description}</p>
            ) : (
              ""
            )}
          </>
        ) : (
          <TextField
            onChange={(e) =>
              checkExtraField(
                "description",
                e,
                /^(?!.*[|\\#·$~%¬^[\]*{}_])[\s\S]{1,1000}$/,
                t("MAX_1000"),
                true,
                index
              )
            }
            value={extra.description}
            margin="normal"
            fullWidth
            multiline
            rows={4}
            id="description"
            name="description"
            label={t("DESCRIPTION")}
            autoComplete="description"
            error={
              (error.description !== "" && error.description !== "empty") ||
              (error.description === "empty" && sendButtonClicked)
            }
            helperText={error.description !== "empty" ? error.description : ""}
            disabled={isDisabled}
            sx={focusColor}
          />
        )
      ) : isEmailEditor() ? (
        <EmailsEditor
          ref={(el) => el && (emailEditorRefs.current[selectedLanguageIndex] = el)}
          initialDesign={convertStringToObject(
            extra_language_infos[selectedLanguageIndex]?.description_design
          )}
          onExportHtml={(html) =>
            handleChangeLanguageParam(html, "description", selectedLanguageIndex)
          }
          onDesignChange={(design) =>
            handleChangeLanguageParam(design, "description_design", selectedLanguageIndex)
          }
          key={`editor-extra-event-${defaultLanguage}-${selectedLanguageIndex}`}
        />
      ) : checkRenderEditorJs("", extra_language_infos[selectedLanguageIndex]?.description) ? (
        <TextEditor
          data={extra_language_infos[selectedLanguageIndex]?.description}
          setData={(data) => handleChangeLanguageParam(data, "description", selectedLanguageIndex)}
          id={`extra-description-${selectedLanguage}-${index}`}
          disabled={isDisabled}
        />
      ) : (
        <TextField
          onChange={(e) =>
            handleChangeLanguageParam(e.target.value, "description", selectedLanguageIndex)
          }
          value={extra_language_infos[selectedLanguageIndex]?.description}
          margin="normal"
          fullWidth
          multiline
          rows={4}
          id="description"
          name="description"
          label={t("DESCRIPTION")}
          autoComplete="description"
          disabled={isDisabled}
          sx={focusColor}
        />
      )}
    </Grid>
  );
};

export default ExtraDescription;
