import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { itineraryErrorsAllOk } from "../../../../classes/tripClass";
import CustomDialog from "../../../shared/CustomDialog";
import { focusColor } from "../../../shared/textFieldStyle";
import TextEditor from "../../../textEditor/TextEditor";

const Itinerary = ({
  index,
  handleRemoveItinerary,
  itinerary,
  buttonClicked,
  handleItineraryChange,
  itinerariesErrors,
  setItinerariesErrors,
  formData,
}) => {
  const { t } = useTranslation();
  const { title, description, subtitle } = itinerary;
  const [open, setOpen] = useState(false);

  if (!itinerariesErrors[index]) itinerariesErrors[index] = itineraryErrorsAllOk;

  const handleChangeDescription = (data) => {
    const newDescription = typeof data !== "string" ? JSON.stringify(data) : data;
    const e = { target: { value: newDescription } };
    handleChangeFormParamsAndErrors(
      "description",
      e,
      index,
      /^[\s\S]{0,10000000}$/,
      t("MAX_50000")
    );
  };

  const handleChangeFormParamsAndErrors = (key, event, index, regex, errorMessage) => {
    const inputValue = event.target.value;
    if (regex.test(inputValue)) {
      itinerariesErrors[index][key] = "";
    } else {
      itinerariesErrors[index][key] = errorMessage;
    }
    setItinerariesErrors(itinerariesErrors);
    handleItineraryChange(index, key, event);
    formData.moreInformation.itineraries[index][key] = inputValue;
  };

  const handleOpenDelete = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <Draggable draggableId={`draggable-${index}`} index={parseInt(index, 10)}>
      {(provided, snapshot) => (
        <Accordion
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            backgroundColor: snapshot.isDragging ? "var(--grey-cancelled)" : "",
            display: "flex",
            flexDirection: "column",
            border: "1px solid var(--grey-cancelled)",
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container flexDirection="row" justifyContent="space-between">
              <Grid item xs={6} container alignItems="center">
                <DragIndicatorIcon sx={{ marginRight: 2 }} />
                <Typography>{title ? title : t("TITLE")}</Typography>
              </Grid>
              <Grid item marginRight={2}>
                <IconButton onClick={handleOpenDelete} className="oniria-icon-btn-delete">
                  <DeleteOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) =>
                    handleChangeFormParamsAndErrors(
                      "title",
                      e,
                      index,
                      /^(?!.*[|\\#·$~%¬^[\]*{}_]).{0,80}$/,
                      t("MAX_80")
                    )
                  }
                  margin="normal"
                  fullWidth
                  value={title || ""}
                  id="title"
                  name="title"
                  autoComplete="title"
                  label={t("TITLE")}
                  error={
                    (itinerariesErrors[index]?.title !== "" &&
                      itinerariesErrors[index]?.title !== "empty") ||
                    (itinerariesErrors[index]?.title === "empty" && buttonClicked)
                  }
                  helperText={
                    itinerariesErrors[index]?.title !== "empty"
                      ? itinerariesErrors[index]?.title
                      : ""
                  }
                  sx={focusColor}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) =>
                    handleChangeFormParamsAndErrors("subtitle", e, index, /^.{0,80}$/, t("MAX_80"))
                  }
                  margin="normal"
                  fullWidth
                  value={subtitle || ""}
                  id="subtitle"
                  name="subtitle"
                  autoComplete="subtitle"
                  label={t("SUBTITLE")}
                  error={itinerariesErrors[index]?.subtitle !== ""}
                  helperText={itinerariesErrors[index]?.subtitle}
                  sx={focusColor}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                  {t("DESCRIPTION")}
                </InputLabel>
                <TextEditor
                  data={description ? description : ""}
                  setData={handleChangeDescription}
                  id={`itinerary-${index}-description`}
                />
                {itinerariesErrors[index]?.description !== "" && (
                  <Typography variant="caption" sx={{ color: "red" }}>
                    {itinerariesErrors[index]?.description
                      ? itinerariesErrors[index]?.description
                      : ""}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
          <CustomDialog
            isOpen={open}
            onClose={() => setOpen(false)}
            title={t("DELETE_ITINERARY")}
            content={t("DELETE_ITINERARY_CONFIRM")}
            onAccept={() => {
              handleRemoveItinerary(index);
              setOpen(false);
            }}
          />
        </Accordion>
      )}
    </Draggable>
  );
};

export default Itinerary;
