import { Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IVA_OPTIONS } from "../../../constants/variables";
import LanguageTabs from "../../shared/languages/LanguageTabs";
import SelectHookForm from "../../shared/SelectHookForm";
import { focusColor } from "../../shared/textFieldStyle";
import TripTicketDates from "./TripTicketDates";
import TripTicketSales from "./TripTicketSales";

const TripTicketConfig = ({ index }) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const ticketLanguages = watch(`tickets[${index}].ticket_language_infos`);
  const defaultLanguage = watch(`tickets[${index}].default_language`);
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const languageIndex = ticketLanguages?.findIndex((info) => info.language === selectedLanguage);

  const isTripMultiDate = watch("multi_date");

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography>{t("STOCK")}*</Typography>
        <TextField
          placeholder={"00"}
          {...register(`tickets.${index}.initial_stock`)}
          error={!!errors?.tickets?.[index]?.initial_stock}
          helperText={errors?.tickets?.[index]?.initial_stock?.message}
          fullWidth
          sx={focusColor}
          type="number"
          inputProps={{ min: 0 }}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>{t("PRICE")}*</Typography>
        <TextField
          placeholder={t("PRICE")}
          {...register(`tickets.${index}.price`)}
          error={!!errors?.tickets?.[index]?.price}
          helperText={errors?.tickets?.[index]?.price?.message}
          fullWidth
          sx={focusColor}
          type="number"
          inputProps={{ min: 0 }}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>{t("IVA")}*</Typography>
        <SelectHookForm
          name={`tickets.${index}.iva`}
          control={control}
          required={true}
          error={errors?.tickets?.[index]?.iva}
          defaultValue={""}
          placeholder="%"
          options={IVA_OPTIONS.map((iva) => (
            <MenuItem key={iva} value={iva}>
              {iva}%
            </MenuItem>
          ))}
        />
      </Grid>
      <Grid item xs={12}>
        <LanguageTabs
          languagesInfo={ticketLanguages}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          defaultLanguage={defaultLanguage}
        />
        <Typography>{t("POLICY")}</Typography>
        {selectedLanguage === defaultLanguage ? (
          <>
            <TextField
              placeholder={t("POLICY")}
              {...register(`tickets.${index}.tickets_policy`)}
              error={!!errors?.tickets?.[index]?.tickets_policy}
              helperText={errors?.tickets?.[index]?.tickets_policy?.message}
              fullWidth
              sx={focusColor}
            />
          </>
        ) : (
          <TextField
            placeholder={t("POLICY")}
            {...register(`tickets.${index}.ticket_language_infos.${languageIndex}.tickets_policy`)}
            error={!!errors?.tickets?.[index]?.tickets_policy}
            helperText={errors?.tickets?.[index]?.tickets_policy?.message}
            fullWidth
            sx={focusColor}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              {...register(`tickets.${index}.is_visible`)}
              checked={watch(`tickets.${index}.is_visible`)}
              className="checkbox-oniria"
            />
          }
          label={t("TICKET_VISIBLE")}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              {...register(`tickets.${index}.only_in_app`)}
              checked={watch(`tickets.${index}.only_in_app`)}
              className="checkbox-oniria"
            />
          }
          label={t("ONLY_VISIBLE_APP")}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              {...register(`tickets.${index}.only_international`)}
              checked={watch(`tickets.${index}.only_international`)}
              className="checkbox-oniria"
            />
          }
          label={t("ONLY_FOR_MEMBERS")}
        />
      </Grid>
      {isTripMultiDate && <TripTicketDates index={index} />}

      <TripTicketSales index={index} />
    </Grid>
  );
};

export default TripTicketConfig;
