import { Box } from "@mui/material";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Polyline, TileLayer } from "react-leaflet";

const generateLetterIcon = (texto) => {
  return L.divIcon({
    className: "custom-icon",
    html: `<div style="
          background-color: #007bff;
          color: white;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: bold;
          border: 2px solid white;">
          ${texto}
        </div>`,
    iconSize: [30, 30],
    iconAnchor: [15, 15],
  });
};

const RouteMap = ({ coordinates = [] }) => {
  const cleanCoordinates = coordinates?.filter((coordinate) => coordinate);
  const marks = cleanCoordinates?.map((_, index) => String.fromCharCode(65 + index));
  const totalMarks = marks.length;
  if (totalMarks === 0) {
    return null;
  }

  const mapCenter = cleanCoordinates
    .reduce(
      (acc, curr) => {
        return [acc[0] + parseFloat(curr[0]), acc[1] + parseFloat(curr[1])];
      },
      [0, 0]
    )
    .map((sum) => sum / totalMarks);

  return (
    <Box sx={{ height: "500px", width: "100%" }}>
      <MapContainer center={mapCenter} zoom={4} style={{ height: "100%", width: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {cleanCoordinates.map((pos, index) => (
          <Marker key={index} position={pos} icon={generateLetterIcon(marks[index])} />
        ))}

        <Polyline positions={cleanCoordinates} color="blue" />
      </MapContainer>
    </Box>
  );
};

export default RouteMap;
