import { Box, Button, Grid, Tab, Tabs, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { details, extra, moreInformation, ticket } from "../../classes/tripClass";
import Header from "../../components/Header";
import RrppTable from "../../components/RrppTable";
import EditEvent from "../../components/events/EditEvent";
import Orders from "../../components/events/Orders";
import ListAssistants from "../../components/events/listAssistants/ListAssistants";
import ScannerAssistant from "../../components/events/scanner/ScannerAssistant";
import Statistics from "../../components/events/statistics/Statistics";
import { objectWithTemplateKeys } from "../../components/shared/FormsValidator";
import { Loading } from "../../components/shared/Loading";
import { optionsValue } from "../../components/shared/OptionsMultiSelect";
import TripPlusForm from "../../components/tripPlus/TripPlusForm";
import { RQ_KEY } from "../../constants/query";
import { ROUTES } from "../../constants/routes";
import { MUI_SELECT_PRIM_COLOR } from "../../constants/styles";
import { getDateFromDate, getTimeFromDate } from "../../constants/utils";
import { EVENT_TABS, EVENTS_TYPE } from "../../constants/variables";
import useUserRoles from "../../hooks/useUserRoles";
import {
  getAllAssistants,
  getEventById,
  getExtrasByEventId,
  getTicketsByEventId,
} from "../../services/eventsServices";
import SelectDomain from "./SelectDomain";

const EventDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { eventId, extraId } = useParams();
  const [formData, setFormData] = useState({});
  const [eventName, setEventName] = useState("");
  const [valueTab, setValueTab] = useState(0);
  const [searchParam] = useSearchParams();
  const [qrResponse, setQrResponse] = useState();
  const { isScanner } = useUserRoles();

  const {
    selectedTab: selectedEventsTab,
    domains: userDomains = [],
    selectedDomain: stateSelectedDomain,
  } = useSelector((state) => state.b2bInfo);

  const selectedDomain = useMemo(() => {
    if (selectedEventsTab === EVENT_TABS.MY_EVENTS) {
      return stateSelectedDomain;
    }
    return stateSelectedDomain || userDomains.join(";");
  }, [selectedEventsTab, stateSelectedDomain, userDomains]);

  useEffect(() => {
    const numValue = searchParam.get("newValueTab");
    if (numValue) {
      setValueTab(Number(numValue));
    }
  }, []);

  // Query successes
  const handleEventQuerySuccess = async (data) => {
    //
    const moreInformationOrganized = objectWithTemplateKeys(data, moreInformation);
    moreInformationOrganized.included_options = includedOptionsComplete(
      moreInformationOrganized.included_options
    );
    data.is_visible = data.is_visible === 1;
    data.autovalidation = data.autovalidation === 1;
    data.is_international = data.is_international === 1;
    //
    const eventToSee = {
      id: data.id,
      event_type: data.event_type,
      details: objectWithTemplateKeys(data, details),
      moreInformation: moreInformationOrganized,
    };
    setFormData({ ...formData, ...eventToSee });
    setEventName(data.name);
  };

  const handleTicketsQuerySuccess = async (data) => {
    const processedTickets = [];
    for (let i = 0; i < data.length; i++) {
      const nextTicket = objectWithTemplateKeys(data[i], ticket, ["id", "event_id"]);
      nextTicket.is_visible = nextTicket.is_visible === 1;
      nextTicket.early_payment_discount_date_time = nextTicket.early_payment_discount_date
        ? nextTicket.early_payment_discount_date.split("T")[1].split(".")[0]
        : "";
      nextTicket.early_payment_discount_date = nextTicket.early_payment_discount_date
        ? nextTicket.early_payment_discount_date.split("T")[0]
        : "";
      nextTicket.start_date_time = getTimeFromDate(nextTicket.start_date);
      nextTicket.start_date = getDateFromDate(nextTicket.start_date);
      nextTicket.end_date_time = getTimeFromDate(nextTicket.end_date);
      nextTicket.end_date = getDateFromDate(nextTicket.end_date);
      nextTicket.second_payment_end_date_time = getTimeFromDate(nextTicket.second_payment_end_date);
      nextTicket.second_payment_end_date = getDateFromDate(nextTicket.second_payment_end_date);
      processedTickets.push(nextTicket);
    }
    //
    const ticketsToSee = {
      tickets: processedTickets,
    };
    setFormData({ ...formData, ...ticketsToSee });
  };

  const handleExtrasQuerySuccess = async (data) => {
    //
    const attributes = ["id", "event_id", "position", "extra_template_id"];
    const processedExtras = [];
    for (let i = 0; i < data.length; i++) {
      const newExtra = objectWithTemplateKeys(data[i], extra, attributes);
      processedExtras.push(newExtra);
    }
    //
    const extrasToSee = {
      extras: processedExtras,
    };
    setFormData({ ...formData, ...extrasToSee });
  };

  // Querys
  const { isLoading: isLoadingEvents, refetch: refetchEvent } = useQuery(
    ["event", eventId],
    () => getEventById(eventId),
    {
      onSuccess: handleEventQuerySuccess,
      refetchOnWindowFocus: false,
    }
  );
  const { isLoading: isLoadingTickets, refetch: refetchTickets } = useQuery(
    ["ticketsEventId", eventId, selectedDomain],
    () => getTicketsByEventId(eventId, selectedDomain),
    {
      onSuccess: handleTicketsQuerySuccess,
      refetchOnWindowFocus: false,
    }
  );
  const { isLoading: isLoadingExtras, refetch: refetchExtras } = useQuery(
    ["extras", eventId],
    () => getExtrasByEventId(eventId),
    {
      onSuccess: handleExtrasQuerySuccess,
      refetchOnWindowFocus: false,
    }
  );

  const { refetch: refetchAssistants, data: assistantsInfo } = useQuery(
    [RQ_KEY.ASSISTANTS_EVENT_ID, eventId, selectedDomain],
    () => getAllAssistants(eventId, selectedDomain),
    {
      refetchOnWindowFocus: false,
    }
  );

  const totalTicketConsumed =
    assistantsInfo?.data?.reduce((total, ticket) => {
      if (ticket.consumed_at) {
        total++;
      }
      return total;
    }, 0) || 0;

  const isTripType =
    formData.event_type === EVENTS_TYPE.TRIP || formData.event_type === EVENTS_TYPE.TRIP_COMPOSITE;
  // Breadcrumbs
  const breadcrumbs = [
    {
      name: isTripType ? t("TRAVELS") : t("EVENTS"),
      link: isTripType ? ROUTES.TRIPS : ROUTES.EVENTS,
    },
    {
      name: eventName ?? "",
      link: `/event/${formData.id}`,
    },
    {
      name:
        valueTab === 0
          ? selectedEventsTab === EVENT_TABS.MY_EVENTS
            ? t("EDIT")
            : t("SEE_EVENT")
          : valueTab === 1
          ? t("LIST_ATTENDEES")
          : valueTab === 2
          ? t("ORDERS")
          : valueTab === 3
          ? t("STATISTICS")
          : t("RRPP"),
    },
  ];

  const includedOptionsComplete = (includedOptionsValues) => {
    if (!includedOptionsValues || Array.isArray(includedOptionsValues)) return [];
    const parsedValues = JSON.parse(includedOptionsValues);
    const optionsToReturn = [];
    for (let i = 0; i < parsedValues.length; i++) {
      const optionToGet = optionsValue.find((obj) => obj.value === parsedValues[i]);
      optionsToReturn.push(optionToGet);
    }
    return optionsToReturn;
  };

  const handleChange = (_, newValue) => {
    setValueTab(newValue);
  };
  const createAssistant = () => {
    navigate(`/event/${formData.id}/add_assistant`);
  };

  useEffect(() => {
    if (qrResponse) {
      refetchAssistants();
    }
  }, [qrResponse]);

  // For when the queries are loading
  if (isLoadingEvents || isLoadingTickets || isLoadingExtras) {
    return <Loading />;
  }

  // If is not loading, but we have no details
  // Then we have to force data refetch
  if (!formData.details) {
    refetchEvent();
    return <></>;
  }
  if (!formData.tickets) {
    refetchTickets();
    return <></>;
  }
  if (!formData.extras) {
    refetchExtras();
    return <></>;
  }

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        description={
          valueTab === 1
            ? t("HEADER_LIST_ASSISTANT") +
              (isTripType
                ? t("TRIP")
                : formData.event_type === EVENTS_TYPE.ACTIVITY
                ? t("ACTIVITY")
                : t("EVENT"))
            : valueTab === 2
            ? t("HEADER_ORDERS") +
              (isTripType
                ? t("TRIP")
                : formData.event_type === EVENTS_TYPE.ACTIVITY
                ? t("ACTIVITY")
                : t("EVENT"))
            : ""
        }
      />
      <Grid container sx={{ display: "flex", flexDirection: "row-reverse", mb: 2 }}>
        <Grid item xs={12} container justifyContent="flex-end" gap={2}>
          <SelectDomain eventCategories={formData?.details?.event_categories || []} />
          <ScannerAssistant
            eventId={eventId}
            goBack={() => navigate(`${ROUTES.EVENT}/${eventId}`)}
            eventName={formData?.details?.name}
            totalTickets={assistantsInfo?.sold_tickets}
            consumedTickets={totalTicketConsumed}
            setQrResponse={setQrResponse}
          />
        </Grid>
        {valueTab === 1 && (
          <Button
            onClick={createAssistant}
            className="oniria-btn"
            variant="contained"
            size="medium"
            sx={{
              width: "fit-content",
              fontSize: "12px",
              borderRadius: 3,
              display: "flex",
              alignItems: "center",
              gap: "4px",
              alignSelf: "flex-end",
              marginBottom: "31px",
            }}
          >
            {t("ADD_ASSISTANT")}
          </Button>
        )}
      </Grid>
      <Grid container justifyContent="center">
        {qrResponse}
      </Grid>
      <Grid container sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Grid item xs={11} md={9} lg={7.5}>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={valueTab}
              onChange={handleChange}
              centered={!theme.breakpoints.down("sm")}
              variant={theme.breakpoints.down("sm") ? "scrollable" : "standard"}
              scrollButtons={theme.breakpoints.down("sm") ? "auto" : "off"}
              allowScrollButtonsMobile
              TabIndicatorProps={{
                style: { backgroundColor: "var(--secondary-color)" },
              }}
            >
              <Tab
                sx={MUI_SELECT_PRIM_COLOR}
                label={
                  isTripType && selectedEventsTab === EVENT_TABS.MY_EVENTS
                    ? t("EDIT_TRIP")
                    : isTripType
                    ? t("SEE_TRIP")
                    : selectedEventsTab === EVENT_TABS.MY_EVENTS
                    ? t("EDIT_EVENT")
                    : t("SEE_EVENT")
                }
              />
              <Tab sx={MUI_SELECT_PRIM_COLOR} label={t("LIST_ATTENDEES")} />
              {!isScanner && <Tab sx={MUI_SELECT_PRIM_COLOR} label={t("ORDERS")} />}
              {!isScanner && <Tab sx={MUI_SELECT_PRIM_COLOR} label={t("STATISTICS")} />}
              <Tab sx={MUI_SELECT_PRIM_COLOR} label={t("RRPP")} />
            </Tabs>
          </Box>
        </Grid>
        {valueTab === 0 && formData.event_type === EVENTS_TYPE.TRIP_COMPOSITE ? (
          <TripPlusForm formData={formData} extraTemplateId={extraId} />
        ) : (
          valueTab === 0 && <EditEvent formData={formData} extraId={extraId} />
        )}
        {valueTab === 1 && <ListAssistants eventId={eventId} qr={qrResponse} />}
        {valueTab === 2 && !isScanner && <Orders eventId={eventId} />}
        {valueTab === 3 && <Statistics eventId={eventId} />}
        {(valueTab === 4 || (valueTab === 2 && isScanner)) && (
          <RrppTable eventId={eventId} eventCategories={formData.details.event_categories} />
        )}
      </Grid>
    </>
  );
};

export default EventDetail;
