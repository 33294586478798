import { Box, Divider, Grid, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PAGINATION_STYLES } from "../../../../constants/styles";
import { ASSIGN_TRIP_TYPES } from "../../../../constants/variables";
import useQueryTourLeaders from "../../../../hooks/queries/tourLeaders/useQueryTourLeaders";
import useDebounce from "../../../../hooks/useDebouncing";
import usePagination from "../../../../hooks/usePagination";
import SearchFilter from "../../../events/filters/filter/SearchFilter";
import AssignComponent from "../../../shared/AssignComponent";
import CreateTourLeaderDialog from "../../../tourLeaders/createDialog/CreateTourLeaderDialog";

const AddTourLeaders = () => {
  const { t } = useTranslation();

  const { getValues, setValue } = useFormContext();
  const { page, changePage } = usePagination();
  const [search, setSearch] = useState("");
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const tripInfo = getValues();

  const { data } = useQueryTourLeaders(page, 3, {
    search,
  });
  const tourLeaders = data?.data || [];
  const totalPages = Math.ceil((data?.total || 0) / 3);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleAddTourLeader = async (tourLeaderId, _) => {
    const newTourLeaders = getValues("tour_leader_ids") || [];
    if (!newTourLeaders?.includes(tourLeaderId) && !!tourLeaderId) {
      newTourLeaders.push(tourLeaderId);
    }
    setValue("tour_leader_ids", newTourLeaders);
  };

  const handleDeleteTourLeader = (itemId, _) => {
    const newTourLeaders = getValues("tour_leader_ids");
    if (newTourLeaders?.includes(itemId)) {
      newTourLeaders.splice(newTourLeaders.indexOf(itemId), 1);
    }
    setValue("tour_leader_ids", newTourLeaders);
  };

  const handleCreateTourLeader = () => {
    setOpenCreateDialog(true);
  };

  return (
    <>
      <Divider sx={{ width: "100%", borderColor: "var(--secondary-color)", mt: 2, mb: 2 }} />
      <Typography variant="body1" sx={{ width: "100%", fontWeight: 900 }}>
        {t("ASSIGN_TOUR_LEADERS")}
      </Typography>
      <Typography variant="caption" sx={{ mb: 2 }}>
        {t("ASSIGN_TOUR_LEADERS_DESCRIPTION")}
      </Typography>
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <Grid container spacing={2} sx={{ width: "45%", justifyContent: "start" }}>
          <Grid item xs={12} sm={6}>
            <SearchFilter onSearchChange={debounceSearch} />
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent="flex-end">
            <Pagination
              count={totalPages}
              page={page}
              onChange={changePage}
              sx={PAGINATION_STYLES}
            />
          </Grid>
        </Grid>
      </Box>
      <AssignComponent
        tripInfo={tripInfo}
        itemsToAssign={tourLeaders}
        handleAssign={handleAddTourLeader}
        handleRemove={handleDeleteTourLeader}
        type={ASSIGN_TRIP_TYPES.TOUR_LEADERS}
        handleCreateNew={handleCreateTourLeader}
      />

      <CreateTourLeaderDialog
        isOpen={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
      />
    </>
  );
};

export default AddTourLeaders;
