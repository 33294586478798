import { Close } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useQueryTourPointsList from "../../../hooks/queries/tourPoints/useQueryTourPointsList";
import useQueryTransportDetails from "../../../hooks/queries/transports/useQueryTransportDetails";
import { createTransport } from "../../../services/transportServices";
import { prepareTransportToSend } from "../../../utils/transports";
import { focusColor } from "../textFieldStyle";
import { toastMessageError } from "../toastMessage";

function AssignTransportDialog({ isOpen, onClose, transportTemplateId, addTransportToItinerary }) {
  const { t } = useTranslation();
  const [transport, setTransport] = useState({});

  const { data: tourPoints = [] } = useQueryTourPointsList();
  const orderedTourPoints = tourPoints?.sort((a, b) => a.name.localeCompare(b.name));

  const handleSetTransport = (data) => {
    setTransport({
      start_point_id: "",
      end_point_id: "",
      start_date: "",
      end_date: "",
      start_date_time: "",
      end_date_time: "",
      plate: "",
      assignation_limit: 0,
      ...data,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTransport((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useQueryTransportDetails(transportTemplateId, handleSetTransport, false, isOpen);

  const handleCreateNewTransport = async () => {
    try {
      const transportToSend = await prepareTransportToSend(transport);
      const newTransport = await createTransport(transportToSend, true);
      addTransportToItinerary(newTransport.id);
      setTransport({});
      onClose();
    } catch (e) {
      console.log("Error al crear transporte", e);
      toastMessageError(e?.response?.data?.error || t("ERROR"));
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle sx={{ fontWeight: "bolder" }}>
        {t("ASSIGN_TRANSPORT")}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "var(--secondary-color)",
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: "black" }}>
          {t("ASSIGN_TRANSPORT_DESCRIPTION")}
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography mb={1}>{t("START_POINT")}</Typography>

            <FormControl fullWidth sx={focusColor}>
              <Select
                name="start_point_id"
                value={transport.start_point_id || ""}
                onChange={handleChange}
                fullWidth
              >
                <MenuItem value="">{t("NONE")}</MenuItem>
                {orderedTourPoints.map((point) => (
                  <MenuItem key={point.id} value={point.id}>
                    {point.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography mb={1}>{t("END_POINT")}</Typography>
            <FormControl fullWidth sx={focusColor}>
              <Select
                name="end_point_id"
                value={transport.end_point_id || ""}
                onChange={handleChange}
                fullWidth
              >
                <MenuItem value="">{t("NONE")}</MenuItem>
                {orderedTourPoints.map((point) => (
                  <MenuItem key={point.id} value={point.id}>
                    {point.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("START_DAY")}</Typography>
            <TextField
              type="date"
              name="start_date"
              value={transport.start_date || ""}
              onChange={handleChange}
              fullWidth
              inputProps={{
                min: new Date().toISOString().split("T")[0],
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={focusColor}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography>{t("END_DAY")}</Typography>
            <TextField
              type="date"
              name="end_date"
              value={transport.end_date || ""}
              onChange={handleChange}
              fullWidth
              inputProps={{
                min: new Date().toISOString().split("T")[0],
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={focusColor}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("START_DAY_TIME")}</Typography>
            <TextField
              type="time"
              name="start_date_time"
              value={transport.start_date_time || ""}
              onChange={handleChange}
              fullWidth
              inputProps={{
                min: "00:00",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={focusColor}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("END_DAY_TIME")}</Typography>
            <TextField
              type="time"
              name="end_date_time"
              value={transport.end_date_time || ""}
              onChange={handleChange}
              fullWidth
              inputProps={{
                min: "00:00",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={focusColor}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>{t("OPTIONAL_PLATE")}</Typography>
            <TextField
              placeholder={t("PLATE")}
              name="plate"
              value={transport.plate || ""}
              onChange={handleChange}
              fullWidth
              sx={focusColor}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>{t("OPTIONAL_ASSIGNATION_LIMIT")}</Typography>
            <TextField
              placeholder={t("ASSIGNATION_LIMIT")}
              name="assignation_limit"
              value={transport.assignation_limit || ""}
              onChange={handleChange}
              fullWidth
              sx={focusColor}
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Button
          type="submit"
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={handleCreateNewTransport}
        >
          {t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AssignTransportDialog;
