import { Box, Button, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { extra, extraErrorsAllOk, extraLanguageInfo } from "../../../classes/tripClass";
import useQueryExtraDetail from "../../../hooks/queries/extras/useQueryExtraDetail";
import { deleteExtra } from "../../../services/eventsServices";
import ButtonsForms from "../../account/forms/ButtonsForms";
import ExtraCard from "./ExtraCard";
import ExtraTemplateDialog from "./ExtraTemplateDialog";

const Extras = ({ formController, buttonClicked, setButtonClicked, isDisabled, extraId }) => {
  const { t } = useTranslation();
  const {
    formData: {
      extras,
      details: { event_language_infos, event_categories, default_language },
    },
    validation,
    setFormData,
    setFormErrorsTicketsAndExtras,
  } = formController;
  const { extras: extrasErrors } = validation;

  const [openDialog, setOpenDialog] = useState(false);

  const setErrors = (extrasErrors) => {
    setFormErrorsTicketsAndExtras("extras", extrasErrors);
  };

  const addExtra = (extraTemplate) => {
    //
    const newIdioms = event_language_infos.map((language) => {
      return {
        ...extraLanguageInfo,
        language: language.language,
      };
    });
    //
    const newExtra = {
      ...extra,
      extra_language_infos: [...newIdioms],
    };
    if (extraTemplate) {
      newExtra.name = extraTemplate.name;
      newExtra.description = extraTemplate.description || "";
      newExtra.description_design = extraTemplate.description_design || "";
      newExtra.extra_language_infos = extraTemplate.extra_template_language_infos || [];
      newExtra.extra_template_id = extraTemplate.id;
      newExtra.short_description = extraTemplate.short_description || "";
      newExtra.images = extraTemplate.images || "";
    }
    //
    setFormData((prev) => ({
      ...prev,
      extras: [...extras, newExtra],
    }));
    //
    const newExtraErrors = JSON.parse(JSON.stringify(extraErrorsAllOk));
    setErrors([...extrasErrors, newExtraErrors]);
  };

  const removeExtra = async (index) => {
    //
    if (extras[index].id) {
      await deleteExtra(extras[index].id);
    }
    //
    const updatedExtras = extras.filter((_, extraIndex) => extraIndex !== index);
    setFormData((prev) => ({
      ...prev,
      extras: updatedExtras,
    }));
    //
    const updatedExtrasErrors = extrasErrors.filter((_, extraIndex) => extraIndex !== index);
    setErrors(updatedExtrasErrors);
  };

  const checkExtraField = (key, e, regex, errorMessage, canBeEmpty, index, parameter = "value") => {
    //
    const newValue = e.target[parameter];
    let errorValue = "";
    //
    if (newValue === "" && !canBeEmpty) {
      errorValue = "empty";
    } else if (
      key === "initial_stock" &&
      parseInt(extras[index].stock) + (parseInt(newValue) - parseInt(extras[index].initial_stock)) <
        0
    ) {
      errorValue = t("STOCK_ERROR");
    } else if (regex.test(newValue)) {
      errorValue = "";
    } else {
      errorValue = errorMessage;
    }
    //
    const errorsToModify = JSON.parse(JSON.stringify(extrasErrors));
    const currentExtraErrors = errorsToModify[index];
    currentExtraErrors[key] = errorValue;
    //
    setErrors(errorsToModify);
    //
    setFormData((prev) => {
      const updatedExtras = [...prev.extras];
      updatedExtras[index][key] = newValue;
      return { ...prev, extras: updatedExtras };
    });
  };

  const allExtrasOk = () => {
    for (const extraErrors of extrasErrors) {
      for (const key in extraErrors) {
        if (extraErrors[key] !== "" && key !== "extra_language_infos") {
          return false;
        }
      }
    }
    return true;
  };

  // to set next button form clicked
  const catchButtonClicked = (value) => {
    setButtonClicked(value);
  };

  // drag and drop
  const handleSetExtrasPositions = ({ destination, source }) => {
    if (!destination) {
      return;
    }
    const newExtras = [...extras];
    const [reorderedExtra] = newExtras.splice(source.index, 1);
    newExtras.splice(destination.index, 0, reorderedExtra);
    setFormData((prev) => ({
      ...prev,
      extras: newExtras,
    }));
  };

  const someExtraHasEmailEditor = () => {
    for (let i = 0; i < extras?.length; i++) {
      if (typeof extras[i]?.description === "string") {
        if (extras[i].description.includes("DOCTYPE")) {
          return true;
        }
      }
    }
    return false;
  };

  const { data: extraTemplate } = useQueryExtraDetail(extraId);
  const checkExtraTemplate = () => {
    if (extras.some((extra) => extra.extra_template_id === extraId)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!!extraId && extraTemplate && checkExtraTemplate()) {
      addExtra(extraTemplate);
    }
  }, [extraId, extraTemplate]);

  return (
    <Grid
      container
      sx={{
        mt: 5,
        mb: 20,
        px: 1,
        color: "black",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid
        item
        width="fit-content"
        xs={someExtraHasEmailEditor() ? 12 : 11}
        md={someExtraHasEmailEditor() ? 12 : 8}
      >
        <Box
          component={Paper}
          elevation={3}
          sx={{
            py: 2,
            px: 3,
            border: "1px solid #E4E4E4",
            borderRadius: "15px",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 2 }}>
            <DragDropContext onDragEnd={handleSetExtrasPositions}>
              <Droppable droppableId="extras">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {extras && extras.length > 0
                      ? extras.map((_, index) => {
                          return (
                            <ExtraCard
                              key={index}
                              formController={formController}
                              index={index}
                              checkExtraField={checkExtraField}
                              errors={extrasErrors}
                              removeExtra={removeExtra}
                              sendButtonClicked={buttonClicked}
                              isDisabled={isDisabled}
                              event_language_infos={event_language_infos}
                              setFormData={setFormData}
                              defaultLanguage={default_language}
                            />
                          );
                        })
                      : ""}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <Grid item xs={12} sx={{ mt: 2, mb: 2 }} container gap={2} justifyContent="center">
            <Button
              variant="outlined"
              size="large"
              className="border-btn-dark"
              onClick={() => addExtra()}
            >
              {t("ADD_EXTRA")}
            </Button>
            <Button
              variant="outlined"
              size="large"
              className="border-btn-dark"
              onClick={() => setOpenDialog(true)}
            >
              + {t("ADD_EXTRA_FROM_TEMPLATE")}
            </Button>
          </Grid>
          {!isDisabled && (
            <ButtonsForms
              formController={formController}
              allFieldsOk={allExtrasOk}
              errors={extrasErrors}
              sendButtonClicked={catchButtonClicked}
            />
          )}
        </Box>
      </Grid>
      <ExtraTemplateDialog
        isOpen={openDialog}
        onClose={() => setOpenDialog(false)}
        onAccept={addExtra}
        event_categories={event_categories}
      />
    </Grid>
  );
};

export default Extras;
