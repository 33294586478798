import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const AddTripComponents = ({
  stepIndex,
  activeStep,
  handleChangeTripComponents,
  tripComponents,
}) => {
  const { t } = useTranslation();

  const { hasHosting, hasTransports, hasTourLeaders } = tripComponents;

  return (
    <Grid container ml={3} mb={2}>
      {stepIndex === 0 && activeStep === 0 && (
        <>
          <Typography width="90%">{t("ADD_HOSTING_INFO")}</Typography>
          <RadioGroup
            value={hasHosting}
            onChange={(e) => handleChangeTripComponents("hasHosting", e)}
          >
            <FormControlLabel
              value="true"
              control={<Radio className="radio-oniria" />}
              label={t("TRIP_WITH_HOSTING")}
            />
            <FormControlLabel
              value="false"
              control={<Radio className="radio-oniria" />}
              label={t("TRIP_WITHOUT_HOSTING")}
            />
          </RadioGroup>
        </>
      )}
      {stepIndex === 1 && activeStep === 1 && (
        <>
          <Typography width="90%">{t("ADD_TRANSPORTS_INFO")}</Typography>
          <RadioGroup
            value={hasTransports}
            onChange={(e) => handleChangeTripComponents("hasTransports", e)}
          >
            <FormControlLabel
              value="true"
              control={<Radio className="radio-oniria" />}
              label={t("TRIP_WITH_TRANSPORTS")}
            />
            <FormControlLabel
              value="false"
              control={<Radio className="radio-oniria" />}
              label={t("TRIP_WITHOUT_TRANSPORTS")}
            />
          </RadioGroup>
        </>
      )}
      {stepIndex === 2 && activeStep === 2 && (
        <>
          <Typography width="90%">{t("ADD_TOUR_LEADERS_INFO")}</Typography>
          <RadioGroup
            value={hasTourLeaders}
            onChange={(e) => handleChangeTripComponents("hasTourLeaders", e)}
          >
            <FormControlLabel
              value="true"
              control={<Radio className="radio-oniria" />}
              label={t("TRIP_WITH_TOUR_LEADERS")}
            />
            <FormControlLabel
              value="false"
              control={<Radio className="radio-oniria" />}
              label={t("TRIP_WITHOUT_TOUR_LEADERS")}
            />
          </RadioGroup>
        </>
      )}
    </Grid>
  );
};

export default AddTripComponents;
