import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HOSTING_EXTRAS } from "../../../constants/variables";
import useQueryHotelDetails from "../../../hooks/queries/hotels/useQueryHotelDetails";
import { createHotel } from "../../../services/hotelServices";
import AdditionalExtras from "./extras/AdditionalExtras";
import NightExtra from "./extras/NightExtra";
import GlobalPensions from "./pensions/GlobalPensions";
import IndividualPensions from "./pensions/IndividualPensions";
import GlobalRooms from "./rooms/GlobalRooms";
import IndividualRooms from "./rooms/IndividualRooms";
import { prepareHotelToSend } from "../../../utils/hotels";
import { toastMessageError } from "../toastMessage";

function AssignHotelDialog({
  isOpen,
  onClose,
  onAccept,
  tripInfo,
  hotelTemplateId,
  addHotelToItinerary,
  selectedItinerary,
  isEdit,
}) {
  const { t } = useTranslation();

  const { pensions_type, rooms_type, extras = [], itineraries = [] } = tripInfo;

  const [newHotel, setNewHotel] = useState({});
  const { included_pension = "" } = newHotel;

  const handleCreateNewHotel = async (data) => {
    if (isEdit) {
      setNewHotel(data);
    } else {
      try {
        data.id = null;
        const hotelToSend = await prepareHotelToSend(data, true);
        const newHotel = await createHotel(hotelToSend, true);
        addHotelToItinerary(newHotel.id);
        setNewHotel(newHotel);
      } catch (e) {
        onClose();
        console.log("Error al crear hotel", e);
        toastMessageError(e?.response?.data?.error || t("ERROR"));
      }
    }
  };

  useQueryHotelDetails(hotelTemplateId, handleCreateNewHotel, isEdit);

  const handlePensionChange = (type, isChecked) => {
    if (isChecked) {
      const pensions = newHotel?.included_pension?.split(",") || [];
      pensions.push(type);
      setNewHotel((prev) => ({
        ...prev,
        included_pension: pensions?.join(","),
      }));
    } else {
      const pensions = newHotel?.included_pension?.split(",").filter((pension) => pension !== type);
      setNewHotel((prev) => ({
        ...prev,
        included_pension: pensions?.join(","),
      }));
    }
  };

  const itineraryToAssign = itineraries?.find(
    (itinerary, index) => itinerary?.id?.includes(selectedItinerary) || index == selectedItinerary
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle sx={{ fontWeight: "bolder" }}>
        {t("ASSIGN_HOSTING")}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "var(--secondary-color)",
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: "black" }}>
          {t("ASSIGN_HOTEL_DESCRIPTION")}
        </DialogContentText>
        {/* PENSIONS */}
        {pensions_type === HOSTING_EXTRAS.GLOBAL && (
          <GlobalPensions
            includedPension={included_pension}
            handlePensionChange={handlePensionChange}
            extras={extras}
          />
        )}
        {pensions_type === HOSTING_EXTRAS.INDIVIDUAL && (
          <IndividualPensions extras={extras} hotelId={newHotel.id} />
        )}

        {/* ROOMS */}
        {rooms_type === HOSTING_EXTRAS.GLOBAL && (
          <GlobalRooms
            extras={extras}
            handlePensionChange={handlePensionChange}
            includedPension={included_pension}
          />
        )}
        {rooms_type === HOSTING_EXTRAS.INDIVIDUAL && <IndividualRooms hotelId={newHotel.id} />}

        {/* GENERAL EXTRAS */}
        <NightExtra itineraryToAssign={itineraryToAssign} hotelId={newHotel.id} />
        <AdditionalExtras hotelId={newHotel.id} />
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Button
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onAccept}
        >
          {t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AssignHotelDialog;
