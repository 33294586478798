import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { focusColor } from "../../shared/textFieldStyle";
import InfoToolTip from "../../shared/InfoToolTip";
import { sanitizeBoolean } from "../../../constants/utils";

const TripDates = () => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const isMultiDate = sanitizeBoolean(watch("multi_date"));

  return (
    <Grid item xs={12}>
      <Typography variant="h6">{t("DATES")}</Typography>
      <Typography variant="body1">{t("DATES_DESCRIPTION")}</Typography>

      <Controller
        name="multi_date"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <RadioGroup {...field}>
            <FormControlLabel
              value="false"
              control={<Radio className="radio-oniria" />}
              label={t("UNIQUE_DATE")}
            />
            <FormControlLabel
              value="true"
              control={<Radio className="radio-oniria" />}
              label={
                <div>
                  {t("MULTIPLE_DATES")}
                  <InfoToolTip text={t("MULTIPLE_DATES_TOOLTIP")} />
                </div>
              }
            />
          </RadioGroup>
        )}
      />

      {!isMultiDate && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{t("START_DATE")}</Typography>
              <InfoToolTip text={t("INFO_START_DATE")} />
            </Box>
            <Controller
              name="start_date"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  type="date"
                  {...field}
                  error={!!errors?.start_date}
                  helperText={errors?.start_date?.message || ""}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={focusColor}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{t("END_DATE")}</Typography>
              <InfoToolTip text={t("INFO_END_DATE")} />
            </Box>
            <Controller
              name="end_date"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  type="date"
                  {...field}
                  error={!!errors?.end_date}
                  helperText={errors?.end_date?.message || ""}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={focusColor}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("START_TIME")}</Typography>
            <Controller
              name="start_date_time"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  type="time"
                  {...field}
                  error={!!errors?.start_date_time}
                  helperText={errors?.start_date_time?.message || ""}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={focusColor}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("END_TIME")}</Typography>
            <Controller
              name="end_date_time"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  type="time"
                  {...field}
                  error={!!errors?.end_date_time}
                  helperText={errors?.end_date_time?.message || ""}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={focusColor}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TripDates;
