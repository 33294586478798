import { Add, Delete, DragIndicator, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { cloneElement, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { GREY_BUTTON_STYLE, VERTICAL_STEEP_STYLE } from "../../../constants/styles";
import { DEFAULT_TICKET } from "../../../schemas/trips";
import VerticalStepButtons from "../../shared/VerticalStepButtons";
import TripTicketBasicInfo from "./TripTicketBasicInfo";
import TripTicketConfig from "./TripTicketConfig";
import TripTicketDiscounts from "./TripTicketDiscounts";

const TripTickets = () => {
  const { t } = useTranslation();
  const [activeSteps, setActiveSteps] = useState({});

  const steps = [
    { label: t("BASIC_TICKET_INFORMATION"), content: <TripTicketBasicInfo /> },
    { label: t("CONFIGURATION"), content: <TripTicketConfig /> },
    { label: t("DISCOUNTS"), content: <TripTicketDiscounts /> },
  ];

  const {
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const tickets = watch("tickets") || [];

  const handleSetTicketPositions = ({ destination, source }) => {
    if (!destination) return;
    const newTickets = getValues("tickets") || [];
    const [removed] = newTickets.splice(source.index, 1);
    newTickets.splice(destination.index, 0, removed);

    const updatedTickets = newTickets.map((ticket, index) => ({
      ...ticket,
      position: index,
    }));

    setValue("tickets", updatedTickets);

    setActiveSteps((prev) => ({
      ...prev,
      [destination.index]: 0,
    }));
  };

  const removeTicket = (index) => {
    const updatedTickets = tickets?.filter((_, i) => i !== index);
    setValue("tickets", updatedTickets);
  };

  const handleAddTicket = () => {
    const prev = getValues("tickets") || [];
    const defaultLanguage = getValues("default_language") || "ES";
    setValue("tickets", [...prev, { ...DEFAULT_TICKET, default_language: defaultLanguage }]);

    setActiveSteps((prev) => ({
      ...prev,
      [prev.length - 1]: 0,
    }));
  };

  const handleStepChange = (ticketIndex, step) => {
    setActiveSteps((prev) => ({
      ...prev,
      [ticketIndex]: step,
    }));
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">{t("TICKETS")}</Typography>
      </Grid>
      {errors?.tickets?.message && (
        <Typography sx={{ color: "var(--oniria-red)" }}>*{t("REQUIRED_TICKET_ERROR")}</Typography>
      )}
      <Grid item xs={12}>
        <DragDropContext onDragEnd={handleSetTicketPositions}>
          <Droppable droppableId="droppable-list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {tickets
                  .sort((a, b) => a.position - b.position)
                  .map((ticket, index) => {
                    return (
                      <Draggable
                        key={`draggable-ticket-${index}`}
                        draggableId={`draggable-ticket-${index}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Accordion
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            component={Paper}
                            elevation={0}
                            sx={{
                              backgroundColor: snapshot.isDragging ? "var(--grey-cancelled)" : "",
                              mt: 2,
                              border: "1px solid #E4E4E4",
                              width: "100%",
                              "&:before": {
                                display: "none",
                              },
                            }}
                          >
                            <AccordionSummary expandIcon={<ExpandMore />}>
                              <Grid container justifyContent="space-between" alignItems="center">
                                <Grid
                                  item
                                  container
                                  width="fit-content"
                                  alignItems="center"
                                  gap={2}
                                >
                                  <DragIndicator />
                                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                    {t("TICKET")} - {ticket.name ? ticket.name : index + 1}
                                  </Typography>
                                </Grid>
                                <IconButton onClick={() => removeTicket(index)}>
                                  <Delete fontSize="small" />
                                </IconButton>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Stepper
                                  activeStep={activeSteps[index] || 0}
                                  orientation="vertical"
                                >
                                  {steps.map((step, stepIndex) => (
                                    <Step key={step.label} sx={VERTICAL_STEEP_STYLE}>
                                      <StepLabel onClick={() => handleStepChange(index, stepIndex)}>
                                        {step.label}
                                      </StepLabel>
                                      <StepContent>
                                        {cloneElement(step.content, { index: index })}
                                        <VerticalStepButtons
                                          activeStep={activeSteps[index] || 0}
                                          handleStepChange={(value) =>
                                            handleStepChange(index, value)
                                          }
                                          stepsLength={steps.length}
                                        />
                                      </StepContent>
                                    </Step>
                                  ))}
                                </Stepper>
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>

      <Grid item xs={12}>
        <Button
          fullWidth
          variant="outlined"
          size="large"
          sx={{
            ...GREY_BUTTON_STYLE,
            marginTop: "20px",
          }}
          onClick={handleAddTicket}
          startIcon={<Add sx={{ color: "var(--secondary-color)" }} />}
        >
          {t("ADD_TICKET")}
        </Button>
      </Grid>
    </>
  );
};

export default TripTickets;
