import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LanguageTabs from "../../shared/languages/LanguageTabs";
import { focusColor } from "../../shared/textFieldStyle";
import TextEditor from "../../textEditor/TextEditor";
import TicketLanguageSelect from "./TicketLanguageSelect";

const TripTicketBasicInfo = ({ index }) => {
  const { t } = useTranslation();
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const ticketLanguages = watch(`tickets[${index}].ticket_language_infos`);
  const defaultLanguage = watch(`tickets[${index}].default_language`);
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const languageIndex = ticketLanguages?.findIndex((info) => info.language === selectedLanguage);

  return (
    <>
      <TicketLanguageSelect index={index} />
      <Grid item xs={12} mb={2}>
        <LanguageTabs
          languagesInfo={ticketLanguages}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          defaultLanguage={defaultLanguage}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("NAME")}*</Typography>
        {selectedLanguage === defaultLanguage ? (
          <>
            <TextField
              {...register(`tickets[${index}].name`)}
              error={!!errors?.tickets?.[index]?.name}
              helperText={errors?.tickets?.[index]?.name?.message}
              fullWidth
              sx={focusColor}
              placeholder={t("NAME")}
              required
            />
          </>
        ) : (
          <TextField
            {...register(`tickets[${index}].ticket_language_infos[${languageIndex}].name`)}
            error={!!errors?.tickets?.[index]?.ticket_language_infos?.[languageIndex]?.name}
            helperText={
              errors?.tickets?.[index]?.ticket_language_infos?.[languageIndex]?.name?.message
            }
            fullWidth
            sx={focusColor}
            placeholder={t("NAME")}
            required
          />
        )}
      </Grid>
      <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
        <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
          {t("DESCRIPTION")}*
        </InputLabel>
        {selectedLanguage === defaultLanguage ? (
          <>
            <TextEditor
              data={watch(`tickets[${index}].description`)}
              setData={(data) => setValue(`tickets[${index}].description`, data)}
              id={`ticket-${index}-description`}
            />
          </>
        ) : (
          <TextEditor
            data={watch(`tickets[${index}].ticket_language_infos[${languageIndex}].description`)}
            setData={(data) =>
              setValue(
                `tickets[${index}].ticket_language_infos[${languageIndex}].description`,
                data
              )
            }
            id={`ticket-${index}-description`}
          />
        )}
      </Grid>
    </>
  );
};
export default TripTicketBasicInfo;
